import Base from "itr/base"
import Carbon from "itr/benefit/carbon"
import Energy from "itr/benefit/energy"
import Hydrology from "itr/benefit/hydrology"
import { PollutionAvoided } from "itr/benefit/air"
import { PollutionRemoved } from "itr/benefit/air"
import { camel2dash } from "itr/utility"
import { dash2camel } from "itr/utility"
import { object2dash2camel } from "itr/utility"


class Benefit extends Base {
	constructor(
		{
			projectResponse = null,
		} = {}
	) {
		super()

		if (
			projectResponse === null || typeof projectResponse === "undefined"
		) {
			this.carbon = new Carbon({})
			this.energy = new Energy({})
			this.hydrology = new Hydrology({})
			this.pollutionAvoided = new PollutionAvoided({})
			this.pollutionRemoved = new PollutionRemoved({})
			this.air = {
				pollutionAvoided: this.pollutionAvoided,
				pollutionRemoved: this.pollutionRemoved,
			}
			return
		}

		const r = projectResponse
		const c = this.camelFromResponse

		this.carbon = new Carbon(c(r[Carbon.__id]))
		this.energy = new Energy(c(r[Energy.__id]))
		this.hydrology = new Hydrology(c(r[Hydrology.__id]))
		this.pollutionAvoided = new PollutionAvoided(
			c(r[PollutionAvoided.__id]),
		)
		this.pollutionRemoved = new PollutionRemoved(
			c(r[PollutionRemoved.__id]),
		)

		this.air = {
			pollutionAvoided: this.pollutionAvoided,
			pollutionRemoved: this.pollutionRemoved,
		}
	}

	// Overriding to remove convenience grouping 'air' elimates dupliates
	// fields in JSON and matches the APIv3 output.
	static fromJSON = (str) => {
		let state = JSON.parse(str)
		let publicFields = {}
		for (const prop in state) {
			publicFields[dash2camel(prop)] = state[prop]
		}
		publicFields.air = {
			pollutionAvoided: publicFields.pollutionAvoided,
			pollutionRemoved: publicFields.pollutionRemoved,
		}
		return new Location(publicFields)
	}

	// Overriding to remove convenience grouping 'air' elimates dupliates
	// fields in JSON and matches the APIv3 output.
	toJSON = (key) => {
		delete this.air
		let state = {}
		for (const prop in this) {
			state[camel2dash(prop)] = this[prop]
		}
		return state
	}

	camelFromResponse = (response) => {
		return object2dash2camel(response)
	}
}


export { Benefit }
export default Benefit

