import React, {Component} from 'react';

import {
	Col,
	Container,
	Row
} from "reactstrap";

import {ModalContents} from "../parts/ModalContents";


export class Info extends Component {

	render() {
		return (
			<Container>
				<Row>
					<Col
						className={"mt-5"}
						xs={{size: 10, offset: 1}}
						sm={{size: 6, offset: 3}}
					>
						<ModalContents
							subject={"whyMyTree"}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}
