import React, {Component} from 'react';

import {
	Route,
	Switch
} from "react-router-dom";

import "./stylesheets/site-wide.scss";

import "./components/icons";
import * as PROPS from "./components/static_props/MyTree";
import {SharedNavbar} from "./components/parts/SharedNavbar";
import {
	Container,
	Row,
	Col,
} from "reactstrap";
import logo from "./img/iTree_primary_logo_restricted_MEDIUM.png";
import {AppVersion} from "./components/parts/GeneralPageElements";
import tree from "./img/Picture1.jpg";


export class Offline extends Component {
	constructor(props) {
		super(props);

		window.onpopstate = (event) => {
			window.scrollTo({top: 0});
		};
	};

	render() {
		let {version} = this.props;

		return (
			<div id={"navigation"}>
				<SharedNavbar
					{...PROPS.NAVBAR}
				/>
				<div id={"content"}>
					<Switch>
						<Route
							path={"/"}
							exact
							render={
								props =>
									<Container>
										<Row className={"mt-5"}>
											<Col>
												<p className={"h3 text-center"}>
													{PROPS.NAVBAR.app} requires
													an active internet
													connection to work
													correctly.
												</p>
											</Col>
										</Row>
										<Row>
											<Col className={"text-center"}>
												<img
													className={"ms-n4"}
													alt={"i-Tree Logo"}
													height={"125"}
													src={logo}
												/>
											</Col>
										</Row>
										<Row className={"text-center"}>
											<Col>
												<div className={"d-flex justify-content-center align-items-start"}>
													<img
														className={"tree"}
														src={tree}
														alt={"Tree"}
													/>
												</div>
											</Col>
										</Row>
										<Row className={"my-4 text-center"}>
											<Col>
												<div className={"position-relative"}>
													<AppVersion version={version}/>
												</div>
											</Col>
										</Row>
									</Container>
							}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}
