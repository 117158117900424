import React, {PureComponent} from 'react';

import {
	FormGroup,
	Input,
	Label,
} from "reactstrap";


export class UnitToggle extends PureComponent {
	render() {
		return (
			<React.Fragment>
				<div className={"mx-3 text-nowrap"}>
					Toggle between measurement units.
				</div>
				<div className={"mx-3"}>
					<FormGroup tag="fieldset">
						<FormGroup check>
							<Label check>
								<Input
									type="radio"
									name="unitType"
									checked={this.props.checked === true}
									onChange={this.props.onChange}
								/>
								English Units
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input
									type="radio"
									name="unitType"
									checked={this.props.checked === false}
									onChange={this.props.onChange}
								/>
								Metric Units
							</Label>
						</FormGroup>
					</FormGroup>
				</div>
			</React.Fragment>
		)
	}
}
