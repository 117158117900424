import React, {Component} from "react";

import * as Pages from "./LocationForm";

import * as PROPS from "../static_props/MyTree";

import {
	fromLonLat,
	toLonLat,
} from "ol/proj";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import {Style, Icon} from "ol/style";
import Feature from "ol/Feature";
import {Vector} from "ol/layer";

import "../../stylesheets/location-selection.scss";

import tree from "../../img/itree_treeonly_transparent_w_stroke.png";
import {
	extractAddressComponents,
	getAddressFromLatLng,
	getLatLngFromAddress,
} from "../../utilities/utilities";


export class LocationWrapper extends Component {
	constructor(props) {
		super(props);

		let iconParameters = {
			anchor: [0.5, 30],
			anchorXUnits: "fraction",
			anchorYUnits: "pixels",
			src: tree,
		};

		this.feature = new Feature({
			geometry: new Point(fromLonLat(this.props.location.getCenter())),
		});

		this.layers = [
			new Vector({
				source: new VectorSource({
					features: [this.feature],
				}),
				style: new Style({
					image: new Icon(iconParameters),
				}),
			})
		];
	}

	onMapClick = async (event) => {
		let coordinate = toLonLat(event.coordinate);
		let position = {
			latitude: coordinate[1],
			longitude: coordinate[0]
		};

		getAddressFromLatLng(position).then(
			(response) => {
				if (response.status.toLowerCase() === "ok") {
					let result = response.results[0];
					let components = extractAddressComponents(result);
					this.handleLocationUpdate(
						components.address,
						components,
						position.latitude,
						position.longitude
					);
				} else {
					this.handleBadResponse(response);
				}
			});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.feature.setGeometry(
			new Point(
				fromLonLat(this.props.location.getCenter()
				)
			)
		);
	}

	handleBadResponse = (response) => {
		let warning = "";

		if (
			response.error.includes("ZERO_RESULTS") ||
			response.error.includes("INVALID_REQUEST")
		) {
			warning = "No results were returned or this" +
				" address does not exist. Please try a" +
				" different address.";
		} else if (
			response.error.includes("OVER_DAILY_LIMIT") ||
			response.error.includes("OVER_QUERY_LIMIT") ||
			response.error.includes("REQUEST_DENIED")
		) {
			warning = "The geocoding service is" +
				" currently unavailable.";
		} else if (
			response.error.includes("TOO_BROAD")
		) {
			warning = "This address is too vague. Please try" +
				" one that is more specific.";
		} else {
			warning = "There has been an error while " +
				"geocoding this address. Please try again.";
		}

		this.setState({
			warning: warning
		});
	};

	handleGeolocation = async (position) => {
		document.body.style.cursor = "";
		let addressFromLatLng = await getAddressFromLatLng(position);

		if (addressFromLatLng.status.toLowerCase() === "ok") {
			let result = addressFromLatLng.results[0];
			let latitude = result.geometry.location.lat();
			let longitude = result.geometry.location.lng();
			let components = extractAddressComponents(result);
			this.handleLocationUpdate(
				components.address,
				components,
				latitude,
				longitude
			);
		} else {
			this.handleBadResponse(addressFromLatLng);
		}
	};

	handleAddressSearch = async (address) => {
		document.body.style.cursor = "";
		let latLngFromAddress = await getLatLngFromAddress(address);
		if (latLngFromAddress.status.toLowerCase() === "ok") {
			let result = latLngFromAddress.results[0];
			let components = extractAddressComponents(result);
			let latitude = result.geometry.location.lat();
			let longitude = result.geometry.location.lng();
			this.handleLocationUpdate(
				address,
				components,
				latitude,
				longitude
			);
		}
	};

	handleLocationUpdate = (
		address,
		components,
		latitude,
		longitude,
		callback
	) => {
		this.props.updateProject({
				location: {
					address: {
						entered: address,
						formatted: components.address,
					},
					latitude: latitude,
					longitude: longitude,
					nation: components.nation,
					stateAbbr: components.stateAbbr,
					county: components.county.long,
					city: components.city.long,
				},
			},
			callback ? callback() : null
		);
	};

	render() {
		let mapParameters = {
			imagerySets: [
				{name: "RoadOnDemand", visible: false},
				{name: "AerialWithLabelsOnDemand", visible: true},
			],
			layers: this.layers,
			activeImagery: 1,
		};

		return (
			<Pages.LocationForm
				{...this.props}
				{...PROPS.LOCATION}
				{...mapParameters}
				location={this.props.location}
				infoSubject={"location"}
				onMapClick={this.onMapClick}
				handleGeolocation={this.handleGeolocation}
				handleAddressSearch={this.handleAddressSearch}
				handleLocationUpdate={this.handleLocationUpdate}
				updateProject={this.props.updateProject}
			/>
		)
	}
}
