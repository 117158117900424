import Base from "itr/base"


class AirPollution extends Base {
	co = 0
	coWorth = 0
	co2 = 0
	co2Worth = 0
	no2 = 0
	no2Worth = 0
	so2 = 0
	so2Worth = 0
	o3 = 0
	o3Worth = 0
	pm25 = 0
	pm25Worth = 0
	voc = 0
	vocWorth = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class PollutionRemoved extends AirPollution {
	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}

PollutionRemoved.__id = "pollution-removed"


class PollutionAvoided extends AirPollution {
	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}

PollutionAvoided.__id = "pollution-avoided"


export { PollutionRemoved }
export { PollutionAvoided }
export { AirPollution }
export default AirPollution

