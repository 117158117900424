import React from "react";

function RugbyPitch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsosb="http://www.openswatchbook.org/uri/2009/osb"
      id="svg6327"
      width="124"
      height="72"
      version="1.1"
      viewBox="0 0 124 72"
    >
      <style id="screen-color-temperature-style" type="text/css"></style>
      <defs id="defs6329">
        <linearGradient id="linearGradient4310" osbpaint="solid">
          <stop
            id="stop4312"
            offset="0"
            stopColor="#000"
            stopOpacity="1"
          ></stop>
        </linearGradient>
      </defs>
      <g id="layer1" transform="translate(6 -984.362)">
        <g id="g4680" strokeOpacity="1">
          <path
            id="rect4623"
            fill="#477e19"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M-6 984.362H118V1056.362H-6z"
            opacity="1"
          ></path>
          <path
            id="path4340"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M13 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4351"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M23 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4353"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M33 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4355"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M43 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4357"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M53 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4359"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M63 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4361"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M73 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4363"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M83 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4365"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M93 996.362h6"
            opacity="1"
          ></path>
          <path
            id="path4369"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M13 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4371"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M23 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4373"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M33 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4375"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M43 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4377"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M53 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4379"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M63 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4381"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M73 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4383"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M83 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4385"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M93 1006.362h6"
            opacity="1"
          ></path>
          <path
            id="path4387"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M13 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4389"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M23 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4391"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M33 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4393"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M43 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4395"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M53 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4397"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M63 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4399"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M73 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4401"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M83 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4403"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M93 1034.362h6"
            opacity="1"
          ></path>
          <path
            id="path4405"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M13 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4407"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M23 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4409"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M33 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4411"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M43 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4413"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M53 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4415"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M63 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4417"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M73 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4419"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M83 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4421"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M93 1044.362h6"
            opacity="1"
          ></path>
          <path
            id="path4423"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M56 986.362v68"
          ></path>
          <path
            id="path4425"
            fill="none"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="#ff5100"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M66 986.362v68"
          ></path>
          <path
            id="path4427"
            fill="none"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="#ff5100"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M46 986.362v68"
          ></path>
          <path
            id="rect4429"
            fill="none"
            fillOpacity="1"
            stroke="#fff"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M-4 986.362H116V1054.362H-4z"
            opacity="1"
          ></path>
          <path
            id="path4431"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M76 986.362v68"
          ></path>
          <path
            id="path4433"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M86 986.362v68"
          ></path>
          <path
            id="path4435"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M96 986.362v68"
          ></path>
          <path
            id="path4437"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M106 986.362v68"
          ></path>
          <path
            id="path4439"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M36 986.362v68"
          ></path>
          <path
            id="path4441"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M26 986.362v68"
          ></path>
          <path
            id="path4443"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M16 986.362v68"
          ></path>
          <path
            id="path4445"
            fill="none"
            fillRule="evenodd"
            stroke="#fff"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeWidth="0.25"
            d="M6 986.362v68"
          ></path>
          <text
            id="text4451"
            x="24.493"
            y="1002.335"
            style={{ lineHeight: "125%" }}
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4453" x="24.493" y="1002.335">
              20
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4455"
            x="14.602"
            y="1002.335"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4457" x="14.602" y="1002.335">
              10
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4459"
            x="34.493"
            y="1002.335"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4461" x="34.493" y="1002.335">
              30
            </tspan>
          </text>
          <text
            id="text4463"
            x="44.493"
            y="1002.335"
            style={{ lineHeight: "125%" }}
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4465" x="44.493" y="1002.335">
              40
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4467"
            x="54.493"
            y="1002.335"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4469" x="54.493" y="1002.335">
              50
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4487"
            x="64.493"
            y="1002.335"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4489" x="64.493" y="1002.335">
              40
            </tspan>
          </text>
          <text
            id="text4491"
            x="74.493"
            y="1002.335"
            style={{ lineHeight: "125%" }}
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4493" x="74.493" y="1002.335">
              30
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4495"
            x="84.493"
            y="1002.335"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4497" x="84.493" y="1002.335">
              20
            </tspan>
          </text>
          <text
            id="text4499"
            x="94.602"
            y="1002.335"
            style={{ lineHeight: "125%" }}
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4501" x="94.602" y="1002.335">
              10
            </tspan>
          </text>
          <text
            transform="scale(-1)"
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4503"
            x="-87.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4505" x="-87.496" y="-1038.402">
              20
            </tspan>
          </text>
          <text
            id="text4507"
            x="-97.388"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontWeight="bold"
            transform="scale(-1)"
            style={{ lineHeight: "125%" }}
            fontVariant="normal"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4509" x="-97.388" y="-1038.402">
              10
            </tspan>
          </text>
          <text
            id="text4511"
            x="-77.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontWeight="bold"
            transform="scale(-1)"
            style={{ lineHeight: "125%" }}
            fontVariant="normal"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4513" x="-77.496" y="-1038.402">
              30
            </tspan>
          </text>
          <text
            transform="scale(-1)"
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4515"
            x="-67.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4517" x="-67.496" y="-1038.402">
              40
            </tspan>
          </text>
          <text
            id="text4519"
            x="-57.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontWeight="bold"
            transform="scale(-1)"
            style={{ lineHeight: "125%" }}
            fontVariant="normal"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4521" x="-57.496" y="-1038.402">
              50
            </tspan>
          </text>
          <text
            id="text4523"
            x="-47.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontWeight="bold"
            transform="scale(-1)"
            style={{ lineHeight: "125%" }}
            fontVariant="normal"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4525" x="-47.496" y="-1038.402">
              40
            </tspan>
          </text>
          <text
            transform="scale(-1)"
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4527"
            x="-37.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4529" x="-37.496" y="-1038.402">
              30
            </tspan>
          </text>
          <text
            id="text4531"
            x="-27.496"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontWeight="bold"
            transform="scale(-1)"
            style={{ lineHeight: "125%" }}
            fontVariant="normal"
            letterSpacing="0"
            wordSpacing="0"
            xmlSpace="preserve"
          >
            <tspan id="tspan4533" x="-27.496" y="-1038.402">
              20
            </tspan>
          </text>
          <text
            transform="scale(-1)"
            xmlSpace="preserve"
            style={{ lineHeight: "125%" }}
            id="text4535"
            x="-17.388"
            y="-1038.402"
            fill="#fff"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            fontFamily="Arial"
            fontSize="2.736"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan id="tspan4537" x="-17.388" y="-1038.402">
              10
            </tspan>
          </text>
          <path
            id="path4541"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M13.5 1001.362l1-.5v1z"
          ></path>
          <path
            id="path4543"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M23.391 1001.362l1-.5v1z"
          ></path>
          <path
            id="path4545"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M33.391 1001.362l1-.5v1z"
          ></path>
          <path
            id="path4547"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M43.391 1001.362l1-.5v1z"
          ></path>
          <path
            id="path4559"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M98.718 1001.362l-1-.5v1z"
          ></path>
          <path
            id="path4561"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M88.609 1001.362l-1-.5v1z"
          ></path>
          <path
            id="path4563"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M78.609 1001.362l-1-.5v1z"
          ></path>
          <path
            id="path4565"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M68.609 1001.362l-1-.5v1z"
          ></path>
          <path
            id="path4579"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M98.539 1039.362l-1 .5v-1z"
          ></path>
          <path
            id="path4581"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M88.654 1039.362l-1 .5v-1z"
          ></path>
          <path
            id="path4583"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M78.545 1039.362l-1 .5v-1z"
          ></path>
          <path
            id="path4585"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M68.654 1039.362l-1 .5v-1z"
          ></path>
          <path
            id="path4589"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M13.25 1039.362l1 .5v-1z"
          ></path>
          <path
            id="path4591"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M23.36 1039.362l1 .5v-1z"
          ></path>
          <path
            id="path4593"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M33.36 1039.362l1 .5v-1z"
          ></path>
          <path
            id="path4595"
            fill="#fff"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeWidth="1"
            d="M43.36 1039.362l1 .5v-1z"
          ></path>
          <g id="g4667" strokeDasharray="none" strokeMiterlimit="4">
            <circle
              id="path4637"
              cx="6"
              cy="1017.612"
              r="0.5"
              fill="#494949"
              fillOpacity="1"
              stroke="none"
              strokeWidth="0.25"
              opacity="1"
            ></circle>
            <circle
              id="circle4639"
              cx="6"
              cy="1023.112"
              r="0.5"
              fill="#494949"
              fillOpacity="1"
              stroke="none"
              strokeWidth="0.25"
              opacity="1"
            ></circle>
            <path
              id="path4641"
              fill="none"
              fillRule="evenodd"
              stroke="#494949"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeWidth="0.375"
              d="M6 1017.362v6"
            ></path>
          </g>
          <g
            id="g4672"
            strokeDasharray="none"
            strokeMiterlimit="4"
            transform="translate(100)"
          >
            <circle
              id="circle4674"
              cx="6"
              cy="1017.612"
              r="0.5"
              fill="#494949"
              fillOpacity="1"
              stroke="none"
              strokeWidth="0.25"
              opacity="1"
            ></circle>
            <circle
              id="circle4676"
              cx="6"
              cy="1023.112"
              r="0.5"
              fill="#494949"
              fillOpacity="1"
              stroke="none"
              strokeWidth="0.25"
              opacity="1"
            ></circle>
            <path
              id="path4678"
              fill="none"
              fillRule="evenodd"
              stroke="#494949"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeWidth="0.375"
              d="M6 1017.362v6"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RugbyPitch;
