import React from 'react';
import {ClimateJusticeMap} from "./ClimateJusticeMap";

import "../../../stylesheets/ourtrees.scss";
import {Fill, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import {Vector} from "ol/layer";
import {convertToOLFeatures} from "../../../utilities/utilities";
import {ANCHORS} from "../../static_props/OurTrees";


export const DisadvantagedMap = (
	{
		address,
		originalFeatureLayer,
		fetchFeatures,
	}
) => {
	let originalFeatureSource = originalFeatureLayer.getSource();
	let disadvantagedFeaturesLayer = new Vector({
		title: "disadvantagedFeatures",
		source: new VectorSource(),
		style: (feature) => {
			// 0 means not disadvantaged, 1 means disadvantaged
			let strokeColor = `#${feature.get("SN_C") === 0 ? "0571B0" : "CA0020"}`;
			return new Style({
				stroke: new Stroke({
					color: strokeColor,
					width: 2,
				}),
				fill: new Fill({
					color: `${strokeColor}50`,
				}),
			});
		},
	});

	originalFeatureSource.on("featuresloadend", async () => {
		let disadvantagedOLFeatures = convertToOLFeatures(
			await fetchFeatures("landscape:climate-and-economic"),
			["GEOID", "SN_C"]
		);
		disadvantagedFeaturesLayer
			.getSource()
			.addFeatures(disadvantagedOLFeatures);
	});

	let disadvantagedMapIntroText = <>
		<h4>Climate and Economy</h4>
		<div className={"d-flex-column cejst-legend-container"}>
			<div className={"d-flex justify-content-start"}>
				<div className={"me-1 disadvantaged-legend red"}/>
				<p>Disadvantaged</p>
			</div>
			<div className={"d-flex justify-content-start"}>
				<div className={"me-1 disadvantaged-legend blue"}/>
				<p>Not disadvantaged</p>
			</div>
			<div className={"d-flex justify-content-start"}>
				<div className={"me-1 disadvantaged-legend black"}/>
				<p>{address} boundary</p>
			</div>
		</div>
	</>;
	let disadvantagedMapLowerText = <p>This map shows census
		tracts in and around {address}. Those
		in red are considered disadvantaged per the{" "}
		<a target={"_blank"} href={"https://screeningtool.geoplatform.gov/en/"}>
			Climate and Economic Justice Screening Tool</a>,
		while those in blue are not. For larger areas, please visit{" "}
		{ANCHORS.landscape} to view them in more detail.
	</p>;

	return (
		<ClimateJusticeMap
			originalFeatureLayer={originalFeatureLayer}
			variableMapParameters={
				{
					id: "disadvantagedMap",
					layers: [
						disadvantagedFeaturesLayer,
						originalFeatureLayer,
					],
				}
			}
		>
			{{
				textAboveTheMap: disadvantagedMapIntroText,
				textBelowTheMap: disadvantagedMapLowerText,
			}}
		</ClimateJusticeMap>
	);
};
