import React, {Component} from "react";

import {withRouter} from "react-router-dom";

import {
	Container,
	Row,
	Col,
} from "reactstrap";

import {Health} from "../parts/Health";
import {Pests} from "../parts/Pests";
import {SaveButton} from "../parts/TreeFields";


class HTHC extends Component {

	validateInputs = () => {
		let history = {...this.props.history};
		let path = "/dashboard";
		this.props.saveProject(history, path);
	};

	render() {
		let text = <span>All done! Add more trees<br/>or get results.</span>;

		return (
			<Container fluid>
				<Row>
					<Col className={"text-center"}>
						<p>
							<strong>Capturing this information
								helps </strong>
							<br/>
							<a
								href={"https://www.conservationgateway.org/ConservationPractices/cities/hthc/Pages/default.aspx"}
								target={"_blank"}
								rel={"noopener noreferrer"}
							>
								The Healthy Trees, Healthy Cities
								Initiative by
								the Nature Conservancy.
							</a>
						</p>
					</Col>
				</Row>
				<Row>
					<Col className={"text-center"}>
						<SaveButton
							text={"No thanks, take me to the results"}
							saveProject={this.props.saveProject}
							validateInputs={this.validateInputs}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{size: 12}}
						sm={{size: 6}}
						lg={{size: 4, offset: 1}}
						xl={{size: 3, offset: 2}}
					>
						<Health
							hthc={this.props.hthc}
							updateProject={this.props.updateProject}
						/>
					</Col>
					<Col
						className={"mt-4"}
						xs={{size: 12}}
						sm={{size: 6}}
						md={{offset: 2}}
						lg={{size: 4}}
						xl={{size: 3}}
					>
						<Pests
							hthc={this.props.hthc}
							updateProject={this.props.updateProject}
						/>
					</Col>
				</Row>
				<Row className={"mt-5"}>
					<Col className={"text-center"}>
						<SaveButton
							text={text}
							saveProject={this.props.saveProject}
							validateInputs={this.validateInputs}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default withRouter(HTHC);
