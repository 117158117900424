import Base from "itr/base"


// XXX: these fields match both the APIv3 input and output, but are clustered
//   separately in the output.
class Building extends Base{
	// These fields are in the top-level 'energy' in APIv3 output.
	hemisphere = null
	electricRate = -1 // -1 will change to null during APIv3 beta.
	naturalGasRate = -1 // -1 will change to null during APIv3 beta.
	// These fields are 'building' in APIv3 output.
	distance = null
	direction = null
	vintage = null
	heated = 1 // boolean 1, 0 will change to true, false during beta.
	cooled = 1 // boolean 1, 0 will change to true, false during beta.

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


export { Building }
export default Building

