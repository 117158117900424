import React, {memo} from "react";

import {
	Col,
	Label,
	Row
} from "reactstrap";

import {FormField} from "./FormField";
import {
	InfoModalButton,
	RadioButtonGroup,
} from "./Buttons";
import {
	VINTAGES,
	DIRECTIONS,
	DISTANCES,
} from "../../utilities/resources";


export const Direction = memo(
	(
		{
			direction,
			updateProject,
		}
	) => {
		let label =
			<span>Estimate the compass direction <span className={"text-primary"}>from the tree to nearest building.</span></span>;

		let updateDirection = (value) => {
			updateProject({building: {direction: value}});
		};

		let children = [];
		DIRECTIONS.forEach((d, i) => {
			children.push(
				<option
					disabled={d[0] === -1}
					key={d[0]}
					value={d[0]}
				>
					{d[1]}
				</option>
			)
		});

		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
				>
					<FormField
						classList={"required"}
						feedback={
							"Please estimate the compass" +
							" direction from the tree towards the" +
							" building."
						}
						onChange={updateDirection}
						id={"direction"}
						label={label}
						type={"select"}
						value={direction}
						children={children}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 3}}
					lg={{offset: 2}}
				>
					<InfoModalButton
						subject={"direction"}
					/>
				</Col>
			</Row>
		)
	}
);


export const Distance = memo(
	(
		{
			unitType,
			distance,
			updateProject,
		}
	) => {
		let updateDistance = (value) => {
			updateProject({building: {distance: value}});
		};

		let children = [];
		DISTANCES.forEach((d, i) => {
			children.push(
				<option
					disabled={d[0] === -1}
					key={d[0]}
					value={d[0]}
				>
					{unitType ? d[1] : d[2]}
				</option>
			)
		});

		return (
			<Row className={"mt-2"}>
				<Col
					className={"pe-0 pe-sm-3"}
					xs={{size: 10}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
				>
					<FormField
						classList={"required"}
						feedback={
							"Please select the distance" +
							" from the tree to the building."
						}
						onChange={updateDistance}
						id={"distance"}
						label={"How far is it from the building?"}
						type={"select"}
						value={distance}
						children={children}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 3}}
					lg={{offset: 2}}
				>
					<InfoModalButton
						subject={"distance"}
					/>
				</Col>
			</Row>
		)
	}
);


export const Proximity = memo(
	(
		{
			unitType,
			proximity,
			updateProject,
		}
	) => {
		let distance = unitType ? "60 feet" : "18 meters";
		let selected = proximity;
		let updateProximity = (value) => {
			updateProject({building: {proximity: value}})
		};

		if (selected === true) selected = 0;
		if (selected === false) selected = 1;

		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 8, offset: 1}}
					md={{size: 6, offset: 2}}
					lg={{size: 4, offset: 3}}
				>
					<Label>
						Is it within {distance} of a building?
					</Label>
					<RadioButtonGroup
						onRadioButtonClick={updateProximity}
						id={"proximity"}
						selected={selected}
						settings={
							[
								{text: "Yes"},
								{text: "No"},
								{text: "Skip"},
							]
						}
						title={"Toggle between whether a building is near your tree or not, or skip this option."}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 2}}
					md={{offset: 1}}
				>
					<InfoModalButton
						subject={"proximity"}
					/>
				</Col>
			</Row>
		)
	}
);


export const Vintage = memo(
	(
		{
			vintage,
			updateProject,
		}
	) => {
		let updateVintage = (value) => {
			updateProject({building: {vintage: value}});
		};

		let children = [];
		VINTAGES.forEach((v, i) => {
			children.push(
				<option
					disabled={v[0] === -1}
					key={v[0]}
					value={v[0]}
				>
					{v[1]}
				</option>
			)
		});

		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
				>
					<FormField
						classList={"required"}
						feedback={
							"Please select the vintage" +
							" of the building."
						}
						onChange={updateVintage}
						id={"vintage"}
						label={"How old is the building?"}
						type={"select"}
						value={vintage}
						children={children}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 3}}
					lg={{offset: 2}}
				>
					<InfoModalButton
						subject={"vintage"}
					/>
				</Col>
			</Row>
		)
	}
);
