import Base from "itr/base"


class HTHC extends Base {
	dieback = null
	discoloration = null
	defoliation = null
	vigor = null
	hthcExposure = null
	transparency = null
	damage = null
	exudation = null
	decay = null
	loss = null
	wilt = null
	shoots = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


export { HTHC }
export default HTHC