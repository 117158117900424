import React, {useEffect, useRef, useState} from "react";
import {Col, FormFeedback, Label, Row} from "reactstrap";
import {Maple} from "../../img/leaves/maple";
import {Oak} from "../../img/leaves/oak";
import {Sycamore} from "../../img/leaves/sycamore";
import {Basswood} from "../../img/leaves/basswood";
import {Locust} from "../../img/leaves/locust";
import {Ash} from "../../img/leaves/ash";
import {Pear} from "../../img/leaves/pear";
import {Sweetgum} from "../../img/leaves/sweetgum";
import {Pine} from "../../img/leaves/pine";
import {Palm} from "../../img/leaves/palm";
import {SpeciesSelector} from "./SpeciesSelector";
import {InfoModalButton} from "./Buttons";
import {ToggleSwitch} from "./ToggleSwitch";
import {StorageHandler} from "../../classes/storage";
import {DEVMODE} from "../../utilities/resources";


const COUNTRIES_ALLOWED_FOR_SHORTCUT = [
	"us",
	"usa",
	"united states",
	"u.s.",
	"canada",
	"ca",
];
const BUTTON_PARAMETERS = [
	{
		image: Maple,
		label: "Maple",
		species: "AC",
		common: "Maple spp",
		scientific: "Acer",
	},
	{
		image: Oak,
		label: "Oak",
		species: "QU",
		common: "Oak spp",
		scientific: "Quercus",
	},
	{
		image: Sycamore,
		label: "Sycamore",
		species: "PL3",
		common: "Sycamore spp",
		scientific: "Platanus",
	},
	{
		image: Basswood,
		label: "Basswood",
		species: "TI",
		common: "Basswood spp",
		scientific: "Tilia",
	},
	{
		image: Locust,
		label: "Locust",
		species: "GL3",
		common: "Locust spp",
		scientific: "Gleditsia",
	},
	{
		image: Ash,
		label: "Ash",
		species: "FR",
		common: "Ash spp",
		scientific: "Fraxinus",
	},
	{
		image: Pear,
		label: "Pear",
		species: "PY",
		common: "Pear spp",
		scientific: "Pyrus",
	},
	{
		image: Sweetgum,
		label: "Sweetgum",
		species: "LI9",
		common: "Sweetgum spp",
		scientific: "Liquidambar",
	},
	{
		image: Pine,
		label: "Pine",
		species: "PI2",
		common: "Pine spp",
		scientific: "Pinus",
	},
	{
		image: Palm,
		label: "Palm",
		species: "AR10",
		common: "Arecastrum Palm spp",
		scientific: "Arecastrum",
	},
];
const THRESHOLD = 3;


export const SpeciesFieldContainer = (
	{
		project,
		updateProject,
	}
) => {
	const SHOW_SHORTCUTS = COUNTRIES_ALLOWED_FOR_SHORTCUT.includes(project.location.nation.toLowerCase());
	let [showLeafImages, setShowLeafImages] = useState(true);
	let [processing, setProcessing] = useState(true);
	let [masterList, setMasterList] = useState({});
	let [taxonomies, setTaxonomies] = useState({
		classes: [],
		genera: [],
		species: [],
	});
	let [options, setOptions] = useState([]);
	let tree = structuredClone(project.tree.__fields());
	let name = tree.namingType ? "common" : "scientific";
	let inputRef = useRef(null);
	let optionContainerRef = useRef(null);
	let leafButtonContainerRef = useRef(null);

	// On initial render, populate the input if an existing tree is passed in.
	useEffect(() => {
			if (inputRef.current.value !== tree[name]) {
				inputRef.current.value = tree[name];
			}
		},
		[]
	);

	// Handles "closing" the options container for clicks outside said
	// container, the species input, or a leaf button.
	useEffect(() => {
			const handleClickOutside = (event) => {
				let shouldBlur = !(
					(
						inputRef.current &&
						inputRef.current.contains(event.target)
					) || (
						optionContainerRef.current &&
						optionContainerRef.current.contains(event.target)
					) || (
						leafButtonContainerRef.current &&
						leafButtonContainerRef.current.contains(event.target)
					)
				);

				if (shouldBlur) {
					inputRef.current.blur();
					setOptions([]);
				}
			};

			document.addEventListener("click", handleClickOutside);
			return () => {
				document.removeEventListener("click", handleClickOutside);
			}
		},
		[]
	);

	// Fetches the species data from localstorage if available, or from the
	// Engine if not.
	useEffect(
		() => {
			let speciesListStorageKey = "i-Tree_Species_List";
			let storageHandler = new StorageHandler({key: speciesListStorageKey});
			let storageAvailable = storageHandler.checkForStorage();

			// Try to retrieve the species list from local storage.
			let storedValues = {};
			// Update every day plus 1 millisecond
			let update = 86400001;
			let now = new Date().getTime();
			let difference = now - storedValues.date;

			if (storageAvailable) {
				storedValues = storageHandler.getFromStorage();
				if (Object.keys(storedValues).length) {
					setMasterList(storedValues.list);
				}
			}
			if (
				!storageAvailable ||
				!Object.keys(storedValues).length ||
				(difference >= update)
			) {
				// Fetch the species list from the Engine if it
				// can't be retrieved from storage, storage is
				// unavailable, or the stored version is out
				// of date.
				getSpeciesData().then((json) => {
					let newList = createList(json);
					if (storageAvailable) {
						storageHandler.putInStorage(
							{
								list: newList,
								date: now,
							}
						);
					}
					setMasterList(newList);
				});
			}
		},
		[]
	);

	// Handles sorting the species data by taxonomy if the species list changes
	// or if the naming type (common/scientific) changes.
	useEffect(
		() => {
			if (Object.keys(masterList).length) {
				const sortListByTaxonomy = () => {
					let species = [];
					let genera = [];
					let classes = [];

					for (let item in masterList) {
						let entry = {
							spp: masterList[item]["spp"],
							common: masterList[item]["common"],
							scientific: masterList[item]["scientific"],
							rank: masterList[item]["rank"],
						};
						switch (masterList[item]["rank"]) {
							case "class":
								classes.push(entry);
								break;
							case "genus":
								genera.push(entry);
								break;
							case "species":
								species.push(entry);
								break;
							default:
								throw new Error(
									`A problem has occurred while sorting species by taxonomy.`
								);
						}
					}
					return {
						classes: classes.sort(compareValues(name)),
						genera: genera.sort(compareValues(name)),
						species: species.sort(compareValues(name)),
					};
				};
				let listSortedIntoTaxonomies = sortListByTaxonomy(masterList);
				setTaxonomies(listSortedIntoTaxonomies);
				if (processing) setProcessing(false);
			}
		},
		[masterList, name]
	);

	const handleLeafButtonClick = (parameters) => {
		inputRef.current.value = parameters[name];
		updateProject(
			{
				tree: {
					common: parameters.common,
					scientific: parameters.scientific,
					species: parameters.species,
				}
			},
			handleOptionsDisplay
		);
	};

	const updateNamingType = () => {
		updateProject(
			{
				tree: {
					namingType: !tree.namingType,
				}
			}
		)
	};

	const getSpeciesData = async () => {
		let key = process.env.REACT_APP_ITREE_ENGINE_API;
		let url = `https://${DEVMODE ? "dev." : ""}api.itreetools.org/v2/getSpecies/?key=${key}&output=JSON`;

		let response = await fetch(url);
		if (response.ok) {
			return await response.json();
		} else {
			throw new Error("There has been an error creating the species list.");
		}
	};

	const createList = (json) => {
		let columns = {};
		let list = {};
		for (let i = 0; i < json.meta.length; i++) {
			columns[json.meta[i]["name"]] = i;
		}
		json.data.map(item => list[item[columns.Code].toUpperCase()] = {
			spp: item[columns.Code].toUpperCase(),
			scientific: item[columns.ScientificName],
			common: item[columns.CommonName],
			rank: item[columns.SpeciesType].toLowerCase() // taxonomic rank.
		});
		return list;
	};

	const compareValues = (key, order = "asc") => {
		return (a, b) => {
			let comparison = 0;
			let first = a[key];
			let second = b[key];
			if (typeof first === "string") first = first.toLowerCase();
			if (typeof second === "string") second = second.toLowerCase();

			if (first > second) {
				comparison = 1;
			} else if (first < second) {
				comparison = -1;
			}

			return (
				(order === "desc") ? (comparison * -1) : comparison
			);
		}
	};

	const onClick = (event) => {
		let dataset = event.target.dataset;
		if (typeof dataset.code === "undefined") {
			dataset = event.target.parentElement.dataset;
		}
		let {code, common, scientific} = dataset;
		inputRef.current.value = tree.namingType ? common : scientific;
		updateProject(
			{
				tree: {
					species: code,
					common: common,
					scientific: scientific,
				}
			},
			setOptions([])
		);
	};

	const createOptions = (filteredTaxonomies) => {
		let options = [];
		for (let taxonomy in filteredTaxonomies) {
			let classifications = filteredTaxonomies[taxonomy];
			classifications.forEach((classification, index) => {
				options.push(
					<div
						key={classification.spp}
						data-code={classification.spp}
						data-common={classification.common}
						data-scientific={classification.scientific}
					>
						<p className={"m-0 p-0"}>
							{classification[tree.namingType ? "common" : "scientific"]}
						</p>
						<small className={"fst-italic"}>
							{classification[tree.namingType ? "scientific" : "common"]}
						</small>
					</div>);
			});
		}

		return options;
	};

	// Filters the master list of taxonomies by a user-input value.
	const filterTaxonomies = (value) => {
		let filteredTaxonomies = {
			classes: [],
			genera: [],
			species: [],
		};

		for (let taxonomy in taxonomies) {
			filteredTaxonomies[taxonomy] = taxonomies[taxonomy].filter(
				(entry) => entry[name].toLowerCase().includes(value.toLowerCase())
			);
		}
		return filteredTaxonomies;
	}

	// Determines if the options container should "open", based on the number
	// of possible options.
	const handleOptionsDisplay = () => {
		showFeedback(false);
		let value = inputRef.current.value;
		if (value.length >= THRESHOLD) {
			let filteredTaxonomies = filterTaxonomies(value);

			if (
				filteredTaxonomies.classes.length <= 0 &&
				filteredTaxonomies.genera.length <= 0 &&
				filteredTaxonomies.species.length <= 0
			) {
				showFeedback(true);
				setOptions([]);
			} else {
				setOptions(createOptions(filteredTaxonomies));
			}
		} else {
			if (options.length >= 1) {
				setOptions([]);
			}
		}
	};

	// Controls whether the feedback element is visible.
	const showFeedback = (error) => {
		let feedbackClassList = document
			.getElementById("species-form")
			.querySelector(".invalid-feedback")
			.classList;

		if (error) {
			inputRef.current.classList.add("is-invalid");
			feedbackClassList.add("d-block");
			feedbackClassList.remove("d-none");
		} else {
			inputRef.current.classList.remove("is-invalid");
			feedbackClassList.remove("d-block");
			feedbackClassList.add("d-none");
		}
	};

	return (
		<>
			<Row className={"mt-1 mb-0"}>
				<Col
					xs={{size: 12}}
					sm={{size: 11, offset: 1}}
					md={{size: 10, offset: 2}}
					lg={{size: 9, offset: 3}}
				>
					<Label
						className={"required"}
						for={"species-input"}
					>
						Tree Species <span className={"text-danger"}>
							{SHOW_SHORTCUTS ?
								<span id={"leaf-button-name-selectors"}>(Stumped? Try a
									<button
										type="button"
										onClick={() => setShowLeafImages(false)}
									>
										Name
									</button>
									 or
									<button
										type="button"
										onClick={() => setShowLeafImages(true)}
									>
										Icon
									</button>
									or type to search)
								</span>
								:
								"(Type to search)"
							}
						</span>
					</Label>
				</Col>
			</Row>
			{SHOW_SHORTCUTS ?
				<Row className={`mt-1 mb-${SHOW_SHORTCUTS ? "2" : "0"}`}>
					<Col
						xs={{size: 10}}
						sm={{size: 7, offset: 1}}
						md={{size: 5, offset: 2}}
						lg={{size: 4, offset: 3}}
					>
						<div
							className={"leaf-button-container"}
							ref={leafButtonContainerRef}
						>
							{
								BUTTON_PARAMETERS.map(
									(
										{
											species,
											common,
											scientific,
											image,
											label
										},
										index
									) => {
										return (
											<button
												className={`leaf-button${species === tree.species ? " active" : ""}`}
												key={species}
												title={common}
												type={"button"}
												value={index}
												onClick={
													() => handleLeafButtonClick({
														species,
														common: label,
														scientific
													}, index)
												}
											>
												{showLeafImages ? (
													{image}.image()
												) : (
													<strong>{label}</strong>
												)}
											</button>
										)
									}
								)
							}
						</div>
					</Col>
				</Row>
					:
				null
			}
			<Row>
				<Col
					xs={{size: 10}}
					sm={{offset: 1}}
					md={{size: 7, offset: 2}}
					lg={{size: 5, offset: 3}}
				>
					<div id={"species-form"}>
						<SpeciesSelector
							inputRef={inputRef}
							namingType={tree.namingType}
							optionContainerRef={optionContainerRef}
							options={options}
							processing={processing}
							onClick={onClick}
							handleOptionsDisplay={handleOptionsDisplay}
							showFeedback={showFeedback}
						/>
						<FormFeedback>
							A valid species must be selected from the list.
						</FormFeedback>
					</div>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
				>
					<InfoModalButton
						className={"mt-n2"}
						subject={"species"}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					xs={{size: 8}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
					xl={{size: 3, offset: 3}}
				>
					<ToggleSwitch
						className={"mt-1"}
						updateField={updateNamingType}
						id={"naming"}
						value={tree.namingType}
						text={{left: "Common", right: "Scientific"}}
						title={"Toggle between common and scientific tree names."}
					/>
				</Col>
				<Col className={"d-flex align-items-center"}>
					<a
						className={"small identilink text-underline"}
						href={"https://www.itreetools.org/tools/tree-identification-tools"}
						rel={"noopener noreferrer"}
						target={"_blank"}
					>
						Help with tree identification
					</a>
				</Col>
			</Row>
		</>
	)
}