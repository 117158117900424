import React, {Component} from 'react';

import {
	Row,
	Col,
} from "reactstrap";

import itree from "../../../img/iTree_primary_logo_restricted_SMALL.png";


export class Top extends Component {
	render() {
		return (
			<Row>
				<Col>
					<div className={"d-flex justify-content-between"}>
						<h3 className={"fw-bold"}>
							{this.props.text}
						</h3>
						<img
							className={"small-image"}
							src={itree}
							alt="i-Tree Logo"
						/>
					</div>
				</Col>
			</Row>
		)
	}
}