export const toFromJson = (obj) => JSON.parse(JSON.stringify(obj))
export const fromToJson = (obj) => JSON.stringify(JSON.parse(obj))


// import { copy } from "@stdlib/utils/copy"
export const deepCopy = (to, from) => {
	for (const x in from) {
		if (from[x] !== null && typeof from[x] === "object") {
			if (
				(to[x] !== null && typeof to[x] !== "object") ||
				Array.isArray(to[x])
			) {
				to[x] = {}
			}
			deepCopy(to[x], from[x])
		} else if (Array.isArray(from[x])) {
			if (
				!Array.isArray(to[x]) ||
				(to[x] !== null && typeof to[x] === "object")
			) {
				to[x] = []
			}
			deepCopy(to[x], from[x])
		} else {
			to[x] = from[x]
		}
	}
}


// Replace all dashes (hypens) with camelCase (medial capitals).
export const dash2camel = (a) =>
	a.replace(/-\w/g, (x) => x.replace(/-/, "").toUpperCase())

// Replace slugified text (all underscores) with camelCase (medial capitals).
export const slug2camel = (a) =>
	a.replace(/_\w/g, (x) => x.replace(/_/, "").toUpperCase())

// Replace all camelCase (medial capitals) with dashes (hypens).
export const camel2dash = (a) =>
	a.replace(/([A-Z])/g, "-$1").toLowerCase()

// Replace camelCase (medial capitals) with slugified text (all underscores).
export const camel2slug = (a) =>
	a.replace(/([A-Z])/g, "_$1").toLowerCase()


const objectXfunc = (xfunc, obj) => {
	let result = {}
	for (const prop in obj) {
		result[xfunc(prop)] = obj[prop]
	}
	return result
}


export const object2dash2camel = (obj) => objectXfunc(dash2camel, obj)
export const object2slug2camel = (obj) => objectXfunc(slug2camel, obj)
export const object2camel2dash = (obj) => objectXfunc(camel2dash, obj)
export const object2camel2slug = (obj) => objectXfunc(camel2slug, obj)

