import React from "react";

function ParkingLots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      width="101.833"
      height="72"
      version="1.1"
      viewBox="0 0 1052.4 744.09"
    >
      <style id="screen-color-temperature-style" type="text/css"></style>
      <g id="layer1" transform="translate(0 -308.27)">
        <path
          id="path3797-7"
          fill="#fff"
          d="M500-353.05c0 25.247-42.853 45.714-95.714 45.714-52.862 0-95.714-20.467-95.714-45.714s42.853-45.714 95.714-45.714c52.862 0 95.714 20.467 95.714 45.714z"
          color="#000"
          transform="translate(110 333.98)"
        ></path>
        <g id="g4189" transform="translate(2.857 -2.857)">
          <path
            id="rect3766"
            fill="#3a393f"
            d="M3.748 687.54H1047.6480000000001V1054.33H3.748z"
            color="#000"
          ></path>
          <path
            id="rect3766-1"
            fill="#77a0e0"
            d="M3.748 320.4H1047.6480000000001V687.19H3.748z"
            color="#000"
          ></path>
          <path
            id="path3797"
            fill="#fff"
            d="M278.56 64.094c-24.751 0-46.412 8.738-58.469 21.844a95.393 95.393 0 00-18.656-1.844c-36.293 0-65.719 19.823-65.719 44.281s29.426 44.281 65.719 44.281c22.783 0 42.836-7.788 54.625-19.656 7.054 1.635 14.611 2.531 22.5 2.531 37.871 0 68.594-20.47 68.594-45.719 0-25.247-30.723-45.719-68.594-45.719z"
            color="#000"
            transform="translate(0 308.27)"
          ></path>
          <path
            id="path3797-0"
            fill="#fff"
            d="M922.84 392.37c-24.751 0-46.412 8.738-58.469 21.844a95.393 95.393 0 00-18.656-1.844c-36.293 0-65.719 19.823-65.719 44.281s29.426 44.281 65.719 44.281c22.783 0 42.836-7.788 54.625-19.656 7.054 1.635 14.611 2.531 22.5 2.531 37.871 0 68.594-20.47 68.594-45.719 0-25.247-30.723-45.719-68.594-45.719z"
            color="#000"
          ></path>
          <path
            id="path3863"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M16.805 878.08h1017.8"
          ></path>
          <path
            id="path3903"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M174.29 718.08l2.857 302.86"
            color="#000"
          ></path>
          <path
            id="path3903-8"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M335.71 719.79l2.857 302.86"
            color="#000"
          ></path>
          <path
            id="path3903-82"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M504.29 718.08l2.857 302.86"
            color="#000"
          ></path>
          <path
            id="path3903-4"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M667.14 723.79L670 1026.6"
            color="#000"
          ></path>
          <path
            id="path3903-5"
            fill="#fff"
            stroke="#fff"
            strokeWidth="16.054"
            d="M838.57 723.79l2.857 302.86"
            color="#000"
          ></path>
          <g id="g4141-2" transform="matrix(.41935 0 0 .33987 1150.8 283.63)">
            <path
              id="path3990-7"
              fill="#e6e6e6"
              strokeWidth="0"
              d="M-447.56 1391.6c-59.031 0-106.86 11.007-106.86 24.573 2.598 17.743-4.101 102.31-4.101 172.48h5.138c-.679 1.119-1.037 2.275-1.037 3.437 0 13.565 47.833 24.533 106.86 24.533 59.03 0 106.86-10.968 106.86-24.533 0-1.162-.358-2.318-1.037-3.437h.848v-160.71c-2.226-23.495-72.025-36.103-106.68-36.346z"
              color="#000"
            ></path>
            <rect
              id="rect4121-6"
              width="40"
              height="314.29"
              x="-465.71"
              y="1106.6"
              fill="#000"
              strokeWidth="0"
              color="#000"
              ry="0"
            ></rect>
            <g
              id="g4057-1"
              transform="matrix(1.6607 0 0 1.4652 1418.6 -359.34)"
            >
              <rect
                id="rect4051-4"
                width="160"
                height="160"
                x="-1200"
                y="544.09"
                fill="#00f"
                strokeWidth="0"
                color="#000"
                ry="40"
                transform="translate(0 308.27)"
              ></rect>
              <text
                id="text4053-2"
                style={{ lineHeight: "125%" }}
                x="-1140.87"
                y="1011.237"
                fill="#fff"
                fontFamily="Sans"
                fontSize="139.46"
                letterSpacing="0"
                transform="scale(1.027 .97371)"
                wordSpacing="0"
                xmlSpace="preserve"
              >
                <tspan id="tspan4055-3" x="-1140.87" y="1011.237" fill="#fff">
                  P
                </tspan>
              </text>
            </g>
            <rect
              id="rect4121-5-2"
              width="74.286"
              height="62.857"
              x="-482.86"
              y="1366.6"
              fill="#000"
              strokeWidth="0"
              color="#000"
              ry="0"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ParkingLots;
