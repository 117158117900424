import ky from "ky-universal"

import {ENGINE_KEY} from "itr/_meta/config"
import {ENGINE_URL} from "itr/_meta/config"
import {ENGINE_API_VERSION_ID} from "itr/_meta/config"
import {RESPONSE_STATUS} from "itr/_meta"
import Base from "itr/base"
import Benefit from "itr/benefit"
import Building from "itr/building"
import Location from "itr/location"
import Tree from "itr/tree"
import {toFromJson} from "itr/utility"


// FIXME: this or Project needs logic for overlapping objects and their
//   duplicate fields.
const makeShallow = (obj, to) => {
	if (typeof to === "undefined" || to === null) {
		to = {}
	}
	let from = obj
	for (const x in from) {
		if (from[x] !== null && typeof from[x] === "object") {
			makeShallow(from[x], to)
		} else if (Array.isArray(from[x])) {
			if (
				!Array.isArray(to[x]) ||
				(to[x] !== null && typeof to[x] === "object")
			) {
				to[x] = []
			}
			// TODO: comma separate.
			makeShallow(from[x], to)
		} else {
			if (
				from[x] !== null &&
				// FIXME: Check against undefined is a stop-gap to avoid
				//  overlapping values.
				typeof to[x] === "undefined"
			) {
				to[x] = from[x]
			}
		}
	}

	return to
}


// XXX: the location, building, and trees fields are library conveniences and
//   do not match the APIv3 output. The rest of the fields match both the input
//   and the output.
//   However, for input, groupName may change to projectGroupName, and
//   plantingType may change to projectPlantingType, but they would remain the
//   same for output.
class Project extends Base {
	// Attributes are alphabetical.
	groupName = null // overlaps with Tree.
	mortalityRate = 0
	notes = null // overlaps with Tree.
	plantingType = null // overlaps with Tree.
	report = "annual"
	timeline = "forwards"
	years = 1
	// Other main encapsulations.
	location = null // overlaps with Tree (only lat/lng).
	building = null // overlaps with Tree.
	trees = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.location instanceof Location)) {
			this.location = new Location(this.location)
		}
		if (! (this.building instanceof Building)) {
			this.building = new Building(this.building)
		}
		if (this.trees === null) {
			this.trees = []
		}
	}

	static prepareForRequest = (obj) => makeShallow(toFromJson(obj))

	// TODO: what about "fetchBenefits"?
	//async process = () => {}
	// Server hard-limit is 30 minutes (1000 * 60 * 30 milliseconds).
	process = async ({timeout = 1_800_000, signal = null, benefitType = "annual"} = {}) => {
		// FIXME: sometimes this returns an array and sometimes an object.
		if (!this.key) this.key = ENGINE_KEY
		if (!this.url) this.url = ENGINE_URL
		if (!this.api) this.api = ENGINE_API_VERSION_ID
		if (this.api !== "v3") throw Error("Only APIv3 is supported.")

		// TODO: move benefitTypes to being a class attribute.
		// TODO: rename benefitTypes and benefitType.
		const benefitTypes = ["annual", "cumulative", "summation"]
		if (! benefitTypes.includes(benefitType)) {
			throw Error(
				`${benefitType} is not a valid benefit type.
				 It must be one of: ${benefitTypes.toString()}.`
			)
		}

		let response = null
		try {
			// FIXME: handle error response like HTTP status 403 Forbidden.
			response = await ky(this.url + this.api + `/benefit/?key=${this.key}`,
			{
				method: "post",
				http2: true,
				// TODO: i-Tree Engine has not finished implementing
				//   accepting JSON. Eventually that will be used because of
				//   the added flexibility it would allow this to provided.
				//json: Project.prepareForRequest(this),
				body: new URLSearchParams(Project.prepareForRequest(this)),
				responseType: "json",
				signal: signal,
				timeout: timeout,
				// Progress output needs 'ReadableStream' which is
				// unavailable in this environment
				// (web-streams-polyfill)
				// (this?: AVA, nodejs 12, ...?).
				//onDownloadProgress: (progress, chunk) => {
				//	console.info(
				//		`${progress.percent * 100}% - ` +
				//		`${progress.transferredBytes} of ` +
				//		`${progress.totalBytes} bytes`
				//	)
				//},
			}).json()
		} catch (e) {
			if (e.message || e instanceof ky.HTTPError) {
				return {error: e.message}
			} else {
				return {error: JSON.stringify(e)}
			}
		}

		if (response.status == RESPONSE_STATUS.OK) {
			// TODO: over the variety of reports from the response.
			//   individual tree, total trees.
			//     annual, cumulative, summation
			//       category, categoryWorth, totalWorth
			let data = {
				benefits: [],
				meta: {
					'apiVersion': response['api-version'],
					'dbVersion': response['db-version'],
					'engineVersion': response['engine-version'],
				},
				warnings: response.warnings.length ? response.warnings : [],
			};

			for (const t of Object.values(response.data.trees)) {
				let category = t.benefits[benefitType].category

				category.forEach((c) => {
					data.benefits.push(
						new Benefit(
							{
								projectResponse: c
							}
						)
					)
				})
			}

			return data
		} else if (response.status == RESPONSE_STATUS.ERROR) {
			if (response.message) {
				return {error: response.message}
			} else {
				return {error: "Unknown error from i-Tree Engine service."}
			}
		} else {
			return {error: "Unknown status from the i-Tree Engine service."}
		}
	}
}


export {Project}
export default Project

