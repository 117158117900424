import React from "react";
import {createRoot} from "react-dom/client";

import {HashRouter as Router} from "react-router-dom";

import "./stylesheets/custom.scss";
import "./stylesheets/index.css";

import {MyTree} from "./MyTree";
import {OurTrees} from "./OurTrees";
import {Offline} from "./Offline";

import * as serviceWorker from "./serviceWorker";

import {MYTREE} from "./utilities/resources";

document.title = MYTREE ? "MyTree" : "OurTrees";

const container = document.getElementById("root")
const root = createRoot(container);


root.render(
	<Router basename={"/"}>
		{navigator.onLine ?
			MYTREE ?
				<MyTree/>
				:
				<OurTrees/>
			:
			<Offline/>
		}
	</Router>
);


// Prevents security errors on http connections.
if (window.location.protocol === "https:") {
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
}
