import React from "react";

export function Palm() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Palm spp</desc>
			<path
				fill="#006D41"
				d="M72.5-.5h2c4.804 2.915 7.637 7.249 8.5 13A14.437 14.437 0 0185.5 7C90.415 5.59 94.915 3.423 99 .5l1.5 1c-.577 3.855-2.577 6.522-6 8-1.495-.085-2.495.582-3 2a10.76 10.76 0 012.5 3c1.754-1.75 3.92-2.417 6.5-2-.114 2.218-.78 4.218-2 6-2.955.48-5.288 1.98-7 4.5 6.759-.193 12.592 1.974 17.5 6.5.749 1.135.583 2.135-.5 3a53.61 53.61 0 00-13-2.5 80.31 80.31 0 016.5 5.5 30.982 30.982 0 005.5 9 9.86 9.86 0 011 6c-1.175.219-2.175-.114-3-1-1.688-3.688-4.355-6.355-8-8-2.018-1.358-3.851-3.025-5.5-5-1.67 2.198-3.67 2.532-6 1-.348 1.64.152 2.973 1.5 4l1 6a49.391 49.391 0 01-1 11c-.709.904-1.709 1.237-3 1 .31-4.205-.357-8.205-2-12 .13-.876-.203-1.543-1-2a91.124 91.124 0 00-1 19 194.388 194.388 0 01-1.5 26 654.4 654.4 0 00-4.5 29h-14a245.103 245.103 0 01-11.5-50c-2.653 10.726-4.32 10.392-5-1a63.125 63.125 0 00-7 11c-.879-4.062-.045-7.729 2.5-11a30.139 30.139 0 013-3.5l-9-1.5c-1.855-.16-3.188.507-4 2-3.013.22-5.347 1.553-7 4-1.13-1.575-1.297-3.241-.5-5 5.526-5.767 12.36-8.267 20.5-7.5-3.54-4.626-8.207-6.292-14-5-5.038 1.212-5.372-.12-1-4 6.918-2.677 13.085-1.51 18.5 3.5.472-6.226-1.861-11.226-7-15-.75-1.135-.583-2.135.5-3 5.283 2.393 9.45 6.06 12.5 11l5-8c1.638-2.234 3.804-3.567 6.5-4 .754 3.178-.413 5.845-3.5 8-.667 1.333-.667 2.667 0 4a49.776 49.776 0 008.5-12c-1.222-2.478-3.222-3.478-6-3h-2c-2.947-.277-5.78.056-8.5 1a2.428 2.428 0 01-.5-2 27.258 27.258 0 015-3.5c4.03-.326 8.03-.326 12 0-1.803-.637-3.136-1.803-4-3.5a4.452 4.452 0 012-1.5l-1-1a72.472 72.472 0 01-13-8.5c7.854-1.253 14.854.746 21 6l1-1.5a450.785 450.785 0 01-2-15.5z"
			></path>
			<path
				fill="white"
				d="M76.5 35.5c1.306 3.187 1.306 6.52 0 10a51.256 51.256 0 01-6-2 28.83 28.83 0 001.5-4c1.011.837 2.178 1.17 3.5 1a20.677 20.677 0 011-5z"
			></path>
			<path
				fill="white"
				d="M56.5 49.5c2.473.447 2.64 1.447.5 3a4.932 4.932 0 01-.5-3z"
			></path>
			<path
				fill="white"
				d="M54.5 81.5c.321-1.548 1.321-2.215 3-2-.31-3.213.023-6.213 1-9 1.648 1.31 3.314 2.643 5 4l1-2a34.268 34.268 0 00-6-8 32.593 32.593 0 0115 0c-.499-2.764-2.165-4.43-5-5l-7-2a83.154 83.154 0 008-7.5 18.982 18.982 0 018-1.5c-.011 23.388-4.011 46.055-12 68a197.932 197.932 0 01-11-35z"
			></path>
		</svg>
	);
}
