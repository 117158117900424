import React from "react";

export function Ash() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Ash spp</desc>
			<path
				fill="#006D41"
				d="M.5-.5h11c12.747 4.906 22.247 13.572 28.5 26a200.37 200.37 0 014.5-23 8.434 8.434 0 014 .5c7.382 5.626 11.049 13.127 11 22.5 1.116 7.756-.55 14.756-5 21a18.685 18.685 0 006.5 6 70.377 70.377 0 01.5-20c2.667-6.833 6-13.333 10-19.5 4.71 1.725 7.876 5.225 9.5 10.5 3.359 14.708.526 28.041-8.5 40a64.271 64.271 0 006.5 7c-.05-6.8 1.617-13.134 5-19A180.554 180.554 0 0199.5 35c.949-.617 1.782-.45 2.5.5 6.403 19.299 1.569 35.299-14.5 48 2.188 6.41 4.022 6.076 5.5-1 6.788-9.148 15.622-15.481 26.5-19v21c-4.858 7.355-11.191 13.188-19 17.5a20.088 20.088 0 01-6 1.5 118.468 118.468 0 01-1 16h-4l1-6c1.308-3.519 1.308-7.185 0-11-.607.124-.94.457-1 1-5.462 6.275-12.462 9.275-21 9-9.84-1.113-19.174-3.946-28-8.5-.95-.718-1.117-1.551-.5-2.5 4.655-5.413 10.488-8.913 17.5-10.5a92.86 92.86 0 01-23-11.5c-1.225-2.343-.559-4.177 2-5.5l8-4c-9.826-3.5-18.326-9-25.5-16.5.7-3.84 3.2-5.84 7.5-6a19.242 19.242 0 016-1c6.511.053 12.845.887 19 2.5-2.73-3.108-5.897-5.608-9.5-7.5a24.166 24.166 0 01-9.5 4h-9c-2.77.08-5.437-.42-8-1.5a189.24 189.24 0 01-16-9.5v-3a50.535 50.535 0 0112-6 111.53 111.53 0 01-11-26z"
			></path>
			<path
				fill="white"
				d="M67.5 65.5c3.58 1.908 6.58 4.574 9 8l-1 1a50.756 50.756 0 00-15-4.5c2.687-1.049 5.02-2.549 7-4.5z"
			></path>
			<path
				fill="white"
				d="M83.5 84.5c2.588 3.598 4.088 7.598 4.5 12-5.085-3.028-10.585-4.862-16.5-5.5 4.51-1.418 8.51-3.585 12-6.5z"
			></path>
		</svg>
	);
}
