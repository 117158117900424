import React, {useState} from 'react';
import {ClimateJusticeMap} from "./ClimateJusticeMap";

import "../../../stylesheets/ourtrees.scss";
import {Fill, Stroke, Style} from "ol/style";
import VectorSource from "ol/source/Vector";
import {Vector} from "ol/layer";
import {
	convertToOLFeatures,
	extractValuesByField
} from "../../../utilities/utilities";
import {DEVMODE} from "../../../utilities/resources";
import {ANCHORS} from "../../static_props/OurTrees";


export const PPIMap = (
	{
		address,
		originalFeatureLayer,
		fetchFeatures,
	}
) => {
	let title = <span className={"h4"}>Planting Priority Index</span>;
	let [returnable, setReturnable] = useState(
		<>
			{title}
			<strong className={"d-block"}>
				Loading... This may take several minutes. If the map fails
				to load, try again later or visit {ANCHORS.landscape} to run
				PPI scenarios there.
			</strong>
		</>
	);

	let getPPIFeatures = async (blockGroupOLFeatures) => {
		let indeces = await getPPIIndeces(
			extractValuesByField(blockGroupOLFeatures, "geoid")
		);

		if (Object.keys(indeces).length) {
			indexFeatures(blockGroupOLFeatures, indeces);
			return blockGroupOLFeatures;
		} else {
			return [];
		}
	};

	let scenario = [
		{
			value: "tree_stocking_level",
			weight: 30,
			ppi_desire: "low",
		},
		{
			value: "tree_cover_per_capita",
			weight: 30,
			ppi_desire: "low",
		},
		{
			value: "below_poverty_line",
			weight: 40,
			ppi_desire: "high",
		},
	];

	let getPPIIndeces = async (geoids) => {
		let locations = geoids.map((geoid, index) => {
			return {"blkgrp": geoid}
		});

		let handleTimeout = () => {
			controller.abort();
			setReturnable(
				<>
					{title}
					<strong className={"d-block"}>
						We are unable to create a PPI map for this area
						because the request timed out. Please try again
						later or visit {ANCHORS.landscape} to run PPI
						scenarios there.
					</strong>
				</>
			);
		};

		let controller = new AbortController();
		let signal = controller.signal;
		let time = 1000 * 60 * 30; // 30 minutes
		let timeout = setTimeout(() => handleTimeout(), time);
		let url = `https://${DEVMODE ? "dev." : ""}landscape.itreetools.org/maps/ppi/index/hi`;
		let method = "POST";
		let headers = {
			"Accept": "application/json",
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
		};
		let data = new URLSearchParams({
			constraints: JSON.stringify(scenario),
			locations: JSON.stringify(locations),
		});
		let response = await fetch(
			url,
			{
				method: method,
				headers: headers,
				body: data.toString(),
				signal: signal,
			}
		);
		clearTimeout(timeout);

		if (response.ok) return await response.json();
		return {};
	};

	let indexFeatures = (blockGroupOLFeatures, json) => {
		blockGroupOLFeatures.forEach((feature, index) => {
			feature.set("ppiIndex", json[feature.get("geoid")]["index"]);
		});
	};

	let colors = [
		"#313695",
		"#4575b4",
		"#74add1",
		"#abd9e9",
		"#e0f3f8",
		"#fee090",
		"#fdae61",
		"#f46d43",
		"#d73027",
		"#a50026",
		"#a50026",
	];

	let stylePPIFeature = (feature) => {
		let color = colors[Math.round(feature.get("ppiIndex") / colors.length)];
		return new Style({
			stroke: new Stroke({
				color: "gray",
				width: 2,
			}),
			fill: new Fill({
				color: `${color}70`,
			}),
		});
	};

	let ppiFeaturesLayer = new Vector({
		title: "ppi",
		source: new VectorSource(),
		style: (feature) => stylePPIFeature(feature),
	});

	originalFeatureLayer.getSource().on("featuresloadend", async () => {
		let rawFeatures = await fetchFeatures("landscape:blkgrp");

		if (rawFeatures.length >= 2) {
			let blockGroupOLFeatures = convertToOLFeatures(
				rawFeatures,
				["geoid"]
			);
			let features = await getPPIFeatures(blockGroupOLFeatures);
			if (features.length) {
				ppiFeaturesLayer
					.getSource()
					.addFeatures(features);
				prepareTheMap();
			} else {
				setReturnable(
					<>
						{title}
						<strong className={"d-block"}>
							We are unable to create a PPI map for this
							area. Please visit {ANCHORS.landscape} to explore
							other options.
						</strong>
					</>
				);
			}
		} else {
			setReturnable(
				<>
					{title}
					<strong className={"d-block"}>
						We are unable to create a PPI map as this area
						mostly covers only one Census block group. Please
						visit {ANCHORS.landscape} to explore other options.
					</strong>
				</>
			)
		}
	});

	let legend = [];
	colors.forEach((color, index) => {
		if (index < colors.length - 1) {
			legend.push(
				<span
					key={color}
					style={{
						backgroundColor: `${color}70`,
						border: "2px solid gray"
					}}
					className={"d-inline-block legend-square"}
				/>
			);
		}
	});

	let prepareTheMap = () => {
		let textAboveTheMap = <>
			<span className={"h4"}>Planting Priority Index</span>
			<div>
				<small className={"pe-1"}>Low</small>
				{legend}
				<small className={"ps-1"}>High</small>
			</div>
			<div className={"cejst-legend-container"}>
				<div className={"d-flex justify-content-start"}>
					<div className={"me-1 disadvantaged-legend black"}/>
					<p>{address} boundary</p>
				</div>
			</div>
		</>;
		let textBelowTheMap = <p>This maps shows US Census block groups
			in and around {address} ranked by an index weighted toward
			areas with a relatively high proportion of population below
			the poverty line, low tree cover per capita, and high
			available planting space. For more information and to create
			your own specific indices, visit {ANCHORS.landscape}.</p>;

		setReturnable(
			<ClimateJusticeMap
				originalFeatureLayer={originalFeatureLayer}
				variableMapParameters={
					{
						id: "ppiMap",
						layers: [
							ppiFeaturesLayer,
							originalFeatureLayer,
						],
					}
				}
			>
				{{
					textAboveTheMap: textAboveTheMap,
					textBelowTheMap: textBelowTheMap,
				}}
			</ClimateJusticeMap>
		);
	}

	return returnable;
};
