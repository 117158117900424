import React, {Component} from 'react';

import {
	Col,
	Container,
	Row
} from "reactstrap";


export class GenericNotFound extends Component {

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<p className={"h1"}>
							Page Not Found
						</p>
					</Col>
				</Row>
			</Container>
		)
	}
}
