import React from "react";
import "../../stylesheets/species-selector.scss";


export const SpeciesSelector = (
	{
		inputRef,
		namingType,
		optionContainerRef,
		options,
		processing,
		onClick,
		handleOptionsDisplay,
		showFeedback,
	} = {}
) => {
	let placeholder = processing ? "Retrieving species list" : (namingType ? 'Try: "Oak" or "Maple"' : 'Try: "Quercus" or "Acer"');

	const onChange = (event) => {
		event.currentTarget.classList.remove("is-invalid");
		showFeedback(false);
		handleOptionsDisplay();
	};

	const onFocus = (event) => {
		event.currentTarget.select();
		handleOptionsDisplay();
	}

	return (
		<React.Fragment>
			<div id={"species-select-container"}>
				<input
					id={"species-input"}
					className={"form-control species-input"}
					placeholder={placeholder}
					ref={inputRef}
					onChange={(event) => onChange(event)}
					onFocus={(event) => onFocus(event)}
				/>
				<div
					ref={optionContainerRef}
					className={`option-container ${options.length ? "open" : "close"}`}
					onClick={(event) => onClick(event)}
				>
					{options.map((option, index) => {
						return option
					})}
				</div>
			</div>
		</React.Fragment>
	)
};
