import React, {memo} from 'react';

import PropTypes from "prop-types";

import {
	FormFeedback,
	FormText,
	Input,
	Label
} from "reactstrap";


export const FormField = memo(
	(
		{
			classList,
			id,
			label,
			type,
			readOnly = false,
			value,
			min = null,
			max = null,
			maxLength = null,
			step = null,
			onChange,
			onFocus,
			children,
			feedback,
			text,
		}
		) => {
	return (
		<div>
			<Label
				className={classList || null}
				for={id}
			>
				{label}
			</Label>
			<Input
				id={id}
				type={type}
				readOnly={readOnly}
				value={value}
				min={min}
				max={max}
				maxLength={maxLength}
				step={step}
				onChange={
					(e) => {
						if (onChange) onChange(e.target.value)
					}
				}
				onFocus={
					(e) => {
						if (onFocus) onFocus(e);
					}
				}
			>
				{children ?
					children
					:
					null
				}
			</Input>
			<FormFeedback>
				{feedback}
			</FormFeedback>
			{
				text ?
					<FormText>
						<em>{text}</em>
					</FormText>
					: null
			}
		</div>
	)
});

FormField.propTypes = {
	classList: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object
	]),
	type: PropTypes.string,
	readOnly: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	min: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	max: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	step: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	feedback: PropTypes.string,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	])
};
