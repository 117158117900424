import React, {memo, useState} from 'react';

import {Link} from "react-router-dom";

import {
	Button,
	ButtonGroup,
	Modal,
	ModalFooter
} from "reactstrap";

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {ModalContents} from "./ModalContents";


export const NavButton = memo(
	(
		{
			leftIcon,
			rightIcon,
			path,
			className,
			disabled,
			block = false,
			color = "primary",
			onClick = null,
			outline = false,
			size = null,
			title,
			text,
		}
	) => {
		let leftIconElement = leftIcon ?
			<FAI icon={leftIcon} className={title ? "ms-1" : ""}/> : null;
		let rightIconElement = rightIcon ?
			<FAI icon={rightIcon} className={title ? "ms-1" : ""}/> : null;

		return (
			<Link to={path}>
				<Button
					className={className || null}
					disabled={disabled}
					block={block}
					color={color}
					onClick={onClick}
					outline={outline}
					size={size}
					type={"button"}
					title={title}
				>
					{leftIconElement} {text} {rightIconElement}
				</Button>
			</Link>
		)
	}
);


export const ActionButton = memo(
	(
		{
			id,
			uuid,
			classNames,
			color,
			onClick,
			onContextMenu,
			outline,
			title,
			icon,
		}
	) => {

		return (
			<Button
				id={id}
				value={uuid}
				className={`${classNames || ""} square`}
				color={color}
				onClick={
					(e) => {
						if (onClick) {
							onClick(
								e.currentTarget.value
							)
						}
					}
				}
				onContextMenu={
					(e) => {
						if (onContextMenu) {
							onContextMenu(
								e.currentTarget.value
							)
						}
					}
				}
				outline={outline || false}
				type={"button"}
				title={title}
			>
				<span>
				<FAI icon={icon}/>
				</span>
			</Button>
		)
	}
);


export const ToggleButton = (
	{
		selected,
		className,
		id,
		vertical,
		settings,
		toggleFunction,
	}
) => {

	const onRadioBtnClick = (i) => {
		toggleFunction(i);
	};

	let check = <FAI icon={"check-circle"}/>;
	let circle = <FAI icon={["far", "circle"]}/>;

	return (
		<ButtonGroup
			className={className || "w-100"}
			id={id}
			vertical={vertical || false}
		>
			{settings.map(
				(item, index) => (
					<Button
						active={selected === index}
						className={"round"}
						color={item.color || "secondary"}
						key={item.text}
						onClick={
							() => onRadioBtnClick(index)
						}
						outline
						type={"button"}
					>
						{selected === index ? check : circle} {item.text} {item.icon}
					</Button>
				)
			)}
		</ButtonGroup>
	);
}


export const RadioButtonGroup = (
	{
		onRadioButtonClick,
		selected,
		id,
		title,
		vertical,
		settings,
	}
) => {

	let check = <FAI icon={"check-circle"}/>;
	let circle = <FAI icon={["far", "circle"]}/>;

	// Handles old bimodal values.
	if (selected === true) selected = 0;
	if (selected === false) selected = 1;

	return (
		<ButtonGroup
			className={"radio-button-group"}
			id={id}
			title={title}
			vertical={vertical}
		>
			{settings.map((item, index) => (
				<button
					className={`p-0 ${selected === index ? "active" : ""}`}
					key={index}
					onClick={
						() => onRadioButtonClick(index)
					}
					type={"button"}
				>
					{selected === index ? check : circle} {item.text} {item.icon}
				</button>
			))}
		</ButtonGroup>
	)
}


export const ModalButton = (
	{
		block,
		className,
		color = "info",
		confirmation,
		disabled,
		outline,
		size,
		leftIcon = null,
		rightIcon = null,
		subject,
		text,
		wrapperClass,
		confirmationColor,
		confirmationOutline,
		confirmationText,
		confirmationFunction,
		cancellationFunction,
		closeColor = "secondary",
		closeText = "Close",
		closeOutline = false,
	}
) => {
	let [modalIsOpen, setModalIsOpen] = useState(false);

	const toggleModal = () => {
		setModalIsOpen(modalIsOpen => !modalIsOpen);
	};

	const handleConfirmation = (e) => {
		confirmationFunction(e);
		toggleModal();
	};

	const handleCancellation = () => {
		if (cancellationFunction) cancellationFunction();
		toggleModal();
	};

	return (
		<div className={wrapperClass}>
			<p className={"h1"}>{modalIsOpen}</p>
			<Button
				aria-label={`${subject} information button`}
				block={block}
				className={className}
				color={color}
				disabled={disabled}
				onClick={toggleModal}
				outline={outline}
				size={size}
				type={"button"}
			>
				{leftIcon} {text} {rightIcon}
			</Button>
			<Modal
				isOpen={modalIsOpen}
				toggle={toggleModal}
			>
				<ModalContents
					confirmation={confirmation}
					subject={subject}
					function={toggleModal}
				/>
				<ModalFooter className={"justify-content-center justify-content-sm-end"}>
					{confirmation ?
						<Button
							color={confirmationColor}
							outline={confirmationOutline}
							onClick={(e) => handleConfirmation(e)}
						>
							{confirmationText}
						</Button>
						:
						null
					}
					<Button
						color={closeColor}
						outline={closeOutline}
						onClick={() => handleCancellation()}
						type={"button"}
					>
						{closeText}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}


export const InfoModalButton = memo(
	(
		{
			className = "",
			subject,
		}
	) => {
		return (
			<ModalButton
				outline={true}
				className={`square round ${className}`}
				subject={subject}
				text={<FAI icon={"question"}/>}
			/>
		)
	}
);


export let GeolocateButton = memo(
	(
		{
			addAlert,
			handleGeolocation,
		}
	) => {
		let [disabled, setDisabled] = useState(false);

		let getPosition = (e) => {
			document.body.style.cursor = "progress";
			let options = {
				enableHighAccuracy: true,
				timeout: 10000,
				maximumAge: 0
			};

			//FIXME: Add an errorCallback function.
			navigator.geolocation.getCurrentPosition(
				(position) =>
					handleGeolocation(position.coords),
				error => {
					document.body.style.cursor = "default";
					setDisabled(true);
					switch (error.code) {
						case 1:
							addAlert(
								"This browser or device denied permission to" +
								" acquire your position. In order to detect your" +
								" location please change the relevant settings.",
								"location"
							);
							break;
						case 2:
							addAlert(
								"We were unable to acquire your position due to" +
								" an internal error. Please try again.",
								"location"
							);
							break;
						case 3:
							addAlert(
								"Our search for your location timed out." +
								" Please try again.",
								"location"
							);
							break;
						default:
							addAlert(error.message, "location");
					}
				},
				options
			);
		};

		return (
			<Button
				aria-label={"Geolocate"}
				disabled={disabled}
				className={"fw-bold round"}
				id={"geolocate"}
				color={"success"}
				block={false}
				onClick={
					(e) => getPosition(e)
				}
				onTouchStart={
					(e) => getPosition(e)
				}
				title={"Find an address for your current position."}
			>
				<FAI icon={"map-marker-alt"}/>
			</Button>
		)
	}
);
