export class StorageHandler {
	constructor(params) {
		this.key = params.key;
	}

	checkForStorage() {
		try {
			localStorage.setItem("TEST", "Token");
			localStorage.removeItem("TEST");
			return true;
		} catch (e) {
			return false;
		}
	};

	putInStorage(value, key = this.key) {
		if (this.checkForStorage()) {
			localStorage.setItem(key, JSON.stringify(value));
		}
	};

	removeFromStorage() {
		if (this.checkForStorage()) {
			localStorage.removeItem(this.key);
		}
	};

	updateStorage(key, value) {
		if (this.checkForStorage()) {
			let obj = this.getFromStorage();
			if (obj) {
				obj[key] = value;
				this.putInStorage(obj);
			}
		}
	};

	getFromStorage(key = this.key) {
		let obj = {};
		if (this.checkForStorage()) obj = JSON.parse(localStorage.getItem(key));
		return obj ? obj : {};
	};
}
