import React, {memo} from "react";

import {
	Button,
	Col,
	Row,
	Label,
	FormFeedback,
	FormGroup,
	Input,
	FormText,
	Card,
} from "reactstrap";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {
	InfoModalButton,
	NavButton,
	RadioButtonGroup,
} from "./Buttons";
import {FormField} from "./FormField";
import {ToggleSwitch} from "./ToggleSwitch";

import "../../stylesheets/species-selector.scss";

import {CONDITIONS} from "../../utilities/resources";


export const AddressField = memo(
	(
		{
			address,
			longitude,
			latitude,
		}
	) => {
		return (
			<Row>
				<Col
					xs={{size: 10}}
					sm={{offset: 1}}
					md={{size: 7, offset: 2}}
					lg={{size: 5, offset: 3}}
				>
					<Label className={"required"}>
						Location
					</Label>
					<Card className={"px-3 py-1 bg-light"}>
						<div className={"d-flex flex-column"}>
							<small>{address}</small>
							<small>Lat: {latitude.toFixed(5)},
								Lng: {longitude.toFixed(5)}</small>
						</div>
					</Card>
				</Col>
				<Col
					className={"d-flex flex-column justify-content-end"}
					xs={{size: 1}}
				>
					<NavButton
						className={"square round"}
						color={"success"}
						path={"/location"}
						text={""}
						title={"Edit location"}
						leftIcon={"pen"}
					/>
					<InfoModalButton
						subject={"address"}
					/>
				</Col>
			</Row>
		)
	}
);


export const ConditionField = memo(
	(
		{
			condition,
			updateProject,
		}
	) => {
		let updateCondition = (target) => {
			target.classList.remove("is_invalid");
			updateProject(
				{tree: {condition: target.value}}
			);
		};
		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 6, offset: 1}}
					md={{size: 4, offset: 2}}
					lg={{size: 3, offset: 3}}
				>
					<Label
						className={"required"}
						for={"condition"}
					>
						Tree Condition
					</Label>
					<Input
						id={"condition"}
						type={"select"}
						value={condition}
						onChange={
							(e) => updateCondition(e.currentTarget)
						}
					>
						<option
							disabled
							value={"-1"}
						>
							Select a condition
						</option>
						{CONDITIONS.map((c, i) => {
							return (
								<option
									key={c}
									value={i}
								>
									{c}
								</option>
							)
						})}
					</Input>
					<FormFeedback>
						Please select a condition.
					</FormFeedback>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 4}}
					md={{offset: 3}}
					lg={{offset: 2}}
				>
					<InfoModalButton
						subject={"condition"}
					/>
				</Col>
			</Row>
		)
	}
);


export const DiameterField = memo(
	(
		{
			measurementType,
			diameter,
			unitType,
			updateProject
		}
	) => {
		let unit = unitType ? "in." : "cm.";
		let [max, min, metric, pi, step] = [
			999,
			1,
			2.54,
			// Using 3.1 to avoid input validation confusion caused by Math.PI.
			3.1,
			0.1
		];

		diameter = parseFloat(diameter);
		if (measurementType === false) diameter = diameter * pi;
		if (unitType === false) diameter = diameter * metric;
		diameter = diameter.toFixed(1);

		diameter = !diameter || isNaN(diameter) ? "" : parseFloat(diameter);

		if (!unitType && measurementType) {
			[max, min] = [(max * metric), metric];
		} else if (unitType && !measurementType) {
			[max, min] = [(pi * max), pi];
		} else if (!unitType && !measurementType) {
			[max, min] = [(pi * (max * metric)), (pi * metric)];
		}
		min = min.toFixed(1);
		max = max.toFixed(1);

		// Parse into floats to satisfy NumericInput prop requirements.
		min = parseFloat(min);
		max = parseFloat(max);

		let updateMeasurementType = () => {
			updateProject(
				{tree: {measurementType: !measurementType}}
			)
		};

		let updateDiameter = (target) => {
			target.classList.remove("is-invalid");
			let value = target.value;

			if (
				value &&
				(
					value > max ||
					value < min
				)
			) {
				target.classList.add("is-invalid");
			}

			if (value && unitType === false) value = value / metric;
			if (value && measurementType === false) value = value / pi;

			updateProject(
				{tree: {diameter: value}}
			);
		};

		return (
			<React.Fragment>
				<Row className={"mt-2"}>
					<Col
						xs={{size: 10}}
						sm={{size: 6, offset: 1}}
						md={{size: 4, offset: 2}}
						lg={{size: 3, offset: 3}}
					>
						<Label
							className={"text-nowrap required"}
							for={"diameter"}
						>
							{`Trunk Size (${unit})`}
						</Label>
						<input
							id={"diameter"}
							className={"form-control"}
							min={min}
							max={max}
							step={step}
							type={"number"}
							value={diameter}
							onFocus={
								(e) => e.target.select()
							}
							onChange={
								(e) => updateDiameter(e.currentTarget)
							}
						/>
						<FormFeedback
							className={"text-nowrap"}
						>
							{`Please enter a number between ${min} and ${max}`}
						</FormFeedback>
					</Col>
					<Col
						className={"d-flex align-items-end"}
						xs={{size: 1}}
						sm={{offset: 4}}
						md={{offset: 3}}
						lg={{offset: 2}}
					>
						<InfoModalButton
							subject={"diameter"}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{size: 8}}
						sm={{size: 6, offset: 1}}
						md={{size: 4, offset: 2}}
						lg={{size: 3, offset: 3}}
						xl={{size: 3, offset: 3}}
					>
						<ToggleSwitch
							className={"mt-1"}
							updateField={updateMeasurementType}
							id={"measurementType"}
							value={measurementType}
							text={{left: "Diameter", right: "Circumference"}}
							title={"Toggle between measuring trunk diameter or circumference."}
						/>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
);


export const ExposureField = memo(
	(
		{
			exposure,
			updateProject,
		}
	) => {
		let exposures = [
			{text: "Full"},
			{text: "Partial"},
			{text: "Shade"},
		];

		let updateExposure = (value) => {
			updateProject(
				{tree: {exposure: value}}
			)
		};

		return (
			<Row className={"mt-2"}>
				<Col
					xs={{size: 10}}
					sm={{size: 8, offset: 1}}
					md={{size: 6, offset: 2}}
					lg={{size: 4, offset: 3}}
				>
					<Label
						className={"text-nowrap required"}
						for={"exposure"}
					>
						Sun Exposure
					</Label>
					<RadioButtonGroup
						onRadioButtonClick={updateExposure}
						id={"proximity"}
						selected={exposure}
						settings={exposures}
						title={"Estimate how much sunlight your tree's crown receives."}
					/>
				</Col>
				<Col
					className={"d-flex align-items-end"}
					xs={{size: 1}}
					sm={{offset: 2}}
					md={{offset: 1}}
				>
					<InfoModalButton
						subject={"exposure"}
					/>
				</Col>
			</Row>
		)
	}
);


export const SaveButton = memo(
	(
		{
			text,
			validateInputs,
		}
	) => {
		let saveButtonValidateInputs = (e) => {
			validateInputs(e, "/dashboard");
		};

		let rightIcon = <FAI icon={"arrow-right"} className={"ms-1"}/>;

		return (
			<Row className={"my-3"}>
				<Col
					className={"text-center"}
				>
					<Button
						className={"round"}
						color={"primary"}
						onClick={saveButtonValidateInputs}
						title={"Save this tree and continue."}
					>
						{text} {rightIcon}
					</Button>
				</Col>
			</Row>
		)
	}
);


export const Note = memo(
	(
		{
			note,
			updateProject,
		}
	) => {
		let updateNote = (value) => {
			updateProject(
				{project: {note: value}}
			);
		};

		return (
			<Row>
				<Col xs={{size: 10}}>
					<FormField
						id={"notes"}
						label={"Add a note or label for this tree"}
						maxLength={"25"}
						type={"text"}
						value={note || ""}
						onChange={updateNote}
					/>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
				>
					<InfoModalButton
						subject={"name"}
					/>
				</Col>
			</Row>
		)
	}
);

export const TypeField = memo(
	(
		{
			type,
			feedback,
			text,
			updateProject
		}
	) => {
		let types = [
			"None",
			"Existing",
			"New Planting",
			"Heritage",
			"Memorial",
			"Specimen",
			"Removal",
			"Planting Site",
			"Testing/Imaginary Tree",
		];

		return (
			<Row>
				<Col xs={{size: 10}}>
					<FormGroup>
						<Label
							className={"text-nowrap"}
							for={"treeType"}
						>
							Log the type of tree or planting site
						</Label>
						<Input
							id={"treeType"}
							type={"select"}
							value={type}
							onChange={
								(e) => updateProject(
									{tree: {type: e.currentTarget.value}}
								)
							}
						>
							<option
								disabled
								value={""}
							>
								Select a Type
							</option>
							{types.map((c, i) => {
								return (
									<option
										key={c}
										value={c}
									>
										{c}
									</option>
								)
							})}
						</Input>
						<FormFeedback>
							{feedback}
						</FormFeedback>
						{
							text ?
								<FormText>
									<em>{text}</em>
								</FormText>
								: null
						}
					</FormGroup>
				</Col>
				<Col
					className={"d-flex justify-content-end align-items-end"}
					xs={{size: 2}}
				>
					<FormGroup>
						<InfoModalButton
							subject={"type"}
						/>
					</FormGroup>
				</Col>
			</Row>
		)
	}
);
