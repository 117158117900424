import Papa from "papaparse";


export const waitForPapa = async (file) => {
	Papa.parsePromise = (file) => {
		return new Promise((complete, error) => {
			Papa.parse(file, {
				header: true,
				dynamicTyping: true,
				complete,
				error,
			});
		});
	};

	let results = null;
	await Papa.parsePromise(file).then((parsed) => {
		results = parsed;
	});
	return results;
};


export const csvImport = async (file) => {

	return await waitForPapa(file);
};
