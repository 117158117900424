import { camel2dash } from "itr/utility"
import { dash2camel } from "itr/utility"


class Base {
	static fromJSON = (str) => {
		let state = JSON.parse(str)
		let publicFields = {}
		for (const prop in state) {
			publicFields[dash2camel(prop)] = state[prop]
		}
		return new Location(publicFields)
	}

	toJSON = (key) => {
		let state = {}
		for (const prop in this) {
			state[camel2dash(prop)] = this[prop]
		}
		return state
	}
}


export { Base }
export default Base

