import React from "react";

import {
	Container,
	Row,
	Col,
} from "reactstrap";

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {
	NavButton,
	ModalButton,
} from "../parts/Buttons";
import {AppVersion} from "../parts/GeneralPageElements";

import "../../stylesheets/landing.scss";

import logo from "../../img/iTree_primary_logo_restricted_MEDIUM.png";
import tree from "../../img/Picture1.jpg";


export const Landing = (
	{
		projectCount,
		headline,
		intro,
		description,
		app,
		locationWarning,
		eula,
		version,
		history,
		initializeProject,
		reset,
	}
) => {
	const resetAndGoToLocationForm = (e) => {
		reset(e);
		history.push("/location");
	};

	const startNewProjectAndProceedToLocationForm = () => {
		initializeProject(
			{
				callback: history.push("/location")
			}
		);
	};
	let leftIcon = <FAI icon={"power-off"} className={"me-1"}/>;

	return (
		<Container id={"landing"} fluid>
			<Row className={"text-center mt-1"}>
				<Col>
					<p className={"h3"}>
						{headline}
					</p>
				</Col>
			</Row>
			<Row className={"text-center"}>
				<Col
					sm={{size: 8, offset: 2}}
					lg={{size: 6, offset: 3}}
					xl={{size: 4, offset: 4}}
				>
					<p className={"lead"}>
						{intro}
					</p>
					<p className={"lead"}>
						It's quick and easy!
					</p>
				</Col>
			</Row>
			<Row>
				<Col className={"text-center"}>
					{projectCount >= 1 ?
						<ModalButton
							className={"button-width"}
							color={"info"}
							leftIcon={leftIcon}
							size={"lg"}
							text={"Get Started"}
							title={"Get started"}
							confirmation={true}
							confirmationColor={"danger"}
							confirmationOutline={true}
							confirmationText={"Delete all previous work"}
							confirmationFunction={resetAndGoToLocationForm}
							closeText={"Proceed with previous work"}
							cancellationFunction={startNewProjectAndProceedToLocationForm}
							subject={"reset"}
						/>
						:
						<NavButton
							className={"button-width"}
							color={"info"}
							leftIcon={"power-off"}
							path={"/location"}
							size={"lg"}
							text={"Get Started"}
							title={"Get started"}
						/>
					}
				</Col>
			</Row>
			<Row className={"my-4 text-center"}>
				<Col>
					<img
						className={"ms-n4"}
						alt={"i-Tree Logo"}
						height={"125"}
						src={logo}
					/>
				</Col>
			</Row>
			<Row className={"text-center"}>
				<Col>
					<p>{description}</p>
					<p>{eula}</p>
					{/*FIXME: Move this to static_props*/}
					<p>
						<small>
							<em>
								<a
									target={"_blank"}
									href={"https://www.itreetools.org"}
								>
									https://www.itreetools.org
								</a>
							</em>
						</small>
					</p>
					<div className={"position-relative"}>
						<p>{locationWarning}</p>
						<p>
							<small className={"fst-italic"}>
								{app} may not work with<br/>
								<span className={"fw-bold"}>
									private browsing
								</span>
								{" "}or{" "}
								<span className={"fw-bold"}>
									incognito mode
								</span>
								.
							</small>
						</p>
						<AppVersion version={version}/>
					</div>
					<div className={"d-flex justify-content-center align-items-start"}>
						<img
							className={"tree"}
							src={tree}
							alt={"Tree"}
						/>
					</div>
				</Col>
			</Row>
		</Container>
	)
}