import React from "react";

export function Basswood() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Basswood spp</desc>
			<path
				fill="#006D41"
				d="M34.5 106.5h-2c-3.612 1.011-7.279 1.178-11 .5C11.741 96.714 8.241 84.548 11 70.5c2.596-4.342 4.596-9.008 6-14A921.775 921.775 0 0039.5 31a178.121 178.121 0 0015-10 111.347 111.347 0 0118-5.5l3 2c4.31-.878 8.642-1.712 13-2.5 3.563-1.814 7.23-3.48 11-5a134.017 134.017 0 0110-9.5l1 1c-4.862 8.77-7.529 18.27-8 28.5 2.645 12.782 3.145 25.615 1.5 38.5a167.833 167.833 0 00-13 27c-9.199 10.681-20.699 17.348-34.5 20a29.201 29.201 0 01-7-2.5 147.037 147.037 0 01-10-10.5c-1.686 1.357-3.352 2.69-5 4z"
			></path>
			<path
				fill="white"
				d="M39.5 83.5c1.846-.278 3.18.389 4 2-2.333 1.442-3.666.775-4-2z"
			></path>
			<path
				fill="#006D41"
				d="M32.5 106.5h2a131.252 131.252 0 01-8 13h-3a85.546 85.546 0 009-13z"
			></path>
		</svg>
	);
}
