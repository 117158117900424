import React from "react";

import {Alert} from "reactstrap";


export const Alerts = (props) => {

	if (props.alerts && props.alerts.length >= 1) {
		return (
			props.alerts.map((alert, index) => {
				return (
					<Alert
						key={`${alert.type}${index}`}
						color={alert.type === "error" ? "danger" : "warning"}
					>
						{alert.content}
					</Alert>
				)
			})
		)
	} else {
		return null;
	}
};
