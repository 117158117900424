import React from "react";

export function Locust() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Locust spp</desc>
			<path
				fill="#006D41"
				d="M-.5 116.5v-3a265.37 265.37 0 0113.5-14c.483-.948.65-1.948.5-3-4.193.138-6.527-1.862-7-6 2.395.864 4.729.864 7 0 .861-2.41 2.194-4.41 4-6 3.127.376 6.127.376 9 0-2.738-.737-4.405-2.404-5-5a60.94 60.94 0 01.5-11 160.113 160.113 0 0013.5-20h5v-5c.175-4.337 2.342-7.004 6.5-8 4.418 1.42 7.085 4.42 8 9a59.566 59.566 0 014-10c2.02-3.212 4.853-4.878 8.5-5 .34-3.016 1.507-5.683 3.5-8 1.263-2.107 3.097-3.107 5.5-3a27.624 27.624 0 012.5-10c2.153-1.944 4.653-2.444 7.5-1.5 4.42 5.366 5.087 11.2 2 17.5 1.664.723 3.33.39 5-1 .83-5.302 1.33-10.636 1.5-16A20.473 20.473 0 0097.5 4c5.009-1.333 7.342.667 7 6a41.49 41.49 0 00-1.5 7.5c4.061-5.723 8.728-6.39 14-2 .311 4.69-1.856 7.69-6.5 9a55.482 55.482 0 015.5 7c.667 1.333.667 2.667 0 4-4.245 1.076-8.078 2.91-11.5 5.5-8.757 2.585-15.424.085-20-7.5a129.517 129.517 0 00-12 9.5 61.817 61.817 0 0113 0c4.079 2.075 7.579 4.908 10.5 8.5 1.04 3.136.207 5.636-2.5 7.5-11.81 1.657-19.143-3.176-22-14.5a24.922 24.922 0 01-5 6.5c-3.732.365-6.732 2.032-9 5 7.765-2.688 15.098-1.688 22 3 3.908 3.791 3.908 7.458 0 11l-9 2.5c2.071 2.43 2.404 5.097 1 8a51.2 51.2 0 01-11 3c3.312 3.344 3.646 6.844 1 10.5a37.098 37.098 0 01-12 1.5c.763 2.641.93 5.308.5 8-4.487 2.636-8.987 2.469-13.5-.5-4.506-3.495-7.672-7.995-9.5-13.5-1.73 2.117-3.898 3.617-6.5 4.5a17.834 17.834 0 01-4.5 5.5c-.667.667-.667 1.333 0 2l2.5-2.5c8.05-.895 14.55 1.939 19.5 8.5.777 2.929.61 5.763-.5 8.5-4 .667-8 .667-12 0-7.058-1.874-10.391-6.374-10-13.5a4.934 4.934 0 00-3 .5 537.827 537.827 0 00-13 13.5z"
			></path>
			<path
				fill="white"
				d="M80.5 29.5c3.633-.057 3.8.943.5 3a4.932 4.932 0 01-.5-3z"
			></path>
			<path
				fill="white"
				d="M94.5 25.5c1.548.321 2.215 1.321 2 3a323.215 323.215 0 01-9 1.5 74.608 74.608 0 007-4.5z"
			></path>
			<path
				fill="white"
				d="M40.5 43.5v5h-5c1.068-.934 2.401-1.268 4-1-.268-1.599.066-2.932 1-4z"
			></path>
			<path
				fill="white"
				d="M55.5 48.5c4.211 3.305 3.711 5.972-1.5 8 .16-2.659.66-5.325 1.5-8z"
			></path>
			<path
				fill="white"
				d="M46.5 65.5c1.527.073 2.527-.594 3-2 2.03-2.682 4.697-4.349 8-5a51.639 51.639 0 001 7c-3.883-.39-7.55.276-11 2-.797-.457-1.13-1.124-1-2z"
			></path>
			<path
				fill="white"
				d="M49.5 63.5c-.473 1.406-1.473 2.073-3 2-1.276-.886-1.276-1.886 0-3 .917.627 1.917.96 3 1z"
			></path>
			<path
				fill="white"
				d="M44.5 68.5c1.644 2.15 2.977 4.65 4 7.5-4.435-.121-8.435 1.046-12 3.5-1.021-.502-1.188-1.168-.5-2 3.038-2.87 5.871-5.87 8.5-9z"
			></path>
			<path
				fill="white"
				d="M33.5 80.5c1.808 1.418 2.475 3.418 2 6-2.79.45-5.456.45-8 0 1.715-2.382 3.715-4.382 6-6z"
			></path>
			<path
				fill="white"
				d="M21.5 79.5c.595 2.596 2.262 4.263 5 5-2.873.376-5.873.376-9 0 1.068-.934 2.401-1.268 4-1v-4z"
			></path>
		</svg>
	);
}
