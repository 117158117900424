import React from "react";

export function Sweetgum() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Sweetgum spp</desc>
			<path
				fill="#006D41"
				d="M68.5 119.5h-4c-4.672-9.337-7.505-19.337-8.5-30A141.366 141.366 0 0039.5 101c-6.228 2.682-12.729 3.849-19.5 3.5-6.502-.367-13.002-.7-19.5-1 .591-3.114 2.258-5.614 5-7.5a164.678 164.678 0 0020-14C17.218 73.18 12.384 62.68 11 50.5a67.226 67.226 0 01.5-20 551.939 551.939 0 0029.5 25c-.939-6.444-.272-12.777 2-19A808.659 808.659 0 0058.5.5h3a155.637 155.637 0 0011.5 39 133.6 133.6 0 012 18c12.424-9.798 26.258-14.965 41.5-15.5-2.468 2.142-4.635 4.642-6.5 7.5a456.684 456.684 0 01-11 22L91.5 79a373.357 373.357 0 0018 18.5c1.893 2.452 3.226 5.119 4 8a191.362 191.362 0 01-36-5.5c-4.879-1.548-8.545-4.548-11-9-2.177-1.202-4.51-1.702-7-1.5-.169 10.342 3.164 19.342 10 27a10.022 10.022 0 01-1 3z"
				opacity="0.999"
			></path>
		</svg>
	);
}
