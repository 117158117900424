export const Abbreviations = () => {
	return (
		<span
			className={"text-start"}
			key={"abbreviations"}
		>
			<strong className={"mb-0"}>Abbreviations:</strong>
			<ul className={"list-unstyled"}>
				<li>CO<sub>2</sub> = Carbon dioxide</li>
				<li>PM<sub>2.5</sub> = Particulate matter 2.5 microns or less</li>
				<li>lbs = Pounds</li>
				<li>kg = Kilograms</li>
				<li>gal = Gallons</li>
				<li>L = Liters</li>
				<li>oz = Ounces</li>
				<li>g = Grams</li>
				<li>kWh = Kilowatt hour</li>
				<li>MMBtu = Millions of British thermal units</li>
			</ul>
		</span>
	)
}
