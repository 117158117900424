import React from "react";

import {
	Button,
	Input,
	InputGroup,
	Form,
	FormFeedback,
} from "reactstrap";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {GeolocateButton} from "./Buttons";

const DEFAULT_FEEDBACK = "A valid address is needed in order to proceed.";
const THIRTEEN = 13;
const ENTER = "enter";

export const AddressInput = (
	{
		location,
		warning,
		handleAddressSearch,
		handleGeolocation,
		onChange,
	}
) => {
	const handleSearch = (e) => {
		let input = document.querySelector(".address-input");
		handleAddressSearch(input.value);
	};

	return (
		<Form>
			<InputGroup>
				<GeolocateButton
					handleGeolocation={handleGeolocation}
				/>
				<label className={"sr-only"} htmlFor={"address-input"}>
					Address
				</label>
				<Input
					id={"address-input"}
					className={`address-input${warning ? " is-invalid" : ""}`}
					value={location.address.entered}
					onChange={
						(e) => onChange(e)
					}
					onFocus={
						(e) => e.target.select()
					}
					onKeyDown={
						(e) => {
							if (
								e.keyCode === THIRTEEN ||
								e.key.toLowerCase() === ENTER
							) {
								e.preventDefault();
								handleSearch(e.currentTarget)
							}
						}
					}
				/>
				<Button
					aria-label={"Search"}
					className={"round"}
					color={"primary"}
					onClick={
						(e) => {
							handleSearch(e)
						}
					}
				>
							<span className={"d-block d-sm-none"}>
								<FAI icon={"search"}/>
							</span>
					<span className={"d-none d-sm-inline"}>
								Search
							</span>
				</Button>
			</InputGroup>
			<FormFeedback className={"text-center"}>
				{DEFAULT_FEEDBACK}
			</FormFeedback>
		</Form>
	)
}
