import React, {Component} from "react";

import {ModalContents} from "./ModalContents";

import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalFooter
} from "reactstrap";

export class ResetModal extends Component {

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.props.toggleModal}
			>
				<ModalContents
					subject={"reset"}
					function={this.props.toggleModal}
				/>
				<ModalFooter>
					<Button
						color={"danger"}
						outline
						onClick={
							(e) => this.props.reset(e)
						}
					>
						{this.props.confirmationText}
					</Button>
					<Button
						color={"secondary"}
						outline={false}
						onClick={this.props.toggleModal}
						type={"button"}
					>
						{this.props.cancelText}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

ResetModal.defaultProps = {
	confirmationText: "Yes, start over",
	cancelText: "Cancel",
};


export class ImportModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			file: null,
			label: "Choose a file",
		}
	}

	onChange = (e) => {
		let file = e.currentTarget.files[0];

		this.setState({
				file: file,
				label: file.name,
			},
			() => {
				if (this.props.onChange) this.props.onChange(file);
			}
		)
	};

	onClick = () => {
		if (this.state.file) {
			let {file} = {...this.state};
			this.props.importProjects(file);
			this.props.toggleModal();
		}
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.props.toggleModal}
			>
				<ModalBody
					className={"mb-4"}
				>
					<Input
						type={"file"}
						id={"customFileInput"}
						name={"customFileInput"}
						label={this.state.label}
						onChange={
							(e) => this.onChange(e)
						}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color={"success"}
						onClick={
							() => this.onClick()
						}
					>
						Import File
					</Button>
					<Button
						color={"danger"}
						outline
						onClick={this.props.toggleModal}
						type={"button"}
					>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}


export class ConfirmationModal extends Component {
	render() {
		return (
			<Modal
				isOpen={this.state.modal}
				toggle={this.toggleModal}
			>
				<ModalContents
					subject={this.props.subject}
					function={this.toggleModal}
				/>
				<ModalFooter>
					{this.props.confirmation ?
						<Button
							color={this.props.confirmationColor}
							outline={this.props.confirmationOutline}
							onClick={(e) => this.handleConfirmation(e)}
						>
							{this.props.confirmationText}
						</Button>
						:
						null
					}
					<Button
						color={this.props.closeColor || "secondary"}
						outline={this.props.closeOutline || false}
						onClick={this.toggleModal}
						type={"button"}
					>
						{this.props.closeText || "Close"}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export const ProcessingModal = (
	{
		isOpen,
		text = "Estimating Tree Benefits...",
		toggleModal
	}
) => {
	return (
		<Modal
			isOpen={isOpen}
			toggle={toggleModal}
		>
			<p className={"text-center h3 p-3"}>
				{text}
			</p>
		</Modal>
	)
};

export class ErrorModal extends Component {
	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				toggle={this.toggleModal}
			>
				<ModalBody>
					<p className={"h3"}>
						An error has occurred:
					</p>
					<p>{this.props.message}</p>
					<p>
						Clicking "Return to previous page" will return you to
						the previous page. Please try again or try a different
						location.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color={"secondary"}
						outline
						onClick={
							() => {
								this.props.history.push("/location")
							}
						}
						type={"button"}
					>
						Return to previous page
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export class ToggleLayerModal extends Component {
	render() {
		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				toggle={this.props.toggleModal}
			>
				<ModalBody>
					<p>
						We’re sorry, but we cannot find the location
						you are searching for. Please check the
						spelling or try a nearby place.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color={"success"}
						onClick={() => this.props.toggleModal()}
					>
						OK
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}
