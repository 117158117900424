import React, {useState} from 'react';
import {NavLink as RRNavLink} from "react-router-dom";
import {
	Collapse,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem,
	NavLink,
} from "reactstrap";
import {Brand} from "./navbar/Shared";
import "../../stylesheets/navbar.scss";


export const SharedNavbar = (
	{
		navbarClasses,
		app,
		tagline,
		appNameClasses,
		taglineClasses,
		afterBrand,
		menus,
		afterCollapse,
	}
) => {
	let [navbarIsOpen, setNavbarIsOpen] = useState(false);

	const toggle = () => setNavbarIsOpen(navbarIsOpen => !navbarIsOpen);

	return (
		<Navbar
			color="light"
			light expand="sm"
			className={navbarClasses}
		>
			<Brand
				app={app}
				tagline={tagline}
				appNameClasses={appNameClasses}
				taglineClasses={taglineClasses}
			/>
			{afterBrand.map((item, index) => {
				return item;
			})}
			<NavbarToggler onClick={toggle}/>
			<Collapse isOpen={navbarIsOpen} navbar>
				<Nav navbar>
					<NavItem>
						<NavLink tag={RRNavLink} exact to={"/"}>
							Home
						</NavLink>
					</NavItem>
					{menus.map((menu, index) => {
						return menu;
					})}
				</Nav>
			</Collapse>
			{afterCollapse.map((item, index) => {
				return item;
			})}
		</Navbar>
	)
}
