import Base from "itr/base"


class Hydrology extends Base {
	interception = 0
	runoffAvoided = 0
	runoffAvoidedWorth = 0
	evaporation = 0
	transpiration = 0
	potentialEvaporation = 0
	potentialEvapotranspiration = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}

Hydrology.__id = "hydrology"


export { Hydrology }
export default Hydrology

