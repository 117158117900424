import {v4 as createUUID} from 'uuid';


export class Tree {
	constructor(
		{
			uuid = createUUID(),
			common = "",
			condition = "0",
			diameter = 1,
			exposure = 0,
			measurementType = true,
			namingType = true,
			scientific = "",
			species = "",
			type = "",
			trillionTrees = null,
		} = {}
	) {
		this.uuid = uuid;
		this.common = common;
		this.condition = condition;
		this.diameter = diameter;
		this.exposure = exposure;
		this.measurementType = measurementType;
		this.namingType = namingType;
		this.scientific = scientific;
		this.species = species;
		this.type = type;
		this.trillionTrees = trillionTrees;
	}

	__fields = () => {
		return {
			uuid: this.uuid,
			common: this.common,
			condition: this.condition,
			diameter: this.diameter,
			exposure: this.exposure,
			measurementType: this.measurementType,
			namingType: this.namingType,
			scientific: this.scientific,
			species: this.species,
			type: this.type,
			trillionTrees: this.trillionTrees,
		};
	};

	// FIXME: Make use of the resources/constants/condition names here to avoid repetition.
	getVerboseCondition = () => {
		let condition = "";

		switch (this.condition.toString()) {
			case "0":
				condition = "excellent";
				break;
			case "1":
				condition = "good";
				break;
			case "2":
				condition = "fair";
				break;
			case "3":
				condition = "poor";
				break;
			case "4":
				condition = "critical";
				break;
			case "5":
				condition = "dying";
				break;
			case "6":
				condition = "dead";
				break;
			default:
				condition = "excellent";
		}

		return condition;
	};

	getExposureValue = () => {
		let exposure = "";

		switch (this.exposure.toString()) {
			case "0":
				exposure = "5";
				break;
			case "1":
				exposure = "3";
				break;
			case "2":
				exposure = "0";
				break;
			default:
				exposure = "5";
		}

		return exposure;
	};

	__url = () => {
		let fields = this.__fields();
		let query = new URLSearchParams();

		function cycleFields(fields) {
			for (let f in fields) {
				if (fields[f] instanceof Object) {
					cycleFields(fields[f])
				} else {
					query.append(f, fields[f]);
				}
			}
		}

		cycleFields(fields);

		return query;
	};

	validate = () => {
		let u = "undefined";
		let response = {
			proceed: true,
			errors: {
				common: false,
				condition: false,
				diameter: false,
				exposure: false,
				scientific: false,
				species: false,
			}
		};

		if (
			!this.common ||
			typeof this.common === u ||
			this.common === ""
		) {
			response.proceed = false;
			response.errors.common = true;
		} else {
			delete response.errors.common;
		}

		if (
			!this.condition ||
			typeof this.condition === u ||
			this.condition === "" ||
			this.condition < 0 ||
			isNaN(this.condition)
		) {
			response.proceed = false;
			response.errors.condition = true;
		} else {
			delete response.errors.condition;
		}

		if (
			!this.diameter ||
			typeof this.diameter === u ||
			this.diameter === "" ||
			this.diameter < 1 ||
			this.diameter > 999 ||
			isNaN(this.diameter)
		) {
			response.proceed = false;
			response.errors.diameter = true;
		} else {
			delete response.errors.diameter;
		}

		if (
			typeof this.exposure === u ||
			this.exposure === "" ||
			this.exposure < 0 ||
			isNaN(this.exposure)
		) {
			response.proceed = false;
			response.errors.exposure = true;
		} else {
			delete response.errors.exposure;
		}

		if (
			!this.scientific ||
			typeof this.scientific === u
			|| this.scientific === ""
		) {
			response.proceed = false;
			response.errors.scientific = true;
		} else {
			delete response.errors.scientific;
		}

		if (
			!this.species ||
			typeof this.species === u ||
			this.species === ""
		) {
			response.proceed = false;
			response.errors.species = true;
		} else {
			delete response.errors.species
		}

		return response;
	}
}
