import React, {Component} from "react";
import {
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	UncontrolledAccordion,
	Row,
	Col,
} from "reactstrap";
import * as Tree from "./TreeFields";


export class OptionalFieldsAccordion extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Row>
				<Col
					xs={{size: 12}}
					sm={{size: 10, offset: 1}}
					md={{size: 8, offset: 2}}
					lg={{size: 6, offset: 3}}
				>
					<UncontrolledAccordion className={"mb-5"} defaultOpen={"0"}>
						<AccordionItem>
							<AccordionHeader targetId={"1"}>
								Optional Fields
							</AccordionHeader>
							<AccordionBody accordionId={"1"}>
								<Tree.Note
									note={this.props.note}
									updateProject={this.props.updateProject}
								/>
								<Tree.TypeField
									type={this.props.type}
									updateProject={this.props.updateProject}
								/>
								{/*<Tree.GroupField*/}
								{/*	group={this.props.group}*/}
								{/*	updateProject={this.props.updateProject}*/}
								{/*/>*/}
								{/*<Tree.TrillionTreesField*/}
								{/*	trillionTrees={this.props.trillionTrees}*/}
								{/*	updateProject={this.props.updateProject}*/}
								{/*/>*/}
								{/*<Tree.OptIn*/}
								{/*	optIn={this.props.optIn}*/}
								{/*	updateProject={this.props.updateProject}*/}
								{/*	validateInputs={this.props.validateInputs}*/}
								{/*/>*/}
								<Tree.SaveButton
									text={this.props.text}
									disabled={this.props.processing}
									validateInputs={this.props.validateInputs}
								/>
							</AccordionBody>
						</AccordionItem>
					</UncontrolledAccordion>
				</Col>
			</Row>
		)
	}
}