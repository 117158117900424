import React from "react";

export function Maple() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Maple spp</desc>
			<path
				fill="#006D41"
				d="M119.5 47.5v3c-5.095 2.787-6.429 6.62-4 11.5a10.122 10.122 0 004 1.5v2c-6.703 2.433-12.869 5.766-18.5 10-.456 1.244.044 2.077 1.5 2.5 3.317.498 6.65.665 10 .5.22 4.426 2.554 6.426 7 6v4c-3.901.415-6.234 2.415-7 6-2.82-.252-5.486.248-8 1.5-.687 2.232-1.021 4.398-1 6.5-6-1.333-12-1.333-18 0-.904 4.383.096 8.383 3 12-1.623.976-2.957.476-4-1.5a406.783 406.783 0 00-15-5.5 41.758 41.758 0 00-10-7c-1.535-.854-2.868-.854-4 0a60.313 60.313 0 00-14 8.5c-4.456.321-8.622 1.487-12.5 3.5l-1.5-.5c3.625-3.367 5.625-7.534 6-12.5h-3l-16-2 2-4a16.314 16.314 0 00-4-2l-6-2a2.427 2.427 0 00-.5-2 69.135 69.135 0 01-6.5-5v-4c2.87-.578 4.537-2.245 5-5 4.512.351 8.845-.315 13-2a61.15 61.15 0 00-16-14c3.17-.483 5.836-1.816 8-4a47.524 47.524 0 00-5-10l2-2c.624-.417.957-1.084 1-2-.637-3.882-1.47-7.549-2.5-11 3.667 3.727 8 5.06 13 4 4.133 3.779 8.133 3.779 12 0a52.432 52.432 0 0011 16c1.622-6.496 1.956-13.163 1-20a66.678 66.678 0 01-2.5-6c2.833-.081 5.833-.248 9-.5a19.832 19.832 0 013-5.5c.934-1.068 1.268-2.401 1-4 1.291.237 2.291-.096 3-1 2.124-1.56 3.791-3.56 5-6a87.589 87.589 0 017 8.5c1.973.495 3.973.662 6 .5a72.444 72.444 0 00.5 12c3 1.333 6 1.333 9 0l.5 1.5c-3.289 6.016-4.955 12.516-5 19.5-.423 2.682.577 3.848 3 3.5a148.275 148.275 0 0018-17.5c3.098 4.354 6.932 4.687 11.5 1a7.248 7.248 0 002.5 2c1.698-1.18 3.365-1.18 5 0-.328 2.672-.661 5.339-1 8-.047 1.538.62 2.538 2 3z"
			></path>
			<path
				fill="#006D41"
				d="M55.5 11.5c-.709.904-1.709 1.237-3 1 .268 1.599-.066 2.932-1 4v-5h4z"
			></path>
			<path
				fill="#006D41"
				d="M6.5 41.5l-2 2c-.543-.06-.876-.393-1-1 .825-.886 1.825-1.219 3-1z"
			></path>
			<path
				fill="#006D41"
				d="M6.5 89.5l6 2a13.117 13.117 0 01-7-1c.124-.607.457-.94 1-1z"
			></path>
			<path
				fill="#006D41"
				d="M14.5 97.5l16 2a73.123 73.123 0 01-17-1c.124-.607.457-.94 1-1z"
			></path>
			<path
				fill="#006D41"
				d="M55.5 100.5c1.132-.854 2.465-.854 4 0a163.801 163.801 0 00-1.5 15 3.647 3.647 0 01-1.5 1 142.283 142.283 0 00-1-16z"
			></path>
		</svg>
	);
}
