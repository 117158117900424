import React from "react";
import "../../stylesheets/toggle-switch.scss";


export const ToggleSwitch = (
	{
		id,
		className,
		text,
		title,
		value,
		updateField,
	}
) => {
	const onBtnClick = () => {
		updateField(id);
	};

	const onFocus = (e) => {
		let parent = e.currentTarget.parentNode.parentNode;
		parent.style["box-shadow"] = "0 0 3pt 2pt #006D41";
	};

	const onBlur = (e) => {
		let parent = e.currentTarget.parentNode.parentNode;
		parent.style["box-shadow"] = "0 0 0 0";
	};

	let classes = "toggle-button-container";
	classes = classes.concat(className ? ` ${className}` : "");
	classes = classes.concat(value ? "" : " toggled");

	return (
		<div
			className={classes}
			title={title}
		>
			<label>
				<input
					checked={value}
					onFocus={
						(e) => onFocus(e)
					}
					onBlur={
						(e) => onBlur(e)
					}
					onChange={onBtnClick}
					onKeyDown={
						(e) => {
							if (e.keyCode === 13) onBtnClick()
						}
					}
					className="switch"
					type="checkbox"
				/>
				<div className={"toggle-button-text left"}>
					{text.left}
				</div>
				<div className={"toggle-button-nob"}/>
				<div className={"toggle-button-text right"}>
					{text.right}
				</div>
			</label>
		</div>
	)
}
