import React, {useEffect, useState} from "react";

import {
	Row,
	Col,
	Table,
	Collapse,
} from "reactstrap";

import {Top} from "./Top";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";


export const Census = (
	{
		address,
		benefits = null,
		canvasTarget,
	}
) => {
	let [censusData, setCensusData] = useState(new Map([
		["total_population", 0],
		["under5", 0],
		["under18", 0],
		["over64", 0],
		["median_age", 0],
		["minority_percent", 0],
		["median_income", 0],
		["per_capita_income", 0],
		["poverty_percent", 0],
		["total_housing_units", 0],
		["median_year_built", 0],
		["median_home_value", 0],
		["total_household_types", 0],
		["vacant_households", 0],
		["family_households", 0],
		["married_couple_households", 0],
		["other_family_households", 0],
		["male_family_households", 0],
		["female_family_households", 0],
		["non_family_households", 0],
		["alone_households", 0],
		["non_alone_households", 0],
		["total_home_tenure", 0],
		["home_mortgage", 0],
		["no_home_loan", 0],
		["rented_home", 0],
	]));
	let [isOpen, setIsOpen] = useState({
		population: true,
		income: false,
		homes: false,
		householdTypes: false,
		homeTenure: false,
	});

	useEffect(() => {
			let clonedFeature = structuredClone(benefits);
			let clonedCensusData = structuredClone(censusData);
			clonedCensusData.forEach((value, key, map) => {
				if (["minority_percent", "poverty_percent", "vacant_household"].includes(key)) {
					clonedCensusData.set(key, tls(clonedFeature.get(key), 1));
				} else if (key === "median_year_built") {
					clonedCensusData.set(key, clonedFeature.get(key));
				} else {
					clonedCensusData.set(key, tls(clonedFeature.get(key)));
				}
			});
			setCensusData(clonedCensusData);
		},
		[benefits]
	);

	let tls = (value, mfd = 0) => {
		let locale = "en-US";
		let options = {maximumFractionDigits: mfd};
		value = parseFloat(value);
		return value.toLocaleString(locale, options);
	};

	let toggleCollapse = (target) => {
		setIsOpen(prev => ({
			...prev,
			[target]: !prev[target],
		}));
	};

	return (
		<section id={canvasTarget}>
			<Top text={"OurTrees Community"}/>
			<Row>
				<Col>
					<p>
						Location! Location! Location! Context is
						important when it comes to the trees all
						around us. Here are some fast facts from
						the U.S. census:
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<h5>{address}</h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<p
						className={"h5"}
						onClick={
							() => toggleCollapse("population")
						}
					>
						<FAI
							data-html2canvas-ignore={"true"}
							size={"xs"}
							icon={["fas", isOpen.population ? "minus" : "plus"]}
						/>
						Population
					</p>
					<Collapse isOpen={isOpen.population}>
						<Table striped size={"sm"}>
							<tbody>
							<tr>
								<td>Total Population</td>
								<td className={"text-end"}>
									{censusData.get("total_population")}
								</td>
							</tr>
							<tr>
								<td>Under 5</td>
								<td className={"text-end"}>
									{censusData.get("under5")}
								</td>
							</tr>
							<tr>
								<td>Under 18</td>
								<td className={"text-end"}>
									{censusData.get("under18")}
								</td>
							</tr>
							<tr>
								<td>Over 64</td>
								<td className={"text-end"}>
									{censusData.get("over64")}
								</td>
							</tr>
							<tr>
								<td>Median Age</td>
								<td className={"text-end"}>
									{censusData.get("median_age")} years
								</td>
							</tr>
							<tr>
								<td>Minority Percent</td>
								<td className={"text-end"}>
									{censusData.get("minority_percent")}%
								</td>
							</tr>
							</tbody>
						</Table>
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<p
						className={"h5"}
						onClick={
							() => toggleCollapse("income")
						}
					>
						<FAI
							data-html2canvas-ignore={"true"}
							size={"xs"}
							icon={["fas", isOpen.income ? "minus" : "plus"]}
						/>
						Income Overview
					</p>
					<Collapse isOpen={isOpen.income}>
						<Table striped size={"sm"}>
							<tbody>
							<tr>
								<td>Median Income</td>
								<td className={"text-end"}>
									${censusData.get("median_income")}
								</td>
							</tr>
							<tr>
								<td>Per Capita Income</td>
								<td className={"text-end"}>
									${censusData.get("per_capita_income")}
								</td>
							</tr>
							<tr>
								<td>Percent Impoverished</td>
								<td className={"text-end"}>
									{censusData.get("poverty_percent")}%
								</td>
							</tr>
							</tbody>
						</Table>
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<p
						className={"h5"}
						onClick={
							() => toggleCollapse("homes")
						}
					>
						<FAI
							data-html2canvas-ignore={"true"}
						    size={"xs"}
							icon={["fas", isOpen.homes ? "minus" : "plus"]}
						/>
						Homes
					</p>
					<Collapse isOpen={isOpen.homes}>
						<Table striped size={"sm"}>
							<tbody>
							<tr>
								<td>Total Housing Units</td>
								<td className={"text-end"}>
									{censusData.get("total_housing_units")}
								</td>
							</tr>
							<tr>
								<td>Median Year Built</td>
								<td className={"text-end"}>
									{censusData.get("median_year_built")}
								</td>
							</tr>
							<tr>
								<td>Median Value</td>
								<td className={"text-end"}>
									${censusData.get("median_home_value")}
								</td>
							</tr>
							</tbody>
						</Table>
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<p
						className={"h5"}
						onClick={
							() => toggleCollapse("householdTypes")
						}
					>
						<FAI
							data-html2canvas-ignore={"true"}
							size={"xs"}
							icon={["fas", isOpen.householdTypes ? "minus" : "plus"]}
						/>
						Household Types
					</p>
					<Collapse isOpen={isOpen.householdTypes}>
						<Table striped size={"sm"}>
							<tbody>
							<tr>
								<td>Total</td>
								<td className={"text-end"}>
									{censusData.get("total_household_types")}
								</td>
							</tr>
							<tr>
								<td>Vacant Percent</td>
								<td className={"text-end"}>
									{censusData.get("vacant_households")}%
								</td>
							</tr>
							<tr>
								<td>Families</td>
								<td className={"text-end"}>
									{censusData.get("family_households")}
								</td>
							</tr>
							<tr>
								<td>Married Couples</td>
								<td className={"text-end"}>
									{censusData.get("married_couple_households")}
								</td>
							</tr>
							<tr>
								<td>Other Families</td>
								<td className={"text-end"}>
									{censusData.get("other_family_households")}
								</td>
							</tr>
							<tr>
								<td>Male Householder</td>
								<td className={"text-end"}>
									{censusData.get("male_family_households")}
								</td>
							</tr>
							<tr>
								<td>Female Householder</td>
								<td className={"text-end"}>
									{censusData.get("female_family_households")}
								</td>
							</tr>
							<tr>
								<td>Non-Family Households</td>
								<td className={"text-end"}>
									{censusData.get("non_family_households")}
								</td>
							</tr>
							<tr>
								<td>Householder Living Alone</td>
								<td className={"text-end"}>
									{censusData.get("alone_households")}
								</td>
							</tr>
							<tr>
								<td>Householder Not Living Alone</td>
								<td className={"text-end"}>
									{censusData.get("non_alone_households")}
								</td>
							</tr>
							</tbody>
						</Table>
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<p
						className={"h5"}
						onClick={
							() => toggleCollapse("homeTenure")
						}
					>
						<FAI
							data-html2canvas-ignore={"true"}
							size={"xs"}
							icon={["fas", isOpen.homeTenure ? "minus" : "plus"]}
						/>
						Home Tenure
					</p>
					<Collapse isOpen={isOpen.homeTenure}>
						<Table striped size={"sm"}>
							<tbody>
							<tr>
								<td>Total Tenure</td>
								<td className={"text-end"}>
									{censusData.get("total_home_tenure")}
								</td>
							</tr>
							<tr>
								<td>Mortgage</td>
								<td className={"text-end"}>
									{censusData.get("home_mortgage")}
								</td>
							</tr>
							<tr>
								<td>No Home Loan</td>
								<td className={"text-end"}>
									{censusData.get("no_home_loan")}
								</td>
							</tr>
							<tr>
								<td>Rented</td>
								<td className={"text-end"}>
									{censusData.get("rented_home")}
								</td>
							</tr>
							</tbody>
						</Table>
					</Collapse>
				</Col>
			</Row>
			{/*<Row>*/}
			{/*	<Col>*/}
			{/*		<p className={"h5"}>*/}
			{/*			Education*/}
			{/*		</p>*/}
			{/*		<Table striped>*/}
			{/*			<tbody>*/}
			{/*			<tr>*/}
			{/*				<td>Total Counted</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>No School</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>Elementary School</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>High School</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>High School</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>Total Counted</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>Total Counted</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			<tr>*/}
			{/*				<td>Total Counted</td>*/}
			{/*				<td className={"text-end"}>*/}
			{/*					{tls(data.)}*/}
			{/*				</td>*/}
			{/*			</tr>*/}
			{/*			</tbody>*/}
			{/*		</Table>*/}
			{/*	</Col>*/}
			{/*</Row>*/}
		</section>
	)
}