import {v4 as createUUID} from 'uuid';


export class HTHC {
	constructor(
		{
			uuid = createUUID(),
			optIn = false,
			// Health
			dieback = "",
			discoloration = null,
			defoliation = null,
			vigor = null,
			hthcExposure = null,
			transparency = "",
			// Pests
			damage = null,
			exudation = null,
			decay = null,
			loss = null,
			wilt = null,
			shoots = null,
		} = {}
	) {
		this.uuid = uuid;
		this.optIn = optIn;
		this.dieback = dieback;
		this.discoloration = discoloration;
		this.defoliation = defoliation;
		this.vigor = vigor;
		this.hthcExposure = hthcExposure;
		this.transparency = transparency;
		this.damage = damage;
		this.exudation = exudation;
		this.decay = decay;
		this.loss = loss;
		this.wilt = wilt;
		this.shoots = shoots;
	}

	__fields = () => {
		if (this.optIn) {
			return {
				uuid: this.uuid,
				optIn: this.optIn,
				dieback: this.dieback,
				discoloration: this.discoloration,
				defoliation: this.defoliation,
				vigor: this.vigor,
				hthcExposure: this.hthcExposure,
				transparency: this.transparency,
				damage: this.damage,
				exudation: this.exudation,
				decay: this.decay,
				loss: this.loss,
				wilt: this.wilt,
				shoots: this.shoots,
			}
		} else {
			return {
				uuid: this.uuid,
				optIn: this.optIn,
			}
		}
	};

	__url = () => {
		let fields = this.__fields();
		let query = new URLSearchParams();

		function cycleFields(fields) {
			for (let f in fields) {
				if (fields[f] instanceof Object) {
					cycleFields(fields[f])
				} else {
					query.append(f, fields[f]);
				}
			}
		}

		cycleFields(fields);

		return query;
	};
}