export const Collapse = (
	{
		children,
		detailsClassName,
		summaryClassName,
		summaryText,
	}
) => {
	return (
		<details className={detailsClassName}>
			<summary className={summaryClassName}>
				{summaryText}
			</summary>
			{children.map((child) => {
				return (
					child
				)
			})}
		</details>
	)
}