import "core-js/stable"
import "regenerator-runtime/runtime"

import Building from "itr/building"
import Location from "itr/location"
import Project from "itr/project"
import Tree from "itr/tree"
import HTHC from "itr/hthc"

import Benefit from "itr/benefit"

import Carbon from "itr/benefit/carbon"
import Energy from "itr/benefit/energy"
import Hydrology from "itr/benefit/hydrology"
import PollutionAvoided from "itr/benefit/air"
import PollutionRemoved from "itr/benefit/air"

let itr = {
	Building,
	Location,
	Project,
	Tree,
	HTHC,

	Benefit,

	Carbon,
	Energy,
	Hydrology,
	PollutionAvoided,
	PollutionRemoved,
}

export {
	Building,
	Location,
	Project,
	Tree,
	HTHC,

	Benefit,

	Carbon,
	Energy,
	Hydrology,
	PollutionAvoided,
	PollutionRemoved,
}

export default itr

