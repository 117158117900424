import React from "react";

export function Pear() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Pear spp</desc>
			<path
				fill="#006D41"
				d="M73.5-.5h10c5.642 1.047 11.475 1.713 17.5 2 3.477.172 6.644-.494 9.5-2h8a114.54 114.54 0 00-5.5 11C109.09 39.817 95.257 63.317 71.5 81a314.71 314.71 0 00-21 11 326.173 326.173 0 00-16 5c-5.825.707-11.491.374-17-1-4.58 7.152-6.914 14.986-7 23.5h-4c-.163-2.357.003-4.69.5-7 2.495-6.147 4.995-12.313 7.5-18.5A93.45 93.45 0 016 80.5c-2.675-9.51-3.175-19.176-1.5-29 1.424-2.014 2.09-4.348 2-7 13.731-27.706 36.064-42.706 67-45z"
			></path>
		</svg>
	);
}
