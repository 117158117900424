import React from "react";

import {
	Row,
	Col,
} from "reactstrap";

import {Top} from "./Top";

import {
	calculateAvoidedCarEmissions,
	toLocaleString,
	convertRunoffToPoolVolume,
	calculateAvoidedHealthIncidents,
	convertImperviousToParkingLots,
	convertCanopyToFootballFields,
	calculateAvoidedMedicalVisits,
	convertBenefits,
	extractBenefits,
} from "./reportHelper";

import RugbyPitch from "../../../img/RugbyPitch.jsx";
import HospitalBed from "../../../img/HospitalBed.jsx";
import ParkingLots from "../../../img/ParkingLots.jsx";
import SmokeyCar from "../../../img/SmokeyCar.jsx";
import SwimmingPool from "../../../img/SwimmingPool.jsx";


export const Story = (
	{
		address,
		benefits,
		canvasTarget,
		imperial,
	}
) => {
	let footballFields = toLocaleString(
		convertCanopyToFootballFields(
			benefits.get("canopy.area")
		)
	);

	let parkingLot = convertImperviousToParkingLots(
		benefits.get("impervious.area")
	);
	parkingLot = toLocaleString(
		parkingLot,
		{maximumFractionDigits: parkingLot < 10 ? 1 : 0}
	);

	let convertedBenefits = convertBenefits(
		extractBenefits(benefits),
		imperial
	);

	let avoidedCarEmissions = toLocaleString(
		calculateAvoidedCarEmissions(
			convertedBenefits,
			imperial
		)
	);

	let {
		respiratoryIncidents,
		schoolLossDays,
		workLossDays
	} = calculateAvoidedHealthIncidents(convertedBenefits);
	let lostDaysText;
	if (schoolLossDays >= 1 && workLossDays >= 1) {
		lostDaysText = `This also prevents the loss of ${schoolLossDays.toLocaleString()} school day(s) and ${workLossDays.toLocaleString()} work day(s).`;
	}
	if (schoolLossDays >= 1 && workLossDays <= 0) {
		lostDaysText = `This also prevents the loss of ${schoolLossDays.toLocaleString()} school day(s).`;
	}
	if (schoolLossDays <= 0 && workLossDays >= 1) {
		lostDaysText = `This also prevents the loss of ${workLossDays.toLocaleString()} work day(s).`;
	}
	if (schoolLossDays <= 0 && workLossDays <= 0) {
		lostDaysText = "";
	}

	let avoidedMedicalVisits = calculateAvoidedMedicalVisits(benefits);

	let respiratoryAndMedicalVisitText = "";
	if (respiratoryIncidents >= 1 && avoidedMedicalVisits >= 1) {
		respiratoryAndMedicalVisitText = `The filtration and removal
			 of air pollution by the leaves of trees is estimated to avoid
			  ${avoidedMedicalVisits.toLocaleString()} hospital and ER visits as well as
			   reduce acute respiratory symptoms and exacerbated asthma
			    by ${respiratoryIncidents.toLocaleString()} incidents.`;
	}
	if (respiratoryIncidents >= 1 && avoidedMedicalVisits <= 0) {
		respiratoryAndMedicalVisitText = `The filtration and removal
			 of air pollution by the leaves of trees is estimated to reduce
			  acute respiratory symptoms and exacerbated asthma
			    by ${respiratoryIncidents.toLocaleString()} incidents.`;
	}
	if (respiratoryIncidents <= 0 && avoidedMedicalVisits >= 1) {
		respiratoryAndMedicalVisitText = `The filtration and removal
			 of air pollution by the leaves of trees is estimated to avoid
			  ${avoidedMedicalVisits.toLocaleString()} hospital and ER visits.`;
	}
	if (respiratoryIncidents <= 0 && avoidedMedicalVisits <= 0) {
		respiratoryAndMedicalVisitText = "";
	}

	let pools = toLocaleString(
		convertRunoffToPoolVolume(convertedBenefits, imperial)
	);

	return (
		<section id={canvasTarget}>
			<Top text={"OurTrees Story"}/>
			<Row>
				<Col>
					<p>
						The impacts of tree benefits can be hard to
						grasp. Below are some real-world examples of
						how trees work hard for our community.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className={"h5"}>
						Trees in {address}
					</p>
					<hr/>
				</Col>
			</Row>
			<Row className={"mb-4"}>
				<Col>
					<em>
						Trees lower air temperature and absorb water,
						while impervious areas do the opposite.
					</em>
				</Col>
			</Row>
			<Row className={"mb-4"}>
				<Col>
					<div className={"flow-root"}>
							<span className={"ms-3 float-end image-wrap"}>
								{RugbyPitch()}
							</span>
						<strong className={"green"}>
							Trees shade an area equivalent to{" "}
							{footballFields} professional football fields!
						</strong>
					</div>
				</Col>
			</Row>
			<Row className={"mb-4"}>
				<Col>
					<div className={"flow-root"}>
							<span className={"me-3 float-start image-wrap"}>
								{ParkingLots()}
							</span>
						<strong className={"text-danger"}>
							The land area covered by impervious surfaces &ndash;
							typically buildings and pavement &ndash; is like a{" "}
							{parkingLot} square mile parking lot.
						</strong>
					</div>
				</Col>
			</Row>
			<Row className={"mb-4"}>
				<Col>
					<p className={"h5"}>
						Annual Tree Benefits for<br/>{address}
					</p>
					<hr/>
				</Col>
			</Row>
			<Row className={"mb-4"}>
				<Col>
					<div className={"flow-root"}>
							<span className={"ms-3 float-end image-wrap"}>
								{SmokeyCar()}
							</span>
						<strong className={"green"}>
							Sequestering carbon as wood in trees counteracts
							the CO<sub>2</sub> emissions of {avoidedCarEmissions}{" "}
							gasoline powered passenger cars.
						</strong>
					</div>
				</Col>
			</Row>
			{respiratoryAndMedicalVisitText.length ?
				<Row className={"mb-4"}>
					<Col>
						<div className={"flow-root"}>
							<span className={"me-3 float-start image-wrap"}>
								{HospitalBed()}
							</span>
							<strong className={"brown"}>
								{respiratoryAndMedicalVisitText} {lostDaysText}
							</strong>
						</div>
					</Col>
				</Row>
				:
				null
			}
			<Row className={"mb-4"}>
				<Col>
					<div className={"flow-root"}>
						<span className={"ms-3 float-end image-wrap"}>
							{SwimmingPool()}
						</span>
						<strong className={"blue"}>
							Rainfall absorbed by tree roots and therefore
							kept out of storm sewers is equal to {pools}{" "}
							Olympic sized pools!
						</strong>
					</div>
				</Col>
			</Row>
		</section>
	);
}
