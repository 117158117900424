import {library} from "@fortawesome/fontawesome-svg-core";
import {far} from "@fortawesome/free-regular-svg-icons";
import {
	faArrowLeft,
	faArrowRight,
	faCalculator,
	faCheckCircle,
	faCircle,
	faCloud,
	faCloudSun,
	faCopy,
	faCrosshairs,
	faEnvelope,
	faExclamationTriangle,
	faSearch,
	faMapMarkerAlt,
	faMinus,
	faPen,
	faPlus,
	faPowerOff,
	faQuestion,
	faQuestionCircle,
	faSave,
	faSearchLocation,
	faSun,
	faThumbtack,
	faTrash,
	faUndoAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(
	far,
	faArrowLeft,
	faArrowRight,
	faCalculator,
	faCheckCircle,
	faCircle,
	faCloud,
	faCloudSun,
	faCopy,
	faCrosshairs,
	faEnvelope,
	faExclamationTriangle,
	faSearch,
	faMapMarkerAlt,
	faMinus,
	faPen,
	faPlus,
	faPowerOff,
	faQuestion,
	faQuestionCircle,
	faSave,
	faSearchLocation,
	faSun,
	faThumbtack,
	faTrash,
	faUndoAlt
);

export default library;