import {Abbreviations, ChildIterator, Collapse, Footnotes} from "../shared";
import {HeavyLine} from "../NutritionLabelParts";
import {Col, Container, Row} from "reactstrap";
import {ANCHORS} from "../../static_props/MyTree";
import {SocialSharing} from "../../SocialSharing/SocialSharing";
import html2canvas from "html2canvas";

export const Bottom = (
	{
		activeTab,
		activeSubTab,
		dbVersion,
		engineVersion,
		tree,
		url = "",
		warning = "",
	}
) => {
	let body = `This is a MyTree project.`;
	if (tree) {
		body = `This is a MyTree project for a ${tree.common} tree.`;
	}
	let emailURL = url ? `${window.location.origin}/#/benefits/${url}&emailed=true` : null;
	let footnotes = [
		<span>
			For large trees sequestration is overtaken by CO<sub>2</sub>{" "}
			loss with decay/maintenance.
		</span>,
		<span>
			CO<sub>2</sub> equivalent is estimated by calculating how much
			atmospheric CO<sub>2</sub> is taken in by trees to provide the
			carbon stored in the tissues of individual trees.
		</span>,
		<span>
			Positive energy values indicate savings or reduced emissions.
			Negative values indicate increased usage or emissions.
			Electricity used for cooling and heating and fuels like
			natural gas or oil used for heating, based on typical usage
			for the selected location.
		</span>,
		<span>
			Not an annual amount or value.
		</span>,
		<span className={"word-break-keep-all"}>
			{
				warning.length ?
					warning
					:
					`This location is supported by i-Tree. Localized 
					data have been used to estimate its tree benefits.`
			}
		</span>
	];

	let handleImageExport = async () => {
		let reportType = activeSubTab ? "Equivalents" : "Benefits";
		let reportEra = activeTab ? "Future" : "Current";
		let fileName = `MyTree_${reportEra}_${reportType}_Report.png`;

		let reportElement = document.getElementById("content").querySelector(".nutrition");

		let finePrint = reportElement.querySelector("details");
		let finePrintWasOpen = finePrint.hasAttribute("open");
		if (!finePrintWasOpen) finePrint.setAttribute("open", "");

		let shareSection = reportElement.querySelector("section");
		shareSection.classList.add("d-none");

		let collapseSummary = reportElement.querySelector("summary");
		collapseSummary.classList.add("d-none");

		const COMMON_OPTIONS = {x: -10, y: -10, scale: 3};
		const REPORT_CANVAS_OPTIONS = {
			width: reportElement.clientWidth + 20,
			height: reportElement.clientHeight + (activeSubTab ? 20 : 0),
			...COMMON_OPTIONS,
		};
		let containerCanvas = document.createElement("canvas");
		let reportCanvas = await html2canvas(
			reportElement,
			REPORT_CANVAS_OPTIONS
		);
		containerCanvas.width = reportCanvas.width;
		containerCanvas.height = reportCanvas.height;
		let containerContext = containerCanvas.getContext("2d");
		containerContext.drawImage(reportCanvas, 0, 0);

		let downloadLink = document.createElement("a");
		downloadLink.setAttribute("download", fileName);
		// This is the default "type parameter" of canvas.toBlob,
		// and is included here just for reference.
		let type = "image/png";
		// A Number between 0 and 1 indicating the image quality to be used
		// when creating images using file formats that support lossy compression.
		let quality = 1;
		containerCanvas.toBlob(
			(blob) => {
				let url = URL.createObjectURL(blob);
				downloadLink.setAttribute("href", url);
				downloadLink.click();
			},
			type,
			quality
		);

		if (!finePrintWasOpen) finePrint.removeAttribute("open");
		shareSection.classList.remove("d-none");
		collapseSummary.classList.remove("d-none");
	};

	return (
		<Container>
			<ChildIterator>
				<HeavyLine/>
				<Row>
					<Col>
						<p>Benefit estimates are based on USDA Forest Service
							research and are meant for guidance only.
							Visit {ANCHORS.home} to learn more.
						</p>
					</Col>
				</Row>
				<Row className={`d-${activeSubTab === 0 ? "flex" : "none"}`}>
					<Col>
						<span data-html2canvas-ignore={"true"}>
							See the Project Menu for currency conversions.
						</span>
					</Col>
				</Row>
				<Row className={`my-3 d-${activeSubTab === 0 ? "flex" : "none"}`}>
					<Col>
						<Collapse
							detailsClassName={"text-center"}
							summaryClassName={"text-decoration-underline"}
							summaryText={"Read the fine print"}
						>
							<Footnotes notes={footnotes}/>
							<Abbreviations/>
							<ChildIterator>
								<p
									className={"fst-italic mb-0"}
									key={"app-version"}
								>
									MyTree{" "}
									{process.env.REACT_APP_VERSION}
								</p>
								<p
									className={"fst-italic mb-0"}
									key={"poweredBy"}
								>
									Powered by i-Tree Engine{" "}
									{engineVersion}
								</p>
								<p
									className={"fst-italic mb-0"}
									key={"locationSpeciesVersion"}
								>
									Location Species Database{" "}
									{dbVersion}
								</p>
							</ChildIterator>
						</Collapse>
					</Col>
				</Row>
				{emailURL ?
				<SocialSharing
					body={body}
					handleImageExport={handleImageExport}
					url={emailURL}
					emailSubject={"Check out this MyTree project!"}
				/>
					:
					null
				}
			</ChildIterator>
		</Container>
	)
}