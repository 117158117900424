import Dexie from "dexie";


export class Database {
	constructor(params) {
		this.database = new Dexie(params.name);

		this.database.version(params.version).stores(params.stores);
	}

	getAll = async (table) => {
		return await this.database.table(table).toArray();
	};

	get = (table, id) => {
		return this.database[table].get(id);
	};

	delete = async (table, id) => {
		return await this.database[table].delete(id);
	};

	close = () => {
		this.database.close()
	};

	copyLayer = (layer, to = {}) => {
		let layerCopy = JSON.parse(JSON.stringify(layer));

		try {
			Object.assign(to, layerCopy);
		} catch (e) {
			Object.assign(to, layer);
		}

		return to;
	};

	save = (table, object) => {
		let tmp = this.copyLayer(object);
		return this.database[table].put(tmp);
	};

	bulkSave = (table, array) => {
		let additions = [];
		array.forEach((arr, idx) => {
			let tmp = this.copyLayer(arr);
			additions.push(tmp);
		});
		return this.database[table].bulkPut(additions);
	};

	deleteAll = (table) => {
		return this.database[table].clear();
	};

	deleteDatabase = () => {
		return this.database.delete();
	};

	count = async (table) => {
		return await this.database[table].toCollection().count();
	};

	isStoragePersisted = async () => {
		return navigator.storage && navigator.storage.persist ? await navigator.storage.persisted() : undefined;
	};

	enablePersistence = async () => {
		return navigator.storage && navigator.storage.persist ? await navigator.storage.persist() : undefined;
	};

	showEstimatedQuota = async () => {
		return navigator.storage && navigator.storage.estimate ? await navigator.storage.estimate() : undefined;
	}
}
