import React from "react";
import {
	Row,
	Col,
	ButtonGroup,
	Button,
	Label
} from "reactstrap";
import {InfoModalButton} from "./Buttons";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";


export const Pests = (
	{
		hthc,
		updateProject,
	}
) => {

	const updateField = (field, truth) => {
		updateProject(
			{hthc: {[field]: truth}}
		)
	};

	let check = <FAI className={"me-2"} icon={"check-circle"}/>;
	let circle = <FAI className={"me-2"} icon={["far", "circle"]}/>;
	let left = {
		"Wood Damage": "damage",
		"Exudation": "exudation",
		"Decay": "decay",
	};
	let right = {
		"Leaf Loss": "loss",
		"Leaf Wilt": "wilt",
		"Epicormic Shoots": "shoots",
	};
	let lfields = Object.entries(left);
	let rfields = Object.entries(right);


	return (
		<React.Fragment>
			<Row className={"mt-3 mt-sm-0 mb-n3"}>
				<Col className={"d-flex justify-content-between justify-content-md-between"}>
					<h5>
						Pest Symptoms
					</h5>
					<InfoModalButton
						subject={"pests"}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					xs={{size: 6}}
					sm={{size: 12}}
				>
					{lfields.map((entry, idx) => {
						return (
							<Row
								key={entry[1] + idx}
							>
								<Col className={"pe-0"}>
									<Label>
										{entry[0]}
									</Label>
									<br/>
									<ButtonGroup className={"pe-3 pe-md-0 w-100-xs w-50-sm"}>
										<Button
											active={hthc[entry[1]] === true}
											className={"round"}
											color={"success"}
											onClick={
												() => updateField(entry[1], true)
											}
											outline
										>
											{hthc[entry[1]] === true ? check : circle} Y<span className={"d-none d-sm-inline"}>es</span>
										</Button>
										<Button
											active={hthc[entry[1]] === false}
											className={"round"}
											color={"success"}
											onClick={
												() => updateField(entry[1], false)
											}
											outline
										>
											{hthc[entry[1]] === false ? check : circle} N<span className={"d-none d-sm-inline"}>o</span>
										</Button>
									</ButtonGroup>
								</Col>
							</Row>
						)
					})}
				</Col>
				<Col
					xs={{size: 6}}
					sm={{size: 12}}
				>
					{rfields.map((entry, idx) => {
						return (
							<Row
								key={entry[1] + idx}
							>
								<Col>
									<Label>
										{entry[0]}
									</Label>
									<br/>
									<ButtonGroup className={"w-100-xs w-50-sm"}>
										<Button
											active={hthc[entry[1]] === true}
											className={"round"}
											color={"success"}
											onClick={
												() => updateField(entry[1], true)
											}
											outline
										>
											{hthc[entry[1]] === true ? check : circle} Y<span className={"d-none d-sm-inline"}>es</span>
										</Button>
										<Button
											active={hthc[entry[1]] === false}
											className={"round"}
											color={"success"}
											onClick={
												() => updateField(entry[1], false)
											}
											outline
										>
											{hthc[entry[1]] === false ? check : circle} N<span className={"d-none d-sm-inline"}>o</span>
										</Button>
									</ButtonGroup>
								</Col>
							</Row>
						)
					})}
				</Col>
			</Row>
		</React.Fragment>
	)
}
