import React, {Component} from "react";

import {
	Col,
	Container,
	Row,
} from "reactstrap";

import {
	LocationWarning,
} from "../parts/GeneralPageElements";

import {BingMap} from "../parts/BingMap";

import "../../stylesheets/location-selection.scss";

import {
	InfoModalButton,
	NavButton,
	ToggleButton,
} from "../parts/Buttons";
import {AddressInput} from "../parts/AddressInput";
import {fromLonLat} from "ol/proj";
import {MYTREE} from "../../utilities/resources";

import {
	extractAddressComponents,
	getLatLngFromAddress,
} from "../../utilities/utilities";


export class LocationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			warning: "",
			activeImagery: this.props.activeImagery,
		};
	}

	validateAddress = (e = null) => {
		if (e) e.preventDefault();
		let addressInput = document.querySelector('.address-input');
		let feedback = addressInput.parentElement.nextSibling;
		let {history} = {...this.props};

		if (!addressInput.value) {
			addressInput.classList.add("is-invalid");
			feedback.classList.add("is-invalid", "d-block");
		} else if (
			this.props.location.address.entered !== addressInput.value
		) {
			getLatLngFromAddress(addressInput.value).then(
				(response) => {
					if (response.status.toLowerCase() === "ok") {
						let result = response.results[0];
						let components = extractAddressComponents(result);
						let latitude = result.geometry.location.lat();
						let longitude = result.geometry.location.lng();
						this.props.handleLocationUpdate(
							addressInput.value,
							components,
							latitude,
							longitude,
							history.push(
								this.props.navButtonParameters.path
							)
						);
					} else {
						this.handleBadResponse(response);
					}
				}
			);
		} else {
			this.setState({
					warning: "",
				},
				() => {
					history.push(
						this.props.navButtonParameters.path
					)
				}
			);
		}
	};

	switchLayers = (value) => {
		this.setState({
			activeImagery: value
		});
	};

	onChange = (e) => {
		this.props.updateProject(
			{
				location: {
					address: {
						entered: e.currentTarget.value,
					},
				},
			},
			this.state.warning !== "" ? this.setState({warning: ""}) : null
		);
	};

	render() {
		let location = JSON.parse(JSON.stringify(this.props.location));
		let disabled = location.address.entered.length <= 0;
		let center = fromLonLat([location.longitude, location.latitude]);
		let baseLayerToggleButtonSettings = [
			{text: "Streets", color: "primary"},
			{text: "Satellite", color: "primary"},
		];

		return (
			<React.Fragment>
				<Container fluid>
					<Row className={"mt-1 mt-lg-3"}>
						<Col
							xs={{size: 12}}
							lg={{size: 3}}
							xl={{size: 2}}
						>
							<p className={"h4 mb-0 text-center text-lg-left"}>
								{this.props.headline}
							</p>
						</Col>
						<Col
							xs={{size: 10}}
							lg={{size: 7}}
							xl={{size: 8}}
						>
							<AddressInput
								location={location}
								handleBadResponse={this.handleBadResponse}
								onChange={this.onChange}
								handleGeolocation={this.props.handleGeolocation}
								handleAddressSearch={this.props.handleAddressSearch}
								warning={this.state.warning}
							/>
						</Col>
						<Col xs={{size: 2}}>
							<InfoModalButton
								className={"float-end"}
								subject={this.props.infoSubject}
							/>
						</Col>
					</Row>
					{this.state.warning ?
						<Row>
							<Col className={"text-center"}>
								<em className={"text-danger"}>
									{this.state.warning}
								</em>
							</Col>
						</Row>
						:
						null
					}
					<Row>
						<Col className={"mt-1 mt-lg-1 text-center"}>
							<small className={"fst-italic"}>
								{this.props.subAddressbarText}
							</small>
							<BingMap
								center={center}
								layers={this.props.layers || []}
								features={this.props.features || []}
								zoom={this.props.zoom}
								maxZoom={this.props.maxZoom}
								iconParameters={this.props.iconParameters}
								imagerySets={this.props.imagerySets}
								activeImagery={this.state.activeImagery}
								onMapClick={this.props.onMapClick}
								onMapMount={this.props.onMapMount}
							/>
						</Col>
					</Row>
					<Row className={"mt-3"}>
						<Col
							className={"d-none d-lg-block"}
							lg={{size: 5}}
						>
							<LocationWarning/>
						</Col>
						<Col
							xs={{size: 12}}
							sm={{size: 6, offset: 0}}
							lg={{size: 3}}
							xl={{size: 4}}
						>
							{MYTREE ?
								<ToggleButton
									className={"float-sm-none float-lg-right"}
									toggleFunction={this.switchLayers}
									id={"layers"}
									selected={this.state.activeImagery}
									settings={baseLayerToggleButtonSettings}
								/>
								:
								null
							}
						</Col>
						<Col
							className={"mt-4 mt-sm-0 text-end"}
							xs={{size: 12}}
							sm={{size: 6}}
							lg={{size: 4}}
							xl={{size: 3}}
						>
							<NavButton
								className={"float-sm-right round"}
								color={"success"}
								disabled={disabled}
								function={this.validateAddress}
								path={this.props.navButtonParameters.path}
								rightIcon={"arrow-right"}
								text={this.props.navButtonParameters.text}
								title={"Proceed to the next page"}
							/>
						</Col>
					</Row>
					<Row className={"d-block d-lg-none mt-3 text-center"}>
						<Col
							sm={{size: 8, offset: 2}}
							lg={{size: 4, offset: 4}}
						>
							<LocationWarning/>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}
