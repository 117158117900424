import React from "react";
import {
	Row,
	Col,
	ButtonGroup,
	Button,
	Label
} from "reactstrap";
import {InfoModalButton} from "./Buttons";


export const Health = (
	{
		hthc,
		updateProject,
	}
	) => {
	const numericCheck = (e, field, min, max) => {
		let value = e.currentTarget.value;

		if (
			(
				!isNaN(parseFloat(value)) ||
				value === ""
			) && (
				value <= max &&
				value >= min
			)
		) {
			updateProject({hthc: {[field]: value}});
		}
	};

	const onKeyDown = (e) => {
		let key = e.key.toLowerCase();
		let keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "backspace", "enter", "tab"];

		if (!keys.includes(key)) e.preventDefault();
	};

		let color = "primary";
		let fields = {
			"Leaf discoloration": "discoloration",
			"Leaf defoliation": "defoliation",
			"Crown vigor": "vigor",
			// "Crown light exposure": "hthc_exposure",
		};

		return (
			<React.Fragment>
				<Row className={"mt-4 mb-n3"}>
					<Col className={"d-flex justify-content-between justify-content-md-between"}>
						<h5>
							Tree Health
						</h5>
						<InfoModalButton
							subject={"health"}
						/>
					</Col>
				</Row>
				<Row>
					<Col
						xs={{size: 8}}
					>
						<Label>Fine twig dieback (%)</Label>
						<input
							className={"form-control w-50-xs"}
							max={100}
							min={0}
							onChange={
								(e) => numericCheck(e, "dieback", 0, 100)
							}
							onKeyDown={
								(e) => onKeyDown(e)
							}
							onFocus={
								(e) => e.currentTarget.select()
							}
							step={1}
							type={"number"}
							value={hthc.dieback}
						/>
					</Col>
				</Row>
				{Object.entries(fields).map((entry, idx) => {
					return (
						<Row
							key={entry[1]}>
							<Col
								xs={{size: 12}}
							>
								<Label>{entry[0]} (low to high)</Label>
							</Col>
							<Col
								xs={{size: 10}}
								sm={{size: 12, offset: 0}}
							>
								<ButtonGroup
									className={"w-100"}
								>
									<Button
										active={hthc[entry[1]] === "1"}
										className={"round"}
										color={color}
										onClick={
											() => {
												updateProject(
													{hthc: {[entry[1]]: "1"}}
												)
											}
										}
										outline={hthc[entry[1]] !== "1"}
									>
										1
									</Button>
									<Button
										active={hthc[entry[1]] === "2"}
										color={color}
										onClick={
											() => {
												updateProject(
													{hthc: {[entry[1]]: "2"}}
												)
											}
										}
										outline={hthc[entry[1]] !== "2"}
									>
										2
									</Button>
									<Button
										active={hthc[entry[1]] === "3"}
										color={color}
										onClick={
											() => {
												updateProject(
													{hthc: {[entry[1]]: "3"}}
												)
											}
										}
										outline={hthc[entry[1]] !== "3"}
									>
										3
									</Button>
									<Button
										active={hthc[entry[1]] === "4"}
										color={color}
										onClick={
											() => {
												updateProject(
													{hthc: {[entry[1]]: "4"}}
												)
											}
										}
										outline={hthc[entry[1]] !== "4"}
									>
										4
									</Button>
									<Button
										active={hthc[entry[1]] === "5"}
										className={"round"}
										color={color}
										onClick={
											() => {
												updateProject(
													{hthc: {[entry[1]]: "5"}}
												)
											}
										}
										outline={hthc[entry[1]] !== "5"}
									>
										5
									</Button>
								</ButtonGroup>
							</Col>
						</Row>
					)
				})}
				<Row>
					<Col
						xs={{size: 12}}
					>
						<Label>Crown transparency (1&ndash;10)</Label>
					</Col>
					<Col
						xs={{size: 4}}
					>
						<input
							className={"form-control"}
							max={10}
							min={1}
							onChange={
								(e) => numericCheck(e, "transparency", 0, 10)
							}
							onKeyDown={
								(e) => onKeyDown(e)
							}
							onFocus={
								(e) => e.currentTarget.select()
							}
							step={1}
							type={"number"}
							value={hthc.transparency}
						/>
					</Col>
				</Row>
			</React.Fragment>
		)
}
