import React, {Component} from 'react';

import {
	Route,
	Switch
} from "react-router-dom";

import "./stylesheets/site-wide.scss";

import * as Pages from "./components/pages";
import {SharedNavbar} from "./components/parts/SharedNavbar";
import {DevelopmentWarning} from "./components/parts/GeneralPageElements";
import ProjectDropdown from "./components/parts/navbar/OurTrees/ProjectDropdown";
import {MenuDropdown} from "./components/parts/navbar/Shared";
import {
	Report,
	LocationWrapper,
	About,
} from "./components/pages/OurTrees";
import {GenericNotFound} from "./components/pages";
import "./components/icons";
import * as PROPS from "./components/static_props/OurTrees";
import {DEVMODE} from "./utilities/resources";

import {Project} from "./classes/project";
import {AfterBrand, AfterCollapse} from "./components/parts/navbar/MyTree";


export class OurTrees extends Component {
	constructor(props) {
		super(props);

		this.state = {
			unitType: true,
			currency: {
				code: "USD",
				locale: "en-US",
				rate: "1",
			},
			project: new Project(),
			geoid: null,
			activeBoundaryLayerIndex: 0,
		};
	};

	updateUnitType = () => {
		this.setState((prevState) => ({
			unitType: !prevState.unitType
		}));
	};

	updateGeoid = (geoid) => {
		this.setState((prevState) => ({
			geoid: geoid,
		}));
	};

	updateBoundaryLayerIndex = (layerIndex = 0) => {
		if (![0, 1].includes(layerIndex)) {
			throw new Error("layerIndex must be either 0 or 1")
		} else {
			this.setState({
				activeBoundaryLayerIndex: layerIndex
			});
		}
	};

	updateCurrency = (currency) => {
		this.setState({currency});
	};

	updateProject = (data = null, callback = null) => {
		let project = JSON.parse(JSON.stringify(this.state.project));

		Object.keys(data).forEach((projectKey, index) => {
			let update = data[projectKey];

			switch (projectKey) {
				case "project":
					Object.keys(update).forEach((projectSubKey, index) => {
						project[projectSubKey] = update[projectSubKey];
					});
					break;
				case "location":
					let location = {...project.location};
					Object.keys(update).forEach((locationKey, index) => {
						if (locationKey === "address") {
							Object.keys(update[locationKey]).forEach((addresKey, index) => {
								location.address[addresKey] = update[locationKey][addresKey];
							});
						} else {
							location[locationKey] = update[locationKey];
						}
					});
					project.location = location;
					break;
				default:
					console.log("DEFAULT SWITCH STATEMENT");
			}
		});

		project = new Project(project);

		this.setState({
				project,
			},
			() => {
				if (callback) callback();
			}
		);
	};

	reset = () => {
		this.setState({
			unitType: true,
			currency: {
				code: "USD",
				locale: "en-US",
				rate: "1",
			},
			project: new Project(),
		});
	};

	render() {
		let menus = [
			<ProjectDropdown
				key={"projectDropdown"}
				unitType={this.state.unitType}
				updateUnitType={this.updateUnitType}
				reset={this.reset}
			/>,
			<MenuDropdown key={"menuDropdown"}/>,
		];

		return (
			<div id={"navigation"}>
				<SharedNavbar
					{...PROPS.NAVBAR}
					menus={menus}
					afterBrand={AfterBrand}
					afterCollapse={AfterCollapse}
				/>
				{
					DEVMODE ?
						<DevelopmentWarning/>
						:
						null
				}
				<div id={"content"}>
					<Switch>
						<Route
							path={"/"}
							exact
							render={
								props =>
									<Pages.Landing
										{...props}
										{...PROPS.LANDING}
									/>
							}
						/>
						<Route
							path={"/location"}
							exact
							render={
								props =>
									<LocationWrapper
										{...props}
										location={this.state.project.location}
										activeBoundaryLayerIndex={this.state.activeBoundaryLayerIndex}
										updateGeoid={this.updateGeoid}
										updateBoundaryLayerIndex={this.updateBoundaryLayerIndex}
										updateProject={this.updateProject}
									/>
							}
						/>
						<Route
							path={
								[
									"/report/:longitude/:latitude",
									"/report/:geoid",
									"/report"
								]
							}
							exact
							render={
								props =>
									<Report
										{...props}
										{...PROPS.REPORT}
										location={this.state.project.location}
										unitType={this.state.unitType}
										geoid={this.state.geoid}
										project={this.state.project}
										activeBoundaryLayerIndex={this.state.activeBoundaryLayerIndex}
									/>
							}
						/>
						<Route
							path={"/about"}
							exact
							render={
								props =>
									<About {...props}/>
							}
						/>
						<Route
							render={
								props =>
									<GenericNotFound {...props}/>
							}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}
