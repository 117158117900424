import React from "react";

function iTree() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58.05"
      height="48"
      version="1.1"
      viewBox="0 0 268.191 299.06"
      role="img"
      aria-label="i-Tree logo"
    >
      <title>i-Tree logo</title>
      <desc>i-Tree logo</desc>
      <g transform="translate(-45 -681.466)">
        <g fillRule="evenodd">
          <path
            fill="#858789"
            d="M103.82 859.83h41.375v-13.875h-1l9.625-10.375 8.25 10.375h-2.125v12.75h15.375v-30.25l7.5-8.438v-9.875h15.688v11.062l7.062 7.5v27.689h13.812v-21.188h23.125v-7.938h18.562c.567 17.29-8.673 19.678.537 72.345-25.909.868-63.547 6.258-83.509-.955-13.153-4.754-20.154-9.514-52.388 1.088l-6.496-.133-19.976-9.988z"
            className="skyline"
          />
          <path
            fill="#9ece6c"
            d="M157.13 774.35c3.221.88 5.914 2.22 8.532 4.36-15.862 12.335-35.802 34.393-47.531 36.874-2.332-.64-4.714-1.943-7.375-7.03-3.08 9.725-29.74 13.997-51.737 7.536l25.633-66.468 77.413 7.365c2.536 5.594 1.764 11.437-4.934 17.363z"
            className="tree-canopy tree-canopy-inner"
          />
          <path
            fill="#005d37"
            d="M58.651 816.01c-1.17-.159-7.8-3.02-7.898-6.023.1-.952 2.201-4.746 2.842-5.491-6.518 3.467-28.127 7.127-31.721-5.922-.326-2.288-.824-13.416 4.793-17.137 1.313-.723 2.976-1.235 4.7-1.71l-3.816-3.682-3.27-1.326.353-3.27.884-2.563c-1.16-.083-1.913-.288-2.387-.707-3.795-2.612-4.602-10.337.177-13.921 1.278-.594 2.414-.626 3.58-.774-3.675-4.642-.921-6.958 4.552-8.551-5.85-10.168 16.178-15.226 19.5-16.353-16.32-21.925 40.68-38.188 62.25-14.312 8.579-1.724 20.36 2.154 23.874 17.116 3.301-1.263 10.783-.269 14.054 2.765 1.781 5.49 1.405 9.953.545 13.84 3.835 1.495 7.55 3.1 10.406 8.99-1.47 2.503-4.768 3.624-5.676 3.855.285.844.349 1.821-.452 2.37 1.24 9.103-24.29 10.232-32.312 2.626-2.435 2.616-6.4 3.862-10.096 4.68-.47-1.076-.66-2.209-.716-3.368-2.449-2.456-5.976-3.32-9.875-2.937-2.161-7.448-8.1-11.833-16.312-11.375.538.607.687 1.494.645 2.13 5.606.326 10.372 1.33 10.105 4.245.015 1.402 2.693 5.054.187 5.937.159.575.221 1.338.17 2.034 6.668 2.605 10.957 11.007 4.68 18.044 10.905 4.554 10.692 17.237-.761 21.47 0 1.033-.07 2.009-.651 2.577.538 10.685-29.523 8.226-32.331 2.36-2.64 2.851-8.235 4.735-10.022 4.383z"
            className="tree-canopy tree-canopy-outer tree-canopy-outer-0"
          />
          <path
            fill="#7290c9"
            d="M89.066 686.83c1.351 4.063 2.085 8 2.438 12.188 9.022 1.33 18.592 5.353 23.124 11 10.607-1.25 21.452 4.704 25.25 16.625 5.433-.22 12.688 2.125 14.626 5.5 1.862 2.755 2.346 10.72 1.625 13.312 9.187 4.624 16.055 16.162 7.937 27.25 12.408 6.605 17.454 24.998-2.25 34.188.028 1.583-.53 2.79-1.313 3.688 1.578 12.918-34.673 16.982-50 5.062-3.408 3.109-7.391 4.492-11.5 5.5l-.25 34.75 5.876.125h36.75l-.063-6.313h-5.75l18.375-20.312 16.25 20.438-6.313.063v4.938h7.5v-28.125l7.563-8.375v-12.188h23.438v13.5l7.25 7.437v25.562h5.75v-21.312h23.188v-7.875h22.875c.04-20.783-8.212-34.212-1.25-67.625 2.708-21.486.481-45.804 1.125-69-74.037 13.008-75.736 1.99-102.88.125-22.75-1.385-45.875.663-69.375-.125z"
            className="sky"
          />
          <path
            fill="#825227"
            d="M98.629 814.36l-14.411 4.737c1.256 14.844 1.857 29.964-.277 44.106-.557 3.698-1.06 7.385-1.125 11-1.67 16.801-9.7 21.191-25.266 26.391l66.453-.047c-10.132-3.878-20.055-7.986-21.058-21.973.13-6.334.27-12.669.753-19.004 3.58-20.163 17.119-30.434 29.812-41.229l-6.744-1.33c-13.17 5.803-20.297 13.807-26.66 20.319z"
            className="tree-trunk"
          />
          <path
            fill="#005d37"
            d="M165.69 778.71c-12.899 3.013-23.841.082-26.562-3.875-4.455 5.03-12.451 5.102-20.125 5.625 3.334 5.51 2.678 9.937-.938 13.562 11.227 5.52 10.128 17.1.063 21.562 10.378 4.154 34.856 4.467 38.434-4.062.132-.745.202-1.411.136-2.262.441-.444.737-1.101.827-1.847-.783-.69-2.489-1.348-3.46-2.391 19.335-4.005 19.833-18.838 11.626-26.312z"
            className="tree-canopy tree-canopy-outer tree-canopy-outer-1"
          />
        </g>
        <g
          fill="#333"
          colorRendering="optimizeQuality"
          shapeRendering="geometricPrecision"
          transform="matrix(1.0012 0 0 .99879 -65.434 499.47)"
        >
          <path d="M225.33 411.4v-.294q0-1.67-1.08-1.67-.49 0-.687.295-.196.295-.393.786-.098.392-.392.883-.197.49-.59.883-.686.688-6.38.688h-5.203q-2.944-.099-6.675-.099h-11.78q-2.945 0-4.123-.294-1.178-.295-1.178-1.473v-.981q0-1.08-.883-1.08-.687 0-.883.49-.197.492-.393 1.277l-3.141 12.565q-.197.589-.295 1.08-.098.49-.098.981 0 1.473.883 1.473.982 0 1.571-1.669.589-1.767 2.258-4.32 1.767-2.65 4.319-4.122 2.552-1.57 4.908-1.57h3.632q.884 0 1.08.588.196.59.196 1.57v48.1q0 1.67-.098 2.651 0 .982-.294 1.67-.295.686-.982 1.079-.687.294-1.865.589-.785.196-1.472.196-.688 0-1.277.098-.589.099-.981.393-.295.295-.295.982 0 1.57 2.16 1.57 1.08 0 3.141-.196 2.16-.294 5.3-.294 3.142 0 5.302.294 2.16.196 3.24.196 2.159 0 2.159-1.374 0-.785-.393-1.08-.294-.294-.883-.392-.59-.197-1.375-.197-.687 0-1.374-.196-2.356-.59-2.847-1.57-.392-1.08-.392-3.73v-48.788q0-.982.196-1.57.196-.59 1.08-.59h2.552q2.552 0 4.32.295 1.766.295 2.944.883 1.178.59 1.963 1.571.786.884 1.276 2.258.59 1.472.786 2.65.196 1.178.294 1.964.197.785.491 1.276.295.392.884.392t.687-.392q.196-.393.294-1.178l.786-13.547z"/>
          <path d="M229.74 432.12q0-1.08-.098-1.669 0-.687-.491-.687-.589 0-1.767 1.57-1.178 1.473-3.436 3.044-1.276.883-2.356 1.374-.981.393-.981 1.178 0 .687.589.687t1.276.098q.785.099 1.374.688.687.589.687 2.355v26.013q0 2.553-.392 3.829-.295 1.178-1.571 1.669-1.669.589-2.454.589-.687 0-.687.981 0 .687.294.884.393.098 1.178.098 1.178 0 2.749-.196 1.669-.197 3.632-.197 3.043 0 5.203.295 2.257.196 3.534.196 1.57 0 1.57-1.08 0-.687-.392-.785-.295-.196-.982-.196-.687-.099-1.669-.197t-2.258-.589q-1.472-.589-1.865-1.668-.392-1.08-.392-3.633v-16.197q0-4.122.588-7.067.59-2.945 1.571-4.81.982-1.964 2.062-2.847 1.177-.884 2.257-.884 1.178 0 1.964.59.785.588 1.472 1.374.785.687 1.472 1.276.786.589 1.767.589 1.277 0 2.258-1.178 1.08-1.276 1.08-3.24 0-1.963-1.374-3.239-1.375-1.374-3.829-1.374-4.613 0-8.736 5.595l-1.767 2.552q-.393.491-.687.491-.393 0-.393-.687v-5.595z"/>
          <path d="M264.78 444.78h-8.05q-.49 0-.785-.294-.294-.295-.294-.59 0-2.06.981-4.22.982-2.16 2.553-3.927 1.669-1.767 3.828-2.945 2.16-1.178 4.516-1.178 1.865 0 3.534.785 1.668.786 2.846 2.062 1.276 1.276 1.964 2.847.687 1.57.687 3.14 0 2.848-2.847 3.633-2.847.687-8.933.687zm15.215 2.356q1.669 0 2.258-.49.687-.492.687-1.572 0-2.944-1.08-5.693-.981-2.847-2.945-4.908-1.865-2.16-4.613-3.436-2.75-1.276-6.185-1.276-3.926 0-7.46 1.669-3.534 1.57-6.184 4.613-2.65 3.044-4.221 7.46-1.571 4.32-1.571 9.72 0 5.3 1.276 9.62 1.374 4.318 3.828 7.361 2.455 3.043 5.792 4.712 3.436 1.67 7.46 1.67 3.534 0 6.381-1.179 2.945-1.178 4.908-2.749 2.062-1.668 3.142-3.337 1.08-1.767 1.08-2.847 0-.785-.688-.785-.294 0-1.08.981-.785.982-2.16 2.16-1.373 1.178-3.533 2.16-2.061.981-5.105.981-3.337 0-6.184-1.374-2.748-1.473-4.81-4.123-1.963-2.65-3.141-6.479-1.08-3.828-1.08-8.736 0-2.454.295-3.24.294-.883 1.57-.883h23.363z"/>
          <path d="M304.09 444.78h-8.05q-.49 0-.785-.294-.294-.295-.294-.59 0-2.06.981-4.22.982-2.16 2.553-3.927 1.669-1.767 3.828-2.945 2.16-1.178 4.516-1.178 1.865 0 3.534.785 1.668.786 2.846 2.062 1.276 1.276 1.964 2.847.687 1.57.687 3.14 0 2.848-2.847 3.633-2.847.687-8.933.687zm15.215 2.356q1.669 0 2.258-.49.687-.492.687-1.572 0-2.944-1.08-5.693-.981-2.847-2.945-4.908-1.865-2.16-4.613-3.436-2.75-1.276-6.185-1.276-3.926 0-7.46 1.669-3.534 1.57-6.184 4.613-2.65 3.044-4.221 7.46-1.571 4.32-1.571 9.72 0 5.3 1.276 9.62 1.374 4.318 3.828 7.361 2.455 3.043 5.792 4.712 3.436 1.67 7.46 1.67 3.534 0 6.381-1.179 2.945-1.178 4.908-2.749 2.062-1.668 3.142-3.337 1.08-1.767 1.08-2.847 0-.785-.688-.785-.294 0-1.08.981-.785.982-2.16 2.16-1.373 1.178-3.533 2.16-2.061.981-5.105.981-3.337 0-6.184-1.374-2.748-1.473-4.81-4.123-1.963-2.65-3.141-6.479-1.08-3.828-1.08-8.736 0-2.454.295-3.24.294-.883 1.57-.883h23.363z"/>
        </g>
        <g
          fill="#333"
          colorRendering="optimizeQuality"
          shapeRendering="geometricPrecision"
          transform="matrix(.99835 0 0 1.0017 -65.434 499.47)"
        >
          <path d="M156.47 411.54q-1.373 0-2.452 1.471-.98 1.471-.98 3.433 0 1.962.98 3.335 1.079 1.373 2.452 1.373 1.471 0 2.452-1.373 1.079-1.471 1.079-3.335 0-1.962-1.079-3.433-1.079-1.471-2.452-1.471zm3.04 19.223q0-1.079-.098-1.471-.098-.49-.588-.49-.589 0-1.962 1.274-1.373 1.275-4.12 2.845-1.274 1.079-2.451 1.47-1.079.393-1.079 1.374 0 .687.49.883.49.098 1.275.098 3.04 0 3.04 3.629v26.972q0 1.961-.98 2.746-.883.785-1.962 1.079-1.078.196-2.06.392-.882.098-.882.883 0 .686.392.883.49.098 1.275.098 1.08 0 2.747-.197 1.765-.196 4.02-.196 2.845 0 4.512.196 1.766.197 2.354.197.785 0 1.08-.098.392-.099.392-.883 0-.785-.883-.883-.785-.196-1.864-.392-.98-.294-1.863-.981-.785-.785-.785-2.844v-36.583z"/>
          <path d="M170.61 448.71q-.294 0-.687.392-.294.295-.49.883l-1.079 3.629q-.098.294-.098.686 0 .49.589.49h14.516q.294 0 .686-.293.392-.393.589-.981l.98-3.63q.196-.588.196-.686 0-.49-.686-.49H170.61z"/>
        </g>
        <g transform="matrix(1.5 0 0 1.5 -128.36 -488.061)">
          <circle
            cx="265.034"
            cy="969.727"
            r="5.391"
            fill="none"
            stroke="#333"
            strokeWidth="1.218"
            className="registered-trademark"
            colorRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
          />
          <path
            fill="#333"
            stroke="#333"
            strokeWidth="0.061"
            d="M264.705 969.24c.335 0 .579-.067.725-.195.146-.122.226-.329.226-.621 0-.287-.08-.488-.226-.616-.146-.121-.39-.182-.725-.182h-.682v1.614h.682m-.682 1.115v2.375h-1.62v-6.28h2.473c.828 0 1.431.14 1.815.42.39.274.579.713.579 1.316 0 .414-.098.755-.299 1.023-.2.262-.5.463-.907.585.225.055.42.17.597.347.176.177.353.445.53.81l.883 1.78h-1.724l-.767-1.56c-.153-.31-.311-.53-.47-.64-.158-.115-.371-.176-.633-.176h-.457"
            className="registered-trademark"
            colorRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
          />
        </g>
      </g>
    </svg>
  );
}

export default iTree;
