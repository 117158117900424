import React from "react";
import {Link} from "react-router-dom";
import {
	Col,
	Container,
	Row
} from "reactstrap";
import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";
import {ActionButton} from "./Buttons";
import {compareValues} from "../../utilities/utilities";


export const ProjectList = (
	{
		projects,
		deleteProject,
		editProject,
		retrieveProject,
	}
) => {
	if (projects.length) {
		if (projects.length >= 2) {
			projects.sort(compareValues("creationDate"));
		}
		let fontAwesomeIcon = ["fas", "exclamation-triangle"];

		return (
			<Container
				className={"pt-2"}
				id={"tree-list"}
			>
				{projects.map((project) => {
					let [error, warning, reprocess] = [null, null, null];
					if (
						typeof project.error !== "undefined" &&
						project.error !== ""
					) {
						error = <FAI
							icon={fontAwesomeIcon}
							className={"me-1 text-danger"}
							title={project.error}
						/>;
					}

					if (
						typeof project.warning !== "undefined" &&
						project.warning !== ""
					) {
						warning = <FAI
							icon={fontAwesomeIcon}
							className={"me-1 text-warning"}
							title={project.warning}
						/>;
					}

					return (
						<React.Fragment key={project.uuid}>
							<Row>
								<Col>
									<div className={"d-flex flex-row"}>
										<div className={"me-2"}>
											{error}
											{warning}
											{reprocess}
										</div>
										<div className={"d-flex justify-content-start flex-column"}>
											<strong className={"d-block text-truncate"}>
												{project.note || null}
											</strong>
											<small>
												{project.tree.common}
											</small>
											<small className={"d-none d-sm-inline fst-italic"}>
												({project.tree.scientific})
											</small>
										</div>
									</div>
								</Col>
								<Col>
									<div className={"d-flex justify-content-end mt-1"}>
										<Link to={`/benefits/individual`}>
											<ActionButton
												classNames={"ms-1 round"}
												id={`view-${project.uuid}`}
												icon={"calculator"}
												color={"primary"}
												uuid={project.uuid}
												onClick={retrieveProject}
												onContextMenu={retrieveProject}
												title={"Calculate this tree's benefits."}
											/>
										</Link>
										<ActionButton
											id={`edit-${project.uuid}`}
											icon={"pen"}
											classNames={"ms-1 ms-sm-2 round"}
											color={"success"}
											uuid={project.uuid}
											onClick={editProject}
											title={"Edit this tree's information."}
										/>
										<ActionButton
											id={`delete-${project.uuid}`}
											icon={"trash"}
											classNames={"ms-1 ms-sm-2 round"}
											color={"danger"}
											outline={true}
											uuid={project.uuid}
											onClick={deleteProject}
											title={"Remove this tree."}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<hr/>
								</Col>
							</Row>
						</React.Fragment>
					)
				})}
			</Container>
		)
	} else {
		return (
			<React.Fragment>
				<div className={"text-center"}>
					<h3>Add a tree first to get started!</h3>
				</div>
			</React.Fragment>
		)
	}
}
