import itr from "itr-engine-js";

import {inRange} from "../utilities/utilities";


export class Benefits {
	constructor(
		{
			calculated = false,
			error = "",
			warning = "",
			meta = null,
			hydro = new HydroBenefit(),
			energy = new EnergyBenefit(),
			air = new AirQualityBenefit(),
			co2 = new CO2Benefit(),
			carbon = new Carbon(),
		} = {}
	) {
		this.calculated = calculated;
		this.error = error;
		this.warning = warning;
		this.meta = meta;

		this.hydro = new HydroBenefit(hydro);
		this.energy = new EnergyBenefit(energy);
		this.air = new AirQualityBenefit(air);
		this.co2 = new CO2Benefit(co2);
		if (carbon instanceof Carbon) {
			this.carbon = carbon;
		} else {
			this.carbon = new Carbon(carbon);
		}
	}

	totalDollarValue = (energy = true) => {
		let total = 0;
		total += inRange(this.hydro.totalDollarValue());
		total += inRange(this.air.totalRemovedDollarValue());
		total += inRange(this.co2.totalDollarValue());

		if (energy) {
			total += inRange(this.energy.totalDollarValue());
			total += inRange(this.air.totalAvoidedDollarValue());
			total += inRange(this.co2.CO2AvoidedValue);
		}

		return total.toFixed(2);
	};

	__fields = () => {
		return {
			calculated: this.calculated,
			error: this.error,
			warning: this.warning,
			meta: this.meta,
			hydro: this.hydro.__fields(),
			energy: this.energy.__fields(),
			air: this.air.__fields(),
			co2: this.co2.__fields(),
			carbon: this.carbon.__fields()
		};
	};

	static getBenefits = async (project, options = {}) => {
		let {
			timeline = "forwards",
			years = 1,
			report = "annual",
			benefitType = "annual"
		} = {...options};
		let {location, tree, building, group} = project;
		let itrLocation = new itr.Location({
			longitude: location.longitude,
			latitude: location.latitude,
		});

		// Taking default Project attributes, except for location, building,
		// and trees. Taking location and building from Project as defaults
		// for Tree.
		// TODO: how should API key usage be handled?
		//itr.key = process.env.REACT_APP_ITREE_ENGINE_API;
		//itr.Project.key = process.env.REACT_APP_ITREE_ENGINE_API;
		//itr.Project.prototype.key = process.env.REACT_APP_ITREE_ENGINE_API;
		let _project = new itr.Project({
			//key: process.env.REACT_APP_ITREE_ENGINE_API,
			// FIXME: when the 'makeShallow' in itr is replaced with JSON
			//   requests to the Engine, then location and building will only
			//   need to be given once.
			timeline: timeline,
			years: years,
			report: report,
			location: itrLocation,
			building: new itr.Building({
				vintage: building.proximity === 0 ? building.vintage : "pre-1950",
				heated: 1,
				cooled: 1,
				direction: building.proximity === 0 ? building.direction : 0,
				distance: building.proximity === 0 ? building.distance : 3,
			}),
			trees: [
				new itr.Tree({
					groupName: group,
					plantingType: tree.type,
					species: tree.species,
					diameter: tree.diameter * 2.54, // TODO: use centimeters.
					condition: tree.getVerboseCondition(),
					crownExposure: tree.getExposureValue(),
					trillionTrees: tree.trillionTrees === 0,
					hthc: project.hthc.optIn ? project.hthc.__fields() : null,
					// location: new itr.Location({
					// 	nation: nation,
					// 	state: location.stateAbbr,
					// 	county: location.county,
					// 	city: location.city,
					// 	//longitude: location.longitude,
					// 	//latitude: location.latitude,
					// }),
					// building: new itr.Building({
					// 	vintage: building.proximity === 0 ?
					// 		building.vintage : "pre-1950",
					// 	heated: 1,
					// 	cooled: 1,
					// 	direction: building.proximity === 0 ? building.direction : 0,
					// 	distance: building.proximity === 0 ? building.distance : 3,
					// }),
				}),
			],
		});
		_project.key = process.env.REACT_APP_ITREE_ENGINE_API;
		_project.url = process.env.REACT_APP_ITREE_ENGINE_URL;

		let timeout = 1000 * 60 * 5; // 5 minutes

		return await _project.process({
			timeout: timeout,
			benefitType: benefitType
		});
	};

	assignBenefits = (benefits) => {
		this.hydro = HydroBenefit.fromItr(benefits.hydrology);
		this.energy = EnergyBenefit.fromItr(benefits.energy);
		this.air = AirQualityBenefit.fromItr(benefits.air);
		this.co2 = CO2Benefit.fromItr(
			benefits.carbon.carbonDioxide.sequestration,
			benefits.carbon.sequestrationWorth,
			benefits.air.pollutionAvoided,
		);
		this.carbon = Carbon.fromItr(benefits.carbon);

		this.calculated = true;
	};

	handleError = (error) => {
		this.error = `Encountered the following error: "${error}".
		Please, try a different address and/or species.
		If the problem persists, please report it.`;

		this.hydro = new HydroBenefit();
		this.energy = new EnergyBenefit();
		this.air = new AirQualityBenefit();
		this.co2 = new CO2Benefit();
		this.carbon = new Carbon();

		// Calculated is set to False here in case benefits
		// fetching fails after previous successes.
		this.calculated = false;
	};
}


export class HydroBenefit {
	constructor(
		{
			RunoffAvoided = 0,
			RunoffAvoidedValue = 0,
			Interception = 0,
			PotentialEvaporation = 0,
			PotentialEvapotranspiration = 0,
			Evaporation = 0,
			Transpiration = 0,
		} = {}
	) {
		this.RunoffAvoided = RunoffAvoided;
		this.RunoffAvoidedValue = RunoffAvoidedValue;
		this.Interception = Interception;
		this.PotentialEvaporation = PotentialEvaporation;
		this.PotentialEvapotranspiration = PotentialEvapotranspiration;
		this.Evaporation = Evaporation;
		this.Transpiration = Transpiration;
	}

	toJSON = (key) => {
		return this;
	};

	totalDollarValue = () => {
		return inRange(
			parseFloat(
				this.RunoffAvoidedValue.toFixed(2)
			)
		);
	};

	__fields = () => {
		return JSON.parse(JSON.stringify(this));
	};

	// Factory as adaptor to itr-engine-js classes.
	static fromItr = (v3) => {
		return new HydroBenefit({
			RunoffAvoided: v3.runoffAvoided,
			RunoffAvoidedValue: v3.runoffAvoidedWorth,
			Interception: v3.interception,
			PotentialEvaporation: v3.potentialEvaporation,
			PotentialEvapotranspiration: v3.potentialEvapotranspiration,
			Evaporation: v3.evaporation,
			Transpiration: v3.transpiration,
		});
	};
}


export class EnergyBenefit {
	constructor(
		{
			MbtuHeatShade = 0,
			MbtuHeatShadeValue = 0,
			MbtuHeatClimate = 0,
			MbtuHeatClimateValue = 0,
			MbtuWindHeat = 0,
			MbtuWindHeatValue = 0,
			KWhElectricityHeatShade = 0,
			KWhElectricityHeatShadeValue = 0,
			KWhElectricityHeatClimate = 0,
			KWhElectricityHeatClimateValue = 0,
			KWhElectricityHeatWind = 0,
			KWhElectricityHeatWindValue = 0,
			KWhElectricityCoolShade = 0,
			KWhElectricityCoolShadeValue = 0,
			KWhElectricityCoolClimate = 0,
			KWhElectricityCoolClimateValue = 0,
			KWhPrice = 0,
			ThermPrice = 0,
		} = {}
	) {
		this.MbtuHeatShade = MbtuHeatShade;
		this.MbtuHeatShadeValue = MbtuHeatShadeValue;
		this.MbtuHeatClimate = MbtuHeatClimate;
		this.MbtuHeatClimateValue = MbtuHeatClimateValue;
		this.MbtuWindHeat = MbtuWindHeat;
		this.MbtuWindHeatValue = MbtuWindHeatValue;
		this.KWhElectricityHeatShade = KWhElectricityHeatShade;
		this.KWhElectricityHeatShadeValue = KWhElectricityHeatShadeValue;
		this.KWhElectricityHeatClimate = KWhElectricityHeatClimate;
		this.KWhElectricityHeatClimateValue = KWhElectricityHeatClimateValue;
		this.KWhElectricityHeatWind = KWhElectricityHeatWind;
		this.KWhElectricityHeatWindValue = KWhElectricityHeatWindValue;
		this.KWhElectricityCoolShade = KWhElectricityCoolShade;
		this.KWhElectricityCoolShadeValue = KWhElectricityCoolShadeValue;
		this.KWhElectricityCoolClimate = KWhElectricityCoolClimate;
		this.KWhElectricityCoolClimateValue = KWhElectricityCoolClimateValue;
		this.KWhPrice = KWhPrice;
		this.ThermPrice = ThermPrice;
	}

	toJSON = (key) => {
		return this;
	};

	totalDollarValue = () => {
		let t = this.transformEnergyBenefits();
		return inRange(
			t.ElectricityDollarValue.value + t.NaturalGasDollarValue.value
		);
	};

	__fields = () => {
		return JSON.parse(JSON.stringify(this));
	};

	// Factory as adaptor to itr-engine-js classes.
	static fromItr = (v3) => {
		return new EnergyBenefit({
			MbtuHeatShade: v3.naturalGas.heat.shade,
			MbtuHeatShadeValue: v3.naturalGas.heat["shade-worth"],
			MbtuHeatClimate: v3.naturalGas.heat.climate,
			MbtuHeatClimateValue: v3.naturalGas.heat["climate-worth"],
			MbtuWindHeat: v3.naturalGas.heat.wind,
			MbtuWindHeatValue: v3.naturalGas.heat["wind-worth"],

			KWhElectricityHeatShade: v3.electric.heat.shade,
			KWhElectricityHeatShadeValue: v3.electric.heat["shade-worth"],
			KWhElectricityHeatClimate: v3.electric.heat.climate,
			KWhElectricityHeatClimateValue: v3.electric.heat["climate-worth"],
			KWhElectricityHeatWind: v3.electric.heat.wind,
			KWhElectricityHeatWindValue: v3.electric.heat["wind-worth"],

			KWhElectricityCoolShade: v3.electric.cool.shade,
			KWhElectricityCoolShadeValue: v3.electric.cool["shade-worth"],
			KWhElectricityCoolClimate: v3.electric.cool.climate,
			KWhElectricityCoolClimateValue: v3.electric.cool["climate-worth"],

			// TODO: verify.
			// XXX: these are likely to be refactored in the library during beta.
			KWhPrice: v3.priceKwh,
			ThermPrice: v3.priceTherm,
			//MbtuPrice: v3.priceMbtu,
			//KWhPrice: v3.electric.price,
			//ThermPrice: v3.naturalGas.price,
			//MbtuPrice: v3.naturalGas.price,
		});
	};

	transformEnergyBenefits = () => {
		let benefits = {
			ElectricityDollarValue: {
				keys: [
					"KWhElectricityHeatShadeValue",
					"KWhElectricityHeatClimateValue",
					"KWhElectricityHeatWindValue",
					"KWhElectricityCoolShadeValue",
					"KWhElectricityCoolClimateValue"
				],
				value: 0
			},
			ElectricitykWhSaved: {
				keys: [
					"KWhElectricityHeatShade",
					"KWhElectricityHeatClimate",
					"KWhElectricityHeatWind",
					"KWhElectricityCoolShade",
					"KWhElectricityCoolClimate"
				],
				value: 0
			},
			NaturalGasDollarValue: {
				keys: [
					"MbtuHeatShadeValue",
					"MbtuHeatClimateValue",
					"MbtuWindHeatValue"
				],
				value: 0
			},
			NaturalGasThermsSaved: {
				keys: [
					"MbtuHeatShade",
					"MbtuHeatClimate",
					"MbtuWindHeat"
				],
				value: 0
			}
		};

		for (let item in benefits) {
			benefits[item]["keys"].forEach((value, i) => {
				benefits[item]["value"] += parseFloat(this[value]);
			});
		}

		return benefits;
	}

}


export class AirQualityBenefit {
	constructor(
		{
			COAvoided = 0,
			COAvoidedValue = 0,
			CORemoved = 0,
			CORemovedValue = 0,
			NO2Avoided = 0,
			NO2AvoidedValue = 0,
			NO2Removed = 0,
			NO2RemovedValue = 0,
			SO2Avoided = 0,
			SO2AvoidedValue = 0,
			SO2Removed = 0,
			SO2RemovedValue = 0,
			O3Avoided = 0,
			O3AvoidedValue = 0,
			O3Removed = 0,
			O3RemovedValue = 0,
			PM25Avoided = 0,
			PM25AvoidedValue = 0,
			PM25Removed = 0,
			PM25RemovedValue = 0,
			VOCAvoided = 0,
			VOCAvoidedValue = 0,
			VOCRemoved = 0,
			VOCRemovedValue = 0,
		} = {}
	) {
		this.COAvoided = COAvoided;
		this.COAvoidedValue = COAvoidedValue;
		this.CORemoved = CORemoved;
		this.CORemovedValue = CORemovedValue;
		this.NO2Avoided = NO2Avoided;
		this.NO2AvoidedValue = NO2AvoidedValue;
		this.NO2Removed = NO2Removed;
		this.NO2RemovedValue = NO2RemovedValue;
		this.SO2Avoided = SO2Avoided;
		this.SO2AvoidedValue = SO2AvoidedValue;
		this.SO2Removed = SO2Removed;
		this.SO2RemovedValue = SO2RemovedValue;
		this.O3Avoided = O3Avoided;
		this.O3AvoidedValue = O3AvoidedValue;
		this.O3Removed = O3Removed;
		this.O3RemovedValue = O3RemovedValue;
		this.PM25Avoided = PM25Avoided;
		this.PM25AvoidedValue = PM25AvoidedValue;
		this.PM25Removed = PM25Removed;
		this.PM25RemovedValue = PM25RemovedValue;
		this.VOCAvoided = VOCAvoided;
		this.VOCAvoidedValue = VOCAvoidedValue;
		this.VOCRemoved = VOCRemoved;
		this.VOCRemovedValue = VOCRemovedValue;
	}

	toJSON = (key) => {
		return this;
	};

	totalAvoidedDollarValue = () => {
		let total = 0;
		total += parseFloat(this.COAvoidedValue.toFixed(2));
		total += parseFloat(this.NO2AvoidedValue.toFixed(2));
		total += parseFloat(this.SO2AvoidedValue.toFixed(2));
		total += parseFloat(this.O3AvoidedValue.toFixed(2));
		total += parseFloat(this.PM25AvoidedValue.toFixed(2));
		return inRange(total);
	};

	totalRemovedDollarValue = () => {
		let total = 0;
		total += parseFloat(this.CORemovedValue.toFixed(2));
		total += parseFloat(this.NO2RemovedValue.toFixed(2));
		total += parseFloat(this.SO2RemovedValue.toFixed(2));
		total += parseFloat(this.O3RemovedValue.toFixed(2));
		total += parseFloat(this.PM25RemovedValue.toFixed(2));
		return inRange(total);
	};

	__fields = () => {
		return JSON.parse(JSON.stringify(this));
	};

	// Factory as adaptor to itr-engine-js classes.
	static fromItr = (v3) => {
		// XXX: not all pollutants apply to both "avoided" and "removed".
		//   Those that do not may be deprecated fields in the future, instead
		//   of always being 0.
		return new AirQualityBenefit({
			COAvoided: v3.pollutionAvoided.co,
			COAvoidedValue: v3.pollutionAvoided.coWorth,
			NO2Avoided: v3.pollutionAvoided.no2,
			NO2AvoidedValue: v3.pollutionAvoided.no2Worth,
			SO2Avoided: v3.pollutionAvoided.so2,
			SO2AvoidedValue: v3.pollutionAvoided.so2Worth,
			O3Avoided: v3.pollutionAvoided.o3,
			O3AvoidedValue: v3.pollutionAvoided.o3Worth,
			PM25Avoided: v3.pollutionAvoided.pm25,
			PM25AvoidedValue: v3.pollutionAvoided.pm25Worth,
			VOCAvoided: v3.pollutionAvoided.voc,
			VOCAvoidedValue: v3.pollutionAvoided.vocWorth,

			CORemoved: v3.pollutionRemoved.co,
			CORemovedValue: v3.pollutionRemoved.coWorth,
			NO2Removed: v3.pollutionRemoved.no2,
			NO2RemovedValue: v3.pollutionRemoved.no2Worth,
			SO2Removed: v3.pollutionRemoved.so2,
			SO2RemovedValue: v3.pollutionRemoved.so2Worth,
			O3Removed: v3.pollutionRemoved.o3,
			O3RemovedValue: v3.pollutionRemoved.o3Worth,
			PM25Removed: v3.pollutionRemoved.pm25,
			PM25RemovedValue: v3.pollutionRemoved.pm25Worth,
			VOCRemoved: v3.pollutionRemoved.voc,
			VOCRemovedValue: v3.pollutionRemoved.vocWorth,
		});
	};
}


export class CO2Benefit {
	constructor(
		{
			CO2Sequestered = 0,
			CO2SequesteredValue = 0,
			CO2Avoided = 0,
			CO2AvoidedValue = 0,
		} = {}
	) {
		this.CO2Sequestered = CO2Sequestered;
		this.CO2SequesteredValue = CO2SequesteredValue;
		this.CO2Avoided = CO2Avoided;
		this.CO2AvoidedValue = CO2AvoidedValue;
	}

	toJSON = (key) => {
		return this;
	};

	totalDollarValue = () => {
		return inRange(
			parseFloat(
				this.CO2SequesteredValue.toFixed(2)
			)
		);
	};

	__fields = () => {
		return JSON.parse(JSON.stringify(this));
	};

	// Factory as adaptor to itr-engine-js classes.
	static fromItr = (v3Sequestration, v3SequestrationWorth, v3PollutionAvoided) => {
		return new CO2Benefit({
			CO2Sequestered: v3Sequestration,
			CO2SequesteredValue: v3SequestrationWorth,
			CO2Avoided: v3PollutionAvoided.co2,
			CO2AvoidedValue: v3PollutionAvoided.co2Worth,
		});
	};
}


export class Carbon {
	constructor(
		{
			CarbonStorage = 0,
			CarbonSequestration = 0,
			CarbonDioxideStorage = 0,
			CarbonDioxideStorageValue = 0,
			DryWeight = 0,
		} = {}
	) {
		this.CarbonStorage = CarbonStorage;
		this.CarbonSequestration = CarbonSequestration;
		this.CarbonDioxideStorage = CarbonDioxideStorage;
		this.CarbonDioxideStorageValue = CarbonDioxideStorageValue;
		this.DryWeight = DryWeight;
	}

	toJSON = (key) => {
		return this;
	};

	totalDollarValue = () => {
		return inRange(
			parseFloat(
				this.CarbonDioxideStorageValue.toFixed(2)
			)
		);
	};

	__fields = () => {
		return JSON.parse(JSON.stringify(this));
	};

	// Factory as adaptor to itr-engine-js classes.
	static fromItr = (v3) => {
		return new Carbon({
			CarbonStorage: v3.storage,
			CarbonSequestration: v3.sequestration,
			CarbonDioxideStorage: v3.carbonDioxide.storage,
			CarbonDioxideStorageValue: v3.storageWorthLifetime,
			DryWeight: v3.biomass,
		});
	};
}

