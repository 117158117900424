import React, {PureComponent} from 'react';

import {NavbarBrand} from "reactstrap";

import {URLS} from "../../../static_props/MyTree";

import "../../../../stylesheets/navbar.scss"

import iTree from "../../../../img/itree_with_r.jsx";


export class Brand extends PureComponent {
	render() {
		return (
			<NavbarBrand href={URLS.home} className={"flex-grow-1 flex-sm-grow-0"}>
				<span>
					{iTree()}
				</span>
				<div className={"app-name-container"}>
					<div className={this.props.appNameClasses}>
						{this.props.app}
					</div>
					<div className={`${this.props.taglineClasses}`}>
						{this.props.tagline}
					</div>
				</div>
			</NavbarBrand>
		)
	}
}
