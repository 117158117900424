import Papa from "papaparse";


export const csvExport = (fields, filename) => {
	let csv = Papa.unparse(fields);
	let blob = new Blob([csv]);

	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, filename)
	} else {
		let a = document.createElement("a");
		let url = URL.createObjectURL(blob);

		a.setAttribute("href", url);
		a.setAttribute("download", filename);

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
};