export const MYTREE = process.env.REACT_APP_ID === "mytree";
export const OURTREES = process.env.REACT_APP_ID === "ourtrees";
export const DEVMODE = process.env.REACT_APP_BUILD_MODE === "development";
export const BING_API_KEY = process.env.REACT_APP_BING_API;
export const TABS = [
	{text: "Now", color: "primary"},
	{text: "20 Years", color: "primary"},
];
export const CONDITIONS = [
	"Excellent",
	"Good",
	"Fair",
	"Poor",
	"Critical",
	"Dying",
	"Dead",
];
export const EXPOSURES = [
	["5", "Full Sun"],
	["3", "Partial Sun"],
	["0", "Full Shade"],
];
export const DISTANCES = [
	[-1, "Select a Distance", "Select a Distance"],
	["3", `0\u201319 feet`, `0\u20136 meters`],
	["9", `20\u201339 feet`, `6\u201312 meters`],
	["15", `40\u201359 feet`, `12\u201318 meters`],
];
export const DIRECTIONS = [
	[-1, "Select a Direction"],
	["180", `North (0\xb0)`],
	["225", `Northeast (45\xb0)`],
	["270", `East (90\xb0)`],
	["315", `Southeast (135\xb0)`],
	["0", `South (180\xb0)`],
	["45", `Southwest (225\xb0)`],
	["90", `West (270\xb0)`],
	["135", `Northwest (315\xb0)`],
];
export const VINTAGES = [
	[-1, "Select Building Vintage"],
	["post-1980", "Built After 1980"],
	["1950-1980", "Built between 1950 and 1980"],
	["pre-1950", "Built Before 1950"],
];

export const LANGUAGES_AND_CURRENCY_CODES = {
	"prs-AF": "AFN",
	"ps-AF": "AFN",
	"fa-AF": "AFN",
	"uz-Arab-AF": "AFN",
	"sq-AL": "ALL",
	"ar-DZ": "DZD",
	"fr-DZ": "DZD",
	"kab-DZ": "DZD",
	"tzm-Latn-DZ": "DZD",
	"en-AS": "USD",
	"ca-AD": "EUR",
	"ln-AO": "AOA",
	"pt-AO": "AOA",
	"en-AI": "XCD",
	"en-AG": "XCD",
	"es-AR": "ARS",
	"hy-AM": "AMD",
	"nl-AW": "AWG",
	"en-AU": "AUD",
	"en-AT": "EUR",
	"de-AT": "EUR",
	"az-Cyrl-AZ": "AZN",
	"az-Latn-AZ": "AZN",
	"en-BS": "BSD",
	"ar-BH": "BHD",
	"bn-BD": "BDT",
	"en-BB": "BBD",
	"be-BY": "BYN",
	"ru-BY": "BYN",
	"nl-BE": "EUR",
	"en-BE": "EUR",
	"fr-BE": "EUR",
	"de-BE": "EUR",
	"en-BZ": "BZD",
	"es-BZ": "BZD",
	"fr-BJ": "XOF",
	"yo-BJ": "XOF",
	"en-BM": "BMD",
	"dz-BT": "INR",
	"es-VE": "VEF",
	"quz-BO": "BOB",
	"es-BO": "BOB",
	"nl-BQ": "USD",
	"bs-Cyrl-BA": "BAM",
	"bs-Latn-BA": "BAM",
	"hr-BA": "BAM",
	"sr-Cyrl-BA": "BAM",
	"sr-Latn-BA": "BAM",
	"en-BW": "BWP",
	"tn-BW": "BWP",
	"pt-BR": "BRL",
	"es-BR": "BRL",
	"en-IO": "USD",
	"en-VG": "USD",
	"ms-BN": "BND",
	"bg-BG": "BGN",
	"fr-BF": "XOF",
	"ff-Latn-BF": "XOF",
	"en-BI": "BIF",
	"fr-BI": "BIF",
	"rn-BI": "BIF",
	"kea-CV": "CVE",
	"pt-CV": "CVE",
	"km-KH": "KHR",
	"agq-CM": "XAF",
	"ksf-CM": "XAF",
	"bas-CM": "XAF",
	"dua-CM": "XAF",
	"en-CM": "XAF",
	"ewo-CM": "XAF",
	"fr-CM": "XAF",
	"ff-CM": "XAF",
	"ff-Latn-CM": "XAF",
	"kkj-CM": "XAF",
	"nmg-CM": "XAF",
	"mgo-CM": "XAF",
	"mua-CM": "XAF",
	"nnh-CM": "XAF",
	"jgo-CM": "XAF",
	"yav-CM": "XAF",
	"en-CA": "CAD",
	"fr-CA": "CAD",
	"iu-Latn-CA": "CAD",
	"iu-Cans-CA": "CAD",
	"moh-CA": "CAD",
	"en-KY": "KYD",
	"fr-CF": "XAF",
	"ln-CF": "XAF",
	"sg-CF": "XAF",
	"ar-TD": "XAF",
	"fr-TD": "XAF",
	"arn-CL": "CLP",
	"es-CL": "CLP",
	"en-CX": "AUD",
	"en-CC": "AUD",
	"es-CO": "COP",
	"ar-KM": "KMF",
	"fr-KM": "KMF",
	"swc-CD": "CDF",
	"ln-CD": "CDF",
	"lu-CD": "CDF",
	"fr-CD": "CDF",
	"fr-CG": "XAF",
	"ln-CG": "XAF",
	"en-CK": "NZD",
	"es-CR": "CRC",
	"hr-HR": "HRK",
	"es-CU": "CUP",
	"nl-CW": "ANG",
	"en-CY": "EUR",
	"el-CY": "EUR",
	"tr-CY": "EUR",
	"cs-CZ": "CZK",
	"fr-CI": "XOF",
	"da-DK": "DKK",
	"en-DK": "DKK",
	"fo-DK": "DKK",
	"aa-DJ": "DJF",
	"ar-DJ": "DJF",
	"fr-DJ": "DJF",
	"so-DJ": "DJF",
	"en-DM": "XCD",
	"es-DO": "DOP",
	"quz-EC": "USD",
	"es-EC": "USD",
	"ar-EG": "EGP",
	"es-SV": "USD",
	"fr-GQ": "XAF",
	"pt-GQ": "XAF",
	"es-GQ": "XAF",
	"aa-ER": "ERN",
	"ar-ER": "ERN",
	"byn-ER": "ERN",
	"en-ER": "ERN",
	"ssy-ER": "ERN",
	"tig-ER": "ERN",
	"ti-ER": "ERN",
	"et-EE": "EUR",
	"aa-ET": "ETB",
	"am-ET": "ETB",
	"om-ET": "ETB",
	"so-ET": "ETB",
	"ti-ET": "ETB",
	"wal-ET": "ETB",
	"en-FK": "FKP",
	"fo-FO": "DKK",
	"en-FJ": "FJD",
	"en-FI": "EUR",
	"fi-FI": "EUR",
	"smn-FI": "EUR",
	"se-FI": "EUR",
	"sms-FI": "EUR",
	"sv-FI": "EUR",
	"gsw-FR": "EUR",
	"br-FR": "EUR",
	"ca-FR": "EUR",
	"co-FR": "EUR",
	"fr-FR": "EUR",
	"ia-FR": "EUR",
	"oc-FR": "EUR",
	"fr-GF": "EUR",
	"fr-PF": "XPF",
	"fr-GA": "XAF",
	"en-GM": "GMD",
	"ff-Latn-GM": "GMD",
	"ka-GE": "GEL",
	"os-GE": "GEL",
	"en-DE": "EUR",
	"de-DE": "EUR",
	"nds-DE": "EUR",
	"dsb-DE": "EUR",
	"ksh-DE": "EUR",
	"hsb-DE": "EUR",
	"ak-GH": "GHS",
	"en-GH": "GHS",
	"ee-GH": "GHS",
	"ff-Latn-GH": "GHS",
	"ha-Latn-GH": "GHS",
	"en-GI": "GIP",
	"el-GR": "EUR",
	"da-GL": "DKK",
	"kl-GL": "DKK",
	"en-GD": "XCD",
	"fr-GP": "EUR",
	"en-GU": "USD",
	"quc-Latn-GT": "GTQ",
	"es-GT": "GTQ",
	"en-GG": "GBP",
	"fr-GN": "GNF",
	"ff-GN": "GNF",
	"ff-Latn-GN": "GNF",
	"nqo-GN": "GNF",
	"ff-Latn-GW": "XOF",
	"pt-GW": "XOF",
	"en-GY": "GYD",
	"fr-HT": "USD",
	"es-HN": "HNL",
	"zh-HK": "HKD",
	"en-HK": "HKD",
	"hu-HU": "HUF",
	"is-IS": "ISK",
	"as-IN": "INR",
	"bn-IN": "INR",
	"brx-IN": "INR",
	"ccp-Cakm": "INR",
	"en-IN": "INR",
	"gu-IN": "INR",
	"hi-IN": "INR",
	"kn-IN": "INR",
	"kok-IN": "INR",
	"ml-IN": "INR",
	"mr-IN": "INR",
	"ne-IN": "INR",
	"or-IN": "INR",
	"pa-IN": "INR",
	"sa-IN": "INR",
	"ta-IN": "INR",
	"te-IN": "INR",
	"bo-IN": "INR",
	"ur-IN": "INR",
	"id-ID": "IDR",
	"jv-Latn-ID": "IDR",
	"mzn-IR": "IRR",
	"lrc-IR": "IRR",
	"fa-IR": "IRR",
	"ku-Arab-IR": "IRR",
	"ar-IQ": "IQD",
	"ku-Arab-IQ": "IQD",
	"lrc-IQ": "IQD",
	"en-IE": "EUR",
	"ga-IE": "EUR",
	"pa-Arab-PK": "PKR",
	"sd-Arab-PK": "PKR",
	"ur-PK": "PKR",
	"en-PK": "PKR",
	"ps-PK": "PKR",
	"en-IM": "GBP",
	"gv-IM": "GBP",
	"ar-IL": "ILS",
	"en-IL": "ILS",
	"he-IL": "ILS",
	"ca-IT": "EUR",
	"fur-IT": "EUR",
	"de-IT": "EUR",
	"it-IT": "EUR",
	"en-JM": "JMD",
	"ja-JP": "JPY",
	"en-JE": "GBP",
	"ar-JO": "JOD",
	"kk-KZ": "KZT",
	"ru-KZ": "KZT",
	"ebu-KE": "KES",
	"en-KE": "KES",
	"guz-KE": "KES",
	"kln-KE": "KES",
	"kam-KE": "KES",
	"ki-KE": "KES",
	"sw-KE": "KES",
	"luo-KE": "KES",
	"luy-KE": "KES",
	"mas-KE": "KES",
	"mer-KE": "KES",
	"om-KE": "KES",
	"saq-KE": "KES",
	"so-KE": "KES",
	"dav-KE": "KES",
	"teo-KE": "KES",
	"en-KI": "AUD",
	"ko-KR": "KRW",
	"ar-KW": "KWD",
	"ky-KG": "KGS",
	"ru-KG": "KGS",
	"lo-LA": "LAK",
	"lv-LV": "EUR",
	"ar-LB": "LBP",
	"en-LS": "ZAR",
	"st-LS": "ZAR",
	"en-LR": "LRD",
	"ff-Latn-LR": "LRD",
	"vai-Vaii-LR": "LRD",
	"vai-Latn-LR": "LRD",
	"ar-LY": "LYD",
	"gsw-LI": "CHF",
	"de-LI": "CHF",
	"lt-LT": "EUR",
	"fr-LU": "EUR",
	"de-LU": "EUR",
	"lb-LU": "EUR",
	"pt-LU": "EUR",
	"en-MO": "MOP",
	"pt-MO": "MOP",
	"en-MG": "MGA",
	"fr-MG": "MGA",
	"mg-MG": "MGA",
	"en-MW": "MWK",
	"en-MY": "MYR",
	"ms-MY": "MYR",
	"ta-MY": "MYR",
	"dv-MV": "MVR",
	"bm-Latn-ML": "XOF",
	"fr-ML": "XOF",
	"khq-ML": "XOF",
	"ses-ML": "XOF",
	"en-MT": "EUR",
	"mt-MT": "EUR",
	"en-MH": "USD",
	"fr-MQ": "EUR",
	"ar-MR": "MRU",
	"fr-MR": "MRU",
	"ff-MR": "MRU",
	"ff-Latn-MR": "MRU",
	"en-MU": "MUR",
	"fr-MU": "MUR",
	"mfe-MU": "MUR",
	"fr-YT": "EUR",
	"es-MX": "MXN",
	"en-FM": "USD",
	"ro-MD": "MDL",
	"ru-MD": "MDL",
	"mn-MN": "MNT",
	"mn-Mong-MN": "MNT",
	"sr-Cyrl-ME": "EUR",
	"sr-Latn-ME": "EUR",
	"en-MS": "XCD",
	"ar-MA": "MAD",
	"tzm-Latn": "MAD",
	"fr-MA": "MAD",
	"zgh-Tfng-MA": "MAD",
	"shi-Latn-MA": "MAD",
	"shi-Tfng-MA": "MAD",
	"mgh-MZ": "MZN",
	"pt-MZ": "MZN",
	"seh-MZ": "MZN",
	"my-MM": "MMK",
	"af-NA": "ZAR",
	"en-NA": "ZAR",
	"naq-NA": "ZAR",
	"en-NR": "AUD",
	"ne-NP": "NPR",
	"nl-NL": "EUR",
	"en-NL": "EUR",
	"fy-NL": "EUR",
	"nds-NL": "EUR",
	"fr-NC": "XPF",
	"en-NZ": "NZD",
	"mi-NZ": "NZD",
	"es-NI": "NIO",
	"fr-NE": "XOF",
	"ff-Latn-NE": "XOF",
	"ha-Latn-NE": "XOF",
	"twq-NE": "XOF",
	"dje-NE": "XOF",
	"en-NG": "NGN",
	"ff-NG": "NGN",
	"ff-Latn-NG": "NGN",
	"ha-Latn-NG": "NGN",
	"ig-NG": "NGN",
	"yo-NG": "NGN",
	"en-NU": "NZD",
	"en-NF": "AUD",
	"ko-KP": "KPW",
	"sq-MK": "MKD",
	"mk-MK": "MKD",
	"en-MP": "USD",
	"nb-NO": "NOK",
	"nn-NO": "NOK",
	"smj-NO": "NOK",
	"se-NO": "NOK",
	"sma-NO": "NOK",
	"ar-OM": "OMR",
	"en-PW": "USD",
	"ar-PS": "ILS",
	"es-PA": "USD",
	"en-PG": "PGK",
	"gn-PY": "PYG",
	"es-PY": "PYG",
	"zh-CN": "CNY",
	"mn-Mong-CN": "CNY",
	"bo-CN": "CNY",
	"ug-CN": "CNY",
	"ii-CN": "CNY",
	"zh-MO": "CNY",
	"ks-Arab": "INR", // FIXME: find a currency code.
	"ks-Arab-IN": "INR", // FIXME: find a currency code.
	"uz-Arab": "UZS", // FIXME: find a currency code.
	"quz-PE": "PEN",
	"es-PE": "PEN",
	"en-PN": "NZD",
	"pl-PL": "PLN",
	"pt-PT": "EUR",
	"fr-MC": "EUR",
	"en-PR": "USD",
	"es-PR": "USD",
	"ar-QA": "QAR",
	"en-PH": "PHP",
	"ceb-Latn-PH": "PHP",
	"fil-PH": "PHP",
	"es-PH": "PHP",
	"fr-RE": "EUR",
	"ro-RO": "RON",
	"ba-RU": "RUB",
	"cd-RU": "RUB",
	"cu-RU": "RUB",
	"ru-RU": "RUB",
	"sah-RU": "RUB",
	"tt-RU": "RUB",
	"os-RU": "RUB",
	"en-RW": "RWF",
	"fr-RW": "RWF",
	"rw-RW": "RWF",
	"fr-BL": "EUR",
	"en-SH": "SHP",
	"en-KN": "XCD",
	"en-LC": "XCD",
	"fr-MF": "EUR",
	"fr-PM": "EUR",
	"en-VC": "XCD",
	"en-WS": "WST",
	"it-SM": "EUR",
	"ar-SA": "SAR",
	"fr-SN": "XOF",
	"ff-Latn-SN": "XOF",
	"dyo-SN": "XOF",
	"wo-SN": "XOF",
	"sr-Cyrl-RS": "RSD",
	"sr-Latn-RS": "RSD",
	"en-SC": "SCR",
	"fr-SC": "SCR",
	"en-SL": "SLL",
	"ff-Latn-SL": "SLL",
	"zh-SG": "SGD",
	"en-SG": "SGD",
	"ta-SG": "SGD",
	"nl-SX": "ANG",
	"en-SX": "ANG",
	"sk-SK": "EUR",
	"en-SI": "EUR",
	"sl-SI": "EUR",
	"en-SB": "SBD",
	"ar-SO": "SOS",
	"so-SO": "SOS",
	"af-ZA": "ZAR",
	"en-ZA": "ZAR",
	"nso-ZA": "ZAR",
	"tn-ZA": "ZAR",
	"st-ZA": "ZAR",
	"nr-ZA": "ZAR",
	"ss-ZA": "ZAR",
	"ts-ZA": "ZAR",
	"ve-ZA": "ZAR",
	"xh-ZA": "ZAR",
	"zu-ZA": "ZAR",
	"ar-SS": "SSP",
	"en-SS": "SSP",
	"nus-SS": "SSP",
	"ast-ES": "EUR",
	"eu-ES": "EUR",
	"ca-ES": "EUR",
	"gl-ES": "EUR",
	"es-ES_tradnl": "EUR",
	"es-ES": "EUR",
	"si-LK": "LKR",
	"ta-LK": "LKR",
	"ar-SD": "SDG",
	"en-SD": "SDG",
	"nus-SD": "SDG",
	"nl-SR": "SRD",
	"nb-SJ": "NOK",
	"en-SZ": "SZL",
	"ss-SZ": "SZL",
	"en-SE": "SEK",
	"smj-SE": "SEK",
	"se-SE": "SEK",
	"sma-SE": "SEK",
	"sv-SE": "SEK",
	"gsw-CH": "CHF",
	"en-CH": "CHF",
	"fr-CH": "CHF",
	"de-CH": "CHF",
	"it-CH": "CHF",
	"pt-CH": "CHF",
	"rm-CH": "CHF",
	"wae-CH": "CHF",
	"ar-SY": "SYP",
	"fr-SY": "SYP",
	"syr-SY": "SYP",
	"pt-ST": "STN",
	"zh-TW": "TWD",
	"tg-Cyrl-TJ": "TJS",
	"asa-TZ": "TZS",
	"bez-TZ": "TZS",
	"en-TZ": "TZS",
	"sw-TZ": "TZS",
	"lag-TZ": "TZS",
	"jmc-TZ": "TZS",
	"kde-TZ": "TZS",
	"mas-TZ": "TZS",
	"rof-TZ": "TZS",
	"rwk-TZ": "TZS",
	"sbp-TZ": "TZS",
	"ksb-TZ": "TZS",
	"vun-TZ": "TZS",
	"th-TH": "THB",
	"pt-TL": "USD",
	"ee-TG": "XOF",
	"fr-TG": "XOF",
	"en-TK": "NZD",
	"en-TO": "TOP",
	"to-TO": "TOP",
	"en-TT": "TTD",
	"ar-TN": "TND",
	"fr-TN": "TND",
	"tr-TR": "TRY",
	"tk-TM": "TMT",
	"en-TC": "USD",
	"en-TV": "AUD",
	"en-UM": "USD",
	"en-VI": "USD",
	"cgg-UG": "UGX",
	"en-UG": "UGX",
	"lg-UG": "UGX",
	"sw-UG": "UGX",
	"nyn-UG": "UGX",
	"xog-UG": "UGX",
	"teo-UG": "UGX",
	"ru-UA": "UAH",
	"uk-UA": "UAH",
	"en-AE": "AED",
	"ar-AE": "AED",
	"kw-GB": "GBP",
	"en-GB": "GBP",
	"gd-GB": "GBP",
	"cy-GB": "GBP",
	"chr-Cher-US": "USD",
	"en-US": "USD",
	"haw-US": "USD",
	"lkt-US": "USD",
	"es-US": "USD",
	"es-UY": "UYU",
	"uz-Cyrl-UZ": "UZS",
	"uz-Latn-UZ": "UZS",
	"en-VU": "VUV",
	"fr-VU": "VUV",
	"it-VA": "EUR",
	"vi-VN": "VND",
	"fr-WF": "XPF",
	"ar-YE": "YER",
	"bem-ZM": "ZMW",
	"en-ZM": "ZMW",
	"en-ZW": "ZWL",
	"nd-ZW": "ZWL",
	"sn-Latn-ZW": "ZWL",
	"sv-AX": "EUR"
};

// Leaf type 3 is herbaceous.
// Species types 1, 5, and 7 are class, genus, and species, respectively.
// Allows only growth forms 1, 3, and 5:  "Shrub or small tree", "palm",
//   and "tree", respectively.
// Species type less than 6 (i.e. not "species") are exactly the scientific
//   name, whereas "species" scientific name is actually the combination of the
//   genus and species name together.
export const QUERY =
	`SELECT SpeciesId AS id,Code AS classification,ScientificName AS scientific,CommonName AS common,'class' AS rank FROM _Species WHERE SpeciesTypeId=1 UNION SELECT sp1.SpeciesId AS id,sp1.Code AS classification,CONCAT_WS(' ',sp2.ScientificName,sp1.ScientificName) AS scientific,sp1.CommonName AS common,'species' AS rank FROM _Species AS sp1 LEFT JOIN _Species AS sp2 ON sp1.ParentId=sp2.SpeciesId JOIN _SpeciesTypes AS type ON sp1.SpeciesTypeId=type.SpeciesTypeId WHERE sp1.LeafTypeId!=3 AND sp1.Code IS NOT NULL AND sp1.SpeciesTypeId=7 AND sp1.GrowthFormId IN(1,3,5) UNION SELECT sp1.SpeciesId AS id,sp1.Code AS classification,sp1.ScientificName AS scientific,sp1.CommonName AS common,LOWER(type.Type) AS rank FROM _Species AS sp1 JOIN _Species AS sp2 ON sp1.SpeciesId=sp2.ParentId JOIN _SpeciesTypes AS type ON sp1.SpeciesTypeId=type.SpeciesTypeId WHERE sp1.LeafTypeId!=3 AND sp1.Code IS NOT NULL AND sp1.SpeciesTypeId=5 GROUP BY id,classification,scientific,common,rank HAVING sum(IF(sp2.GrowthFormId IN(1,3,5),1,0))>sum(IF(sp2.GrowthFormId IN(1,3,5),0,1)) ORDER BY scientific`;
