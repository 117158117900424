export const Footnotes = ({notes}) => {
	return (
		<span
			className={"text-start"}
			key={"footnotes"}
		>
			<strong>Footnotes</strong>
			{notes.map((note, index) => {
				return (
					<div key={`footnote-${index}`} className={"mb-2"}>
						<sup>{index + 1}</sup> {note}
					</div>
				)
			})}
		</span>
	)
};
