const POLLUTANTS = ["co_", "no2_", "o3_", "pm25_", "so2_"];
// const KGPY = "kilograms per year";
// const M2 = "meters squared";
// const T = "tonne";
// const TPY = "tonne per year";

// VOLUME
const CUBIC_METERS_TO_GALLONS = 264.17205124156;
// const CUBIC_METERS_TO_M_GALLONS = 0.00026417205124156;
// const GALLONS_TO_CUBIC_METERS = 0.0037854118;
// const M_GALLONS_TO_CUBIC_METERS = 3785.4118;
// const CUBIC_METERS_TO_LITERS = 1000;
const CUBIC_METERS_TO_KILOLITERS = 1;
// const LITERS_TO_CUBIC_METERS = 0.001;
// const M_GALLONS_TO_LITERS = 3785411.8;
const MILLIONS_OF_GALLONS_TO_GALLONS = 1000000;
const LITERS_TO_MILLIONS_OF_GALLONS = 0.00026417205124156;

// AREA
const SQUARE_METERS_TO_HECTARES = 0.0001;
const SQUARE_METERS_TO_ACRES = 0.00024710538146717;
const SQUARE_METERS_TO_SQUARE_MILES = 0.00000038610215855;
// const SQUARE_MILES_TO_SQUARE_METERS = 2589988.110336;

// MASS
const KILOGRAMS_TO_POUNDS = 2.2046226218488;
// const POUNDS_TO_KILOGRAMS = 0.45359237;
// const TONS_TO_TONNES = 0.90718474;
const TONNES_TO_TONS = 1.1023113109244;


export const extractCoverages = (feature) => {
	return new Map(
		[
			["area.area", feature.get("area.area")],
			["area.percent", feature.get("area.percent")],
			["canopy.area", feature.get("canopy.area")],
			["canopy.percent", feature.get("canopy.percent")],
			["impervious.area", feature.get("impervious.area")],
			["impervious.percent", feature.get("impervious.percent")],
		]
	);
};

export const convertCoverageAreas = (coverages, imperial = true) => {
	// Coverages are natively in square meters.
	let conversionUnit = imperial ? SQUARE_METERS_TO_ACRES : SQUARE_METERS_TO_HECTARES;
	coverages.forEach((value, key, map) => {
		if (!key.includes("percent")) {
			coverages.set(key, coverages.get(key) * conversionUnit);
		}
	});
	return coverages;
};

export const extractBenefits = (feature) => {
	let benefits = new Map(
		[
			["carbon_storage.currency", 0],
			["carbon_storage.tonne", 0],
			["carbon_sequestration.currency", 0],
			["carbon_sequestration.tonnePerYear", 0],
			["co2_equivalent_storage.currency", 0],
			["co2_equivalent_storage.tonne", 0],
			["co2_equivalent_sequestration.currency", 0],
			["co2_equivalent_sequestration.tonnePerYear", 0],
			["co_removal.currency", 0],
			["co_removal.massPerYear", 0],
			["no2_removal.currency", 0],
			["no2_removal.massPerYear", 0],
			["o3_removal.currency", 0],
			["o3_removal.massPerYear", 0],
			["pm2.5_removal.currency", 0],
			["pm2.5_removal.massPerYear", 0],
			["so2_removal.currency", 0],
			["so2_removal.massPerYear", 0],
			["air_total_removal.currency", 0],
			["air_total_removal.massPerYear", 0],
			["rainfall_interception", 0],
			["avoided_runoff", 0],
			["runoff_monetary", 0],
		]
	);
	POLLUTANTS.forEach((pollutant, index) => {
		benefits.set(`${pollutant}emergency-room-visits`, 0);
		benefits.set(`${pollutant}hospital-admissions`, 0);
		benefits.set(`${pollutant}school-loss-days`, 0);
		benefits.set(`${pollutant}work-loss-days`, 0);
		benefits.set(`${pollutant}acute-respiratory-symptoms`, 0);
		benefits.set(`${pollutant}asthma-exacerbation`, 0);
	});
	benefits.forEach((value, key, map) => {
		benefits.set(key, feature.get(key));
	});
	return benefits;
};

export const convertBenefits = (benefits, imperial = true) => {
	let hydroUnit = imperial ? LITERS_TO_MILLIONS_OF_GALLONS : 1; // millions of gallons vs liters
	let carbonUnit = imperial ? TONNES_TO_TONS : 1; // tons vs tonnes (metric tons)
	let pollutionUnit = imperial ? KILOGRAMS_TO_POUNDS : 1; // pounds vs kilograms
	benefits.forEach((value, key, map) => {
		switch (key) {
			case "carbon_storage.tonne":
			case "carbon_sequestration.tonnePerYear":
			case "co2_equivalent_storage.tonne":
			case "co2_equivalent_sequestration.tonnePerYear":
				benefits.set(key, value * carbonUnit);
				break;
			case "co_removal.massPerYear":
			case "no2_removal.massPerYear":
			case "o3_removal.massPerYear":
			case "pm2.5_removal.massPerYear":
			case "so2_removal.massPerYear":
				benefits.set(key, value * pollutionUnit);
				break;
			case "rainfall_interception":
			case "avoided_runoff":
				benefits.set(key, value * hydroUnit);
				break;
			default:
				// Do nothing
				// benefits.set(key, value);
		}
	});

	return benefits;
};

export const convertRunoffToPoolVolume = (feature, imperial) => {
	let olympicPoolVolume = imperial ? 660000 : 2500; // gallons vs cubic meters;
	let avoidedRunoff = feature.get("avoided_runoff");
	if (imperial) avoidedRunoff = avoidedRunoff * MILLIONS_OF_GALLONS_TO_GALLONS;
	return avoidedRunoff / olympicPoolVolume;
};

export const calculateAvoidedCarEmissions = (benefits, imperial) => {
	let carPollution = imperial ? 5.114724 : 4.640 // tons vs metric tons (tonne) of CO2 per vehicle per year.
	let benefit = benefits.get("co2_equivalent_sequestration.tonnePerYear");
	return benefit / carPollution;
};

export const calculateAvoidedHealthIncidents = (benefits) => {
	let respiratoryProblems = ["acute-respiratory-symptoms", "asthma-exacerbation"];
	let respiratoryIncidents = 0;
	let schoolLossDays = 0;
	let workLossDays = 0;

	POLLUTANTS.forEach((pollutant, i1) => {
		schoolLossDays += benefits.get(`${pollutant}school-loss-days`);
		workLossDays += benefits.get(`${pollutant}work-loss-days`);
		respiratoryProblems.forEach((problem, i2) => {
			respiratoryIncidents += benefits.get(`${pollutant}${problem}`)
		});
	});

	return {
		respiratoryIncidents: Math.floor(respiratoryIncidents),
		schoolLossDays: Math.floor(schoolLossDays),
		workLossDays: Math.floor(workLossDays),
	};
};

export const calculateAvoidedMedicalVisits = (benefits) => {
	let avoidedMedicalVisits = 0;
	POLLUTANTS.forEach((pollutant, index) => {
		avoidedMedicalVisits += benefits.get(`${pollutant}emergency-room-visits`);
		avoidedMedicalVisits += benefits.get(`${pollutant}hospital-admissions`);
	});
	return Math.floor(avoidedMedicalVisits);
};

export const convertCanopyToFootballFields = (canopy) => {
	const FOOTBALL_FIELD_AREA = 5351.215104 // in square meters
	return canopy / FOOTBALL_FIELD_AREA;
};

export const convertImperviousToParkingLots = (impervious) => {
	return impervious * SQUARE_METERS_TO_SQUARE_MILES;
};

export const toLocaleString = (value, options = {maximumFractionDigits: 0}, locale = "en-US") => {
	if (typeof value === "string") {
		return value.toLocaleString(locale, options);
	}
	if (typeof value === "number") {
		parseFloat(value);
		return value.toLocaleString(locale, options);
	}
	for (let v in value) value[v] = value[v].toLocaleString(locale, options);
	return value;
};

export const totalFinancialBenefits = (benefits) => {
	let co2_sequestration_cost = benefits.get("co2_equivalent_sequestration.currency");
	let runoff_monetary = benefits.get("runoff_monetary");
	let air_total_benefit = benefits.get("air_total_removal.currency");
	return runoff_monetary + co2_sequestration_cost + air_total_benefit;
};

export const assembleExclusionList = () => {
	let exclusions = [
		'plantable_space',
		'forest_type',
		'basal_species',
		'basal_total',
		'bird_species_count',
		'slr_1ft',
		'slr_2ft',
		'slr_3ft',
		'slr_4ft',
		'slr_5ft',
		'slr_6ft',
		'pm2.5_vehicle',
	];
	let health_benefits_to_exclude = [
		'vehicle',
		'mortality',
		'hospital-admissions-respiratory',
		'hospital-admissions-cardiovascular',
		'acute-myocardial-infarction',
		'chronic-bronchitis',
		'acute-bronchitis',
		'upper-respiratory-symptoms',
		'lower-respiratory-symptoms',
	];

	POLLUTANTS.forEach((p, index) => {
		health_benefits_to_exclude.forEach((h, indexx) => {
			exclusions.push(`${p}${h}`)
		})
	});

	return exclusions;
};

export const modifyCarbon = (carbonStorage, canopyArea, improvedCanopyArea) => {
	carbonStorage = parseFloat(carbonStorage);
	canopyArea = parseFloat(canopyArea);
	improvedCanopyArea = parseFloat(improvedCanopyArea);
	return carbonStorage * (improvedCanopyArea / canopyArea);
};

export const calculateAdditionalCarbonStorage = (benefits, improvedBenefits, canopyArea, improvedCanopyArea, imperial) => {
	let multiplier = imperial ? TONNES_TO_TONS : 1;
	let currentCarbon = benefits.get("carbon_storage.tonne") * multiplier;
	let improvedCarbon = improvedBenefits.get("carbon_storage.tonne") * multiplier;
	let modifiedCarbon = modifyCarbon(improvedCarbon, canopyArea, improvedCanopyArea);
	return modifiedCarbon - currentCarbon;
};

export const calculateAdditionalAvoidedRunoff = (benefits, improvedBenefits, imperial) => {
	// If imperial, this must be changed from millions of gallons to gallons for a better UX.
	let hydroMultiplier = imperial ? CUBIC_METERS_TO_GALLONS : CUBIC_METERS_TO_KILOLITERS;
	let currentRunoffAvoided = benefits.get("avoided_runoff") * hydroMultiplier;
	let improvedRunoffAvoided = improvedBenefits.get("avoided_runoff") * hydroMultiplier;
	return improvedRunoffAvoided - currentRunoffAvoided;
};

export const calculateAdditionalPollutionRemoval = (benefits, improvedBenefits, imperial) => {
	let pollutionMultiplier = imperial ? KILOGRAMS_TO_POUNDS : 1;
	let currentTotalRemoval = benefits.get("air_total_removal.massPerYear") * pollutionMultiplier;
	let improvedTotalRemoval = improvedBenefits.get("air_total_removal.massPerYear") * pollutionMultiplier;
	return improvedTotalRemoval - currentTotalRemoval;
};
