import Feature from "ol/Feature";
import {MultiPolygon} from "ol/geom";
import {Tile} from "ol/layer";
import {TileWMS} from "ol/source";

import {Loader} from "@googlemaps/js-api-loader";

const LOADER = new Loader({
	apiKey: process.env.REACT_APP_GOOGLE_API,
	version: "weekly",
	libraries: ["geocoding"],
});

let geocoder;
LOADER
	.load()
	.then((google) => {
		geocoder = new google.maps.Geocoder();
	});


export const createOptions = (list) => {
	list.forEach(function (item, i) {
		let selector = document.getElementById(item.title);
		item.content.forEach((c, i) => {
			let opt = document.createElement("option");
			opt.value = i;
			opt.append(
				document.createTextNode(c)
			);
			selector.add(opt);
			if (i === 0 && item.hasPlaceholder) {
				opt.disabled = "disabled";
				// Explicitly selecting it prevents some browsers from
				// skipping to the next option.
				opt.selected = "selected";
			}
			if (item.selection === opt.value) selector.value = opt.value;
		});
	});
};

export const truncNum = (n, style = "decimal", digits = 2) => {
	return parseFloat(n).toLocaleString(
		undefined,
		{
			maximumFractionDigits: digits,
			style: style,
			currency: "USD"
		}
	);
};

export const singleLineString = (strings) => {
	let lines = strings.split(/(?:\r\n|\n|\r)/);

	return lines.map((line) => {
		return line.replace(/^\s+/gm, "");
	}).join(" ").trim();
};

export const compareValues = (key, order = "asc") => {
	return function (a, b) {
		let comparison = 0;
		let first = a[key];
		let second = b[key];
		if (typeof first === "string") first = first.toLowerCase();
		if (typeof second === "string") second = second.toLowerCase();

		if (first > second) {
			comparison = 1;
		} else if (first < second) {
			comparison = -1;
		}

		return (
			(order === "desc") ? (comparison * -1) : comparison
		);
	}
};

export const createUUID = () => {
	let seed = Date.now();
	if (performance && typeof performance.now === "function") {
		seed += performance.now();
	}

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = (seed + Math.random() * 16) % 16 | 0;
		seed = Math.floor(seed / 16);

		return (c === 'x' ? r : r & (0x3 | 0x8)).toString(16);
	});
};

export const inRange = (value) => {
	if (value <= 0 || value >= 0.1) return value;
	return 0;
};

export const formatCurrency = (value, currency) => {
	let {locale, code, exchangeRate} = currency;
	value = value * exchangeRate;
	return Intl.NumberFormat(
		locale,
		{
			style: "currency",
			currency: code
		}
	).format(value);
};

export const extractAddressComponents = (result) => {
	let components = {
		nation: "",
		stateAbbr: "",
		township: "",
		borough: "",
		city: {
			short: "",
			long: "",
		},
		county: {
			short: "",
			long: "",
		},
		zipCode: "",
		address: result.formatted_address,
	};

	// let address.formatted = result.formatted_address;

	// locality: An incorporated city or town.
	// administrative_area_level_1: First-order civil entity below the country level; in the US, these are states. In Puerto Rico these appear to be municipalities.
	// administrative_area_level_2: Second-order civil entities below the country level; in the US, these are counties. In Puerto Rico these appear to be barrios.
	// administrative_area_level_3/4/5: Third-order civil entities below the country level; in the US, these are townships and smaller.
	// locality: In the USA these are usually cities; in Puerto Rico they may be municipalities.
	// sublocality: In Puerto Rico these may be barrios.
	result.address_components.forEach(function (component, i) {
		component.types.forEach(function (type, i) {
			switch (type) {
				case "administrative_area_level_5":
				case "administrative_area_level_4":
				case "administrative_area_level_3":
					components.township = component.long_name.toLowerCase();
					break;
				case "locality":
					components.city.short = component.short_name.toLowerCase();
					components.city.long = component.long_name.toLowerCase();
					break;
				case "sublocality":
					components.borough = component.long_name.toLowerCase();
					break;
				case "administrative_area_level_2":
					components.county.short = component.short_name.toLowerCase();
					components.county.long = component.long_name.toLowerCase();
					break;
				case "country":
					components.nation = component.long_name.toLowerCase();
					break;
				case "administrative_area_level_1":
					components.stateAbbr = component.short_name.toLowerCase();
					break;
				case "postal_code":
					components.zipCode = component.short_name;
					break;
				default:
					// FIXME: This default should do something useful,
					//  or replace this with an if/else chain.
					break;
			}
		});
	});

	// First, check if the location is in Puerto Rico
	// and makes adjustments if true.
	if (components.nation.includes("puerto rico")) {
		components.nation = "united states of america";
		if (!components.city.short) components.city.short = components.borough;
		if (!components.city.long) components.city.long = components.borough;
		components.county.short = components.stateAbbr ? components.stateAbbr : components.city.short;
		components.county.long = components.stateAbbr ? components.stateAbbr : components.city.long;
		components.stateAbbr = "pr";
	}

	// Next, check if the location is a borough of NY and adjust if true.
	if (
		components.stateAbbr === "ny" &&
		components.city.long === ""
	) {
		let boroughs = [
			"the bronx",
			"brooklyn",
			"manhattan",
			"queens",
			"staten island"
		];

		if (boroughs.includes(components.borough.toLowerCase())) {
			components.city.long = "new york";
		}
	}

	// Next, correct for townships, which aren't in the database.
	if (components.city.long === "" && components.township !== "") {
		components.city.long = components.township;
		components.city.short = components.township;
	}

	// Next, handle independent cities, which are not in the territory of
	// any county, or counties with some exceptions (mostly in Virginia).
	if (components.county.long === "" && components.city.long !== "") {
		components.county.long = components.city.long;
		components.county.short = components.city.short;
	}

	// Next, handle St. Paul geocoding as St Paul.
	if (components.city.short === "st paul") {
		components.city.short = "st. paul";
	}

	// Next, handle Washington, DC addresses that don't match our database.
	if (components.city.short === "washington, d.c.") {
		components.city.short = "washington";
		components.county.short = "district of colombia";
		components.county.long = "district of colombia";
		components.stateAbbr = "DC";
	}

	return components;
};

export const getLatLngFromAddress = async (input) => {
	let latlng;
	try {
		latlng = await geocoder.geocode({address: input});
		latlng.status = "ok";
	} catch (error) {
		latlng = {status: error.message};
	}
	return latlng;
};

export const getAddressFromLatLng = async (position) => {
	let address;
	try {
		address = await geocoder.geocode(
			{
				location: {
					lng: position.longitude,
					lat: position.latitude,
				}
			}
		)
		address.status = "ok";
	} catch (error) {
		address = {status: error.message};
	}
	return address;
};

export const toNameCase = (string) => {
	let stringArray = [string];
	if (string.includes(" ")) stringArray = string.split(" ");
	stringArray.forEach((word, index) => {
		stringArray[index] = word[0].toUpperCase() + word.slice(1);
	});

	return stringArray.toString().replace(",", " ");
};

export const fetchExchangeRates = async () => {
	let url = "https://www.itreetools.org/xr.php";
	let controller = new AbortController();
	let signal = controller.signal;
	let timeout = setTimeout(() => controller.abort(), 6000);

	signal.addEventListener("abort", () => {
		alert("We were not able to retrieve exchange rates.");
	});

	let data = await (
		await fetch(url, {signal})
	).json();

	if (data) {
		clearTimeout(timeout);
		return data.rates;
	}
};

export const getOLFeature = async ({source, coordinates, resolution}) => {
	let olFeature = null;
	let url = source
		.getFeatureInfoUrl(
			coordinates,
			resolution,
			"EPSG:3857",
			{"INFO_FORMAT": "application/json"}
		);
	let response = await fetch(url);
	let json = await response.json();

	if (json.numberReturned >= 1) {
		let feature = json.features[0];
		olFeature = new Feature(
			{
				title: feature.properties.geoid,
				geometry: new MultiPolygon(feature.geometry.coordinates),
			}
		);
	}

	return olFeature;
};

export const generateBoundaryLayers = () => {
	let boundaryLayers = [];
	let url = "https://gis.landscape.itreetools.org/geoserver/landscape/wms";
	let layerOptions = [
		{
			layerTitle: "places",
			layersParam: "landscape:places",
		},
		{
			layerTitle: "subdivisions",
			layersParam: "landscape:subdivision",
		},
	];
	layerOptions.forEach((option, index) => {
		boundaryLayers.push(
			new Tile({
				title: option.layerTitle,
				visible: false,
				source: new TileWMS({
					url: url,
					params: {
						LAYERS: option.layersParam,
						TILED: true,
						STYLES: "NeonRed",
					},
					serverType: "geoserver",
					crossOrigin: "anonymous",
					transition: 0,
				}),
			}),
		);
	});
	return boundaryLayers;
};

export const getValueFromSearchParams = (key) => {
	let searchParams = new URLSearchParams(
		window.location.toString().substring(
			window.location.toString().indexOf("?")
		)
	);
	return searchParams.get(key);
}

export const extractValuesByField = (features, field) => {
	let values = [];
	features.forEach((feature, index) => {
		values.push(feature.get([field]));
	});
	return values;
};

export const convertToOLFeatures = (features, fields = []) => {
	let olFeatures = [];
	features.forEach((feature, index) => {
		let newFeature = new Feature({
			geometry: new MultiPolygon(feature.geometry.coordinates),
		});
		fields.forEach((field, index) => {
			newFeature.set(field, feature.properties[field]);
		});
		olFeatures.push(newFeature);
	});
	return olFeatures;
};

export const getOuterHeight = (element) => {
	let offsetHeight = parseFloat(element.offsetHeight);
	offsetHeight += parseFloat(window.getComputedStyle(element).getPropertyValue("margin-top"));
	offsetHeight += parseFloat(window.getComputedStyle(element).getPropertyValue("margin-bottom"));
	return Math.floor(offsetHeight);
};

export const getOuterHeights = (elements = []) => {
	let combinedHeight = 0;
	elements.forEach((element) => {
		if (element) combinedHeight += getOuterHeight(element);
	});
	return combinedHeight;
};
