import Base from "itr/base"


class CarbonBase extends Base {
	storage = 0
	sequestration = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class CarbonDioxide extends CarbonBase {
	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class Carbon extends CarbonBase {
	storageWorthLifetime = 0
	sequestrationWorth = 0
	biomass = 0
	carbonDioxide = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.carbonDioxide instanceof CarbonDioxide)) {
			this.carbonDioxide = new CarbonDioxide(this.carbonDioxide)
		}
	}
}

Carbon.__id = "carbon"


export { CarbonDioxide }
export { Carbon }
export default Carbon

