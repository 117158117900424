import Base from "itr/base"


// XXX: these fields match both the APIv3 input and output.
class Location extends Base {
	nation = null
	state = null
	county = null
	city = null

	zipCode = null

	longitude = null
	latitude = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


export { Location }
export default Location

