import {v4 as createUUID} from 'uuid';


export class Building {
	constructor(
		{
			uuid = createUUID(),
			direction = -1,
			distance = -1,
			proximity = 2,
			vintage = -1,
		} = {}
	) {
		this.uuid = uuid;
		this.direction = direction;
		this.distance = distance;
		this.proximity = proximity;
		this.vintage = vintage;
	}

	__fields = () => {
		return {
			uuid: this.uuid,
			direction: this.direction,
			distance: this.distance,
			proximity: this.proximity,
			vintage: this.vintage
		}
	};

	__url = () => {
		let fields = this.__fields();
		let query = new URLSearchParams();

		function cycleFields(fields) {
			for (let f in fields) {
				if (fields[f] instanceof Object) {
					cycleFields(fields[f])
				} else {
					query.append(f, fields[f]);
				}
			}
		}

		cycleFields(fields);

		return query;
	};

	validate = () => {
		let u = "undefined";
		let response = {
			proceed: true,
			errors: {
				uuid: false,
				direction: false,
				distance: false,
				proximity: false,
				vintage: false,
			},
		};

		if (
			!this.uuid ||
			typeof this.uuid === u ||
			this.uuid === ""
		) {
			response.proceed = false;
			response.errors.uuid = true;
		} else {
			delete response.errors.uuid;
		}

		if (
			typeof this.proximity === u ||
			this.proximity === "" ||
			isNaN(this.proximity)
		) {
			response.proceed = false;
			response.errors.proximity = true;
		} else if (this.proximity) {
			delete response.errors.proximity;

			if (
				typeof this.direction === u ||
				this.direction === "" ||
				(!this.direction instanceof Boolean)
			) {
				response.proceed = false;
				response.errors.direction = true;
			} else {
				delete response.errors.direction;
			}

			if (
				typeof this.distance === u ||
				this.distance === "" ||
				(!this.distance instanceof Boolean)
			) {
				response.proceed = false;
				response.errors.distance = true;
			} else {
				delete response.errors.distance
			}
		}

		return response
	};
}
