import React, {Component} from "react";

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from "reactstrap";


export class Message extends Component {

	render() {
		if (
			this.props.message &&
			this.props.message.content &&
			this.props.message.content !== ""
		) {
			return (
				<Modal
					isOpen={this.props.message.visible}
					fade={false}
					toggle={this.props.setMessage}
				>
					{this.props.message.title ?
						<ModalHeader toggle={this.toggle}>
							{this.props.message.title}
						</ModalHeader>
						:
						null
					}
					<ModalBody>
						{this.props.message.content}
					</ModalBody>
					<ModalFooter>
						<Button
							color={"secondary"}
							onClick={this.props.setMessage}
						>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			)
		} else {
			return null;
		}
	}
}
