import React from 'react';

import {
	Row,
	Col,
} from "reactstrap";

import {Bar} from "../NutritionLabelParts";
import {Top} from "./Top";

import {
	convertBenefits,
	convertCoverageAreas,
	extractCoverages,
	extractBenefits,
	toLocaleString,
	totalFinancialBenefits
} from "./reportHelper";


export const Benefits = (
	{
		address,
		benefits,
		canvasTarget,
		imperial,
	}
) => {
	let convertedBenefits = convertBenefits(extractBenefits(benefits), imperial);
	let coverages = convertCoverageAreas(extractCoverages(benefits), imperial);
	let total = totalFinancialBenefits(convertedBenefits);
	let t = "tonne";
	let kpy = "kilograms per year";
	let coverageUnit = imperial ? "acres" : "hectares";
	let hydroUnit = imperial ?
		<abbr title={"millions of gallons per year"}>MG/yr</abbr> :
		<abbr title={"cubic meters"}>m<sup>3</sup>/yr</abbr>;
	let carbonUnit = imperial ?
		<abbr title={"short ton"}>tn</abbr> :
		<abbr title={t}>t</abbr>;
	let pollutantUnit = imperial ?
		<abbr title={"pounds per year"}>lb/yr</abbr> :
		<abbr title={kpy}>kg/yr</abbr>;

	let canopyArea = toLocaleString(coverages.get("canopy.area"));
	let imperviousArea = toLocaleString(coverages.get("impervious.area"));

	let canopyPercent = coverages.get("canopy.percent");
	let imperviousPercent = coverages.get("impervious.percent");

	let runoffMonetary = toLocaleString(convertedBenefits.get("runoff_monetary"));
	let runoffAvoided = toLocaleString(convertedBenefits.get("avoided_runoff"));
	let interception = toLocaleString(convertedBenefits.get("rainfall_interception"));

	let carbonStorage = toLocaleString(convertedBenefits.get("carbon_storage.tonne"));
	let carbonSequestration = toLocaleString(convertedBenefits.get("carbon_sequestration.tonnePerYear"));
	let co2Storage = toLocaleString(convertedBenefits.get("co2_equivalent_storage.tonne"));
	let co2StorageMonetary = toLocaleString(convertedBenefits.get("co2_equivalent_storage.currency"));
	let co2Sequestered = toLocaleString(convertedBenefits.get("co2_equivalent_sequestration.tonnePerYear"));
	let co2SequesteredMonetary = toLocaleString(convertedBenefits.get("co2_equivalent_sequestration.currency"));

	let totalPollutionRemoval = toLocaleString(convertedBenefits.get("air_total_removal.currency"));
	let co = toLocaleString(convertedBenefits.get("co_removal.massPerYear"));
	let o3 = toLocaleString(convertedBenefits.get("o3_removal.massPerYear"));
	let no2 = toLocaleString(convertedBenefits.get("no2_removal.massPerYear"));
	let so2 = toLocaleString(convertedBenefits.get("so2_removal.massPerYear"));
	let pm25 = toLocaleString(convertedBenefits.get("pm2.5_removal.massPerYear"));

	return (
		<section id={canvasTarget}>
			<Top text={"OurTrees Benefits"}/>
			<Row>
				<Col>
					<h5>
						Trees in {address}
					</h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<strong className={"d-block"}>
						Serving Size:
					</strong>
					<p className={"my-0"}>
						{canopyPercent}% tree canopy{" "}
						on {canopyArea} {coverageUnit}
					</p>
					<p className={"my-0"}>
						{imperviousPercent}% impervious surfaces{" "}
						over {imperviousArea} {coverageUnit}
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className={"mb-2 d-flex justify-content-between"}>
						<strong>
							Total benefits for this year:
						</strong>
						<strong>
							${toLocaleString(total)}
						</strong>
					</div>
				</Col>
			</Row>
			<Bar text={"Annual values:"}/>
			<Row>
				<Col>
					<div className={"mb-2 d-flex justify-content-between"}>
						<strong>
							Carbon Dioxide Uptake
						</strong>
						<strong>
							${co2SequesteredMonetary}
						</strong>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Carbon Sequestered
						</p>
						<p className={"my-0"}>
							{carbonSequestration} {carbonUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							CO<sub>2</sub> Equivalent<sup>1</sup>
						</p>
						<p className={"my-0"}>
							{co2Sequestered} {carbonUnit}
						</p>
					</div>
					<hr/>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className={"mb-2 d-flex justify-content-between"}>
						<strong>
							Storm Water Mitigation
						</strong>
						<strong>
							${runoffMonetary}
						</strong>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Runoff Avoided
						</p>
						<p className={"my-0"}>
							{runoffAvoided} {hydroUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Rainfall Intercepted
						</p>
						<p className={"my-0"}>
							{interception} {hydroUnit}
						</p>
					</div>
					<hr/>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className={"mb-2 d-flex justify-content-between"}>
						<strong>
							Air Pollution Removal
						</strong>
						<strong>
							${totalPollutionRemoval}
						</strong>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Carbon Monoxide
						</p>
						<p className={"my-0"}>
							{co} {pollutantUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Ozone
						</p>
						<p className={"my-0"}>
							{o3} {pollutantUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Nitrogen Dioxide
						</p>
						<p className={"my-0"}>
							{no2} {pollutantUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Sulfur Dioxide
						</p>
						<p className={"my-0"}>
							{so2} {pollutantUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							PM<sub>2.5</sub>
						</p>
						<p className={"my-0"}>
							{pm25} {pollutantUnit}
						</p>
					</div>
					<hr/>
				</Col>
			</Row>
			<Bar text={"Values are totals to date:"}/>
			<Row>
				<Col>
					<div className={"mb-2 d-flex justify-content-between"}>
						<strong>
							Carbon Dioxide Uptake
						</strong>
						<strong>
							${co2StorageMonetary}
						</strong>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							Carbon Storage
						</p>
						<p className={"my-0"}>
							{carbonStorage} {carbonUnit}
						</p>
					</div>
					<hr/>
					<div className={"d-flex justify-content-between"}>
						<p className={"my-0 indent"}>
							CO<sub>2</sub> Equivalent<sup>1</sup>
						</p>
						<p className={"my-0"}>
							{co2Storage} {carbonUnit}
						</p>
					</div>
				</Col>
			</Row>
		</section>
	);
}