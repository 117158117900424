import React from "react";

export function Sycamore() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38"
			height="38"
			viewBox={"0 0 120 120"}
			fillRule="evenodd"
			clipRule="evenodd"
			imageRendering="optimizeQuality"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<desc>Sycamore spp</desc>
			<path
				fill="#006D41"
				d="M81.5-.5h2a144.512 144.512 0 004.5 26c1.011.837 2.178 1.17 3.5 1a60.914 60.914 0 00-1.5 11c1.789 1.729 3.956 2.396 6.5 2a106.512 106.512 0 00-4.5 17l1.5 1.5c7.643.696 14.643 3.196 21 7.5-4.811 6.226-7.978 13.226-9.5 21a1113.218 1113.218 0 01-9 9 21.405 21.405 0 010 5 99.038 99.038 0 01-11 3c.437 2.257.937 4.59 1.5 7a128.508 128.508 0 01-16-.5c-10.639-4.353-21.639-5.52-33-3.5h-1c.202-2.489-.298-4.823-1.5-7C28.307 91.401 19.807 86.235 9.5 84L5 79.5c-.333-2.434.834-3.434 3.5-3-2.14-3.34-3.64-7.006-4.5-11-1.277-2.247-2.777-4.247-4.5-6v-3c2.51-.613 5.01-1.28 7.5-2a119.37 119.37 0 00-1.5-12h5c1.155-4.33 1.322-8.664.5-13A601.836 601.836 0 0118.5 12c1-.667 2-.667 3 0 2.756 3.427 6.09 6.093 10 8 1.792 4.124 4.625 7.29 8.5 9.5 1.261-.26 2.261-.926 3-2L53.5 12c4.7 2.556 8.7 1.556 12-3 6.21-1.714 11.543-4.88 16-9.5z"
			></path>
			<path
				fill="#006D41"
				d="M36.5 106.5h1c-2.273 4.894-5.273 9.227-9 13h-4a60.011 60.011 0 005.5-4c1.643-3.461 3.81-6.461 6.5-9z"
			></path>
		</svg>
	);
}
