import Base from "itr/base"


class ClimateControlBase extends Base {
	climate = 0
	climateWorth = 0
	shade = 0
	shadeWorth = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class Cool extends ClimateControlBase {
	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class Heat extends ClimateControlBase {
	wind = 0
	windWorth = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])
	}
}


class Electric extends Base {
	heat = null
	cool = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.cool instanceof Cool)) {
			this.cool = new Cool(this.cool)
		}

		if (! (this.heat instanceof Heat)) {
			this.heat = new Heat(this.heat)
		}
	}
}


class NaturalGas extends Base {
	heat = null

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.heat instanceof Heat)) {
			this.heat = new Heat(this.heat)
		}
	}
}


class Energy extends Base {
	electric = null
	naturalGas = null
	// TODO: verify price stuff.
	// XXX: these are likely to be refactored during the beta of APIv3.
	priceKwh= 0
	priceTherm= 0
	priceMbtu = 0

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.electric instanceof Electric)) {
			this.electric = new Electric(this.electric)
		}

		if (! (this.naturalGas instanceof NaturalGas)) {
			this.naturalGas = new NaturalGas(this.naturalGas)
		}
	}
}

Energy.__id = "energy"


export { Energy }
export default Energy

