import React from "react";

import {URLS} from "../../../static_props/MyTree";
import usfs from "../../../../img/fs_logo.png";


let target = "_blank";
let rel = "noopener noreferrer"
let alt = "United States Forest Service";

export let AfterCollapse = [
	<div
		key={"afterCollapse"}
		className={"d-none d-sm-block"}
	>
		<a
			href={URLS.forestService}
			target={target}
			rel={rel}
		>
			<img
				className={"nav-item d-none d-sm-block nav-logo"}
				src={usfs}
				alt={alt}
			/>
		</a>
	</div>
];

export let AfterBrand = [
	<div
		key={"afterBrand"}
		className={"d-block d-sm-none"}
	>
		<a
			href={URLS.forestService}
			target={target}
			rel={rel}
		>
			<img
				src={usfs}
				alt={alt}
			/>
		</a>
	</div>
];