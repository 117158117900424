import Base from "itr/base"
import Building from "itr/building"
import Location from "itr/location"
import HTHC from "itr/hthc"


// XXX: the fields match the APIv3 input. Should they instead match the output?
class Tree extends Base {
	// Attributes are alphabetical.
	condition = "excellent"
	crownExposure = 5
	crownHeight = -1 // -1 will change to null during APIv3 beta.
	crownWidth = -1 // -1 will change to null during APIv3 beta.
	diameter = null
	height = -1 // -1 will change to null during APIv3 beta.
	groupName = null // overlaps with Project (but Project may change).
	label = null
	notes = null // overlaps with Project
	plantingType = null // overlaps with Project (Project may change).
	species = null // LocationSpecies database species code.
	treeCount = 1 // TODO: override toJSON so this can be 'count'.
	trillionTrees = null // may overlap with Project in the future.
	// Other main encapsulations.
	hthc = null // Healthy Trees, Healthy Cities fields
	location = null // only uses lat/lng; overlaps Project.
	building = null // overlaps with Project.

	constructor(publicFields) {
		super()
		Object.assign(this, arguments[0])

		if (! (this.hthc instanceof HTHC)) {
			this.hthc = new HTHC(this.hthc)
		}
		if (! (this.location instanceof Location)) {
			this.location = new Location(this.location)
		}
		if (! (this.building instanceof Building)) {
			this.building = new Building(this.building)
		}
	}
}


export { Tree }
export default Tree

