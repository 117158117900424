import {v4 as createUUID} from 'uuid';

import {DEVMODE, MYTREE} from "../utilities/resources";


export class Location {
	constructor(
		{
			// TODO: Test out removing nation, state, county, city, zip, and pin.
			uuid = createUUID(),
			address = {
				formatted: MYTREE ? "1964 Independence Ave. SW, Washington, D.C." : "Washington, DC",
				entered: MYTREE ? "1964 Independence Ave. SW, Washington, D.C." : "Washington, DC",
			},
			city = "Washington",
			county = "District of Colombia",
			latitude = 38.886224,
			longitude = -77.0442257,
			nation = "US",
			stateAbbr = "DC",
			zip = "20003",
			pin = false,
		} = {}
	) {
		this.uuid = uuid;
		this.address = address;
		this.city = city;
		this.county = county;
		this.latitude = latitude;
		this.longitude = longitude;
		this.nation = nation;
		this.stateAbbr = stateAbbr;
		this.zip = zip;
		this.pin = pin;
	}

	__fields = () => {
		return {
			uuid: this.uuid,
			address: this.address,
			city: this.city,
			county: this.county,
			latitude: this.latitude,
			longitude: this.longitude,
			nation: this.nation,
			stateAbbr: this.stateAbbr,
			zip: this.zip,
			pin: this.pin,
		};
	};

	__url = () => {
		let fields = this.__fields();
		let query = new URLSearchParams();

		function cycleFields(fields) {
			for (let f in fields) {
				if (fields[f] instanceof Object) {
					cycleFields(fields[f])
				} else {
					query.append(f, fields[f]);
				}
			}
		}

		cycleFields(fields);

		return query;
	};

	getCenter = () => {
		return [this.longitude, this.latitude];
	};

	extractComponents = async (result) => {
		let prompt = {
			text: "",
			no_town: false,
			address: "",
		};
		let components = {
			nation: "",
			stateAbbr: "",
			township: "",
			borough: "",
			city: {
				short: "",
				long: "",
			},
			county: {
				short: "",
				long: "",
			},
			zipCode: "",
		};

		this.address.formatted = result.formatted_address;

		// locality: An incorporated city or town.
		// administrative_area_level_1: First-order civil entity below the country level; in the US, these are states. In Puerto Rico these appear to be municipalities.
		// administrative_area_level_2: Second-order civil entities below the country level; in the US, these are counties. In Puerto Rico these appear to be barrios.
		// administrative_area_level_3/4/5: Third-order civil entities below the country level; in the US, these are townships and smaller.
		// locality: In the USA these are usually cities; in Puerto Rico they may be municipalities.
		// sublocality: In Puerto Rico these may be barrios.
		result.address_components.forEach(function (c, i) {
			c.types.forEach(function (t, i) {
				switch (t) {
					case "administrative_area_level_5":
					case "administrative_area_level_4":
					case "administrative_area_level_3":
						components.township = c.long_name.toLowerCase();
						break;
					case "locality":
						components.city.short = c.short_name.toLowerCase();
						components.city.long = c.long_name.toLowerCase();
						break;
					case "sublocality":
						components.borough = c.long_name.toLowerCase();
						break;
					case "administrative_area_level_2":
						components.county.short = c.short_name.toLowerCase();
						components.county.long = c.long_name.toLowerCase();
						break;
					case "country":
						components.nation = c.long_name.toLowerCase();
						break;
					case "administrative_area_level_1":
						components.stateAbbr = c.short_name.toLowerCase();
						break;
					case "postal_code":
						components.zipCode = c.short_name;
						break;
					default:
						break;
				}
			});
		});

		// First, check if the location is in Puerto Rico
		// and makes adjustments if true..
		if (components.nation.includes("puerto rico")) {
			components.nation = "united states of america";
			if (!components.city.short) components.city.short = components.borough;
			if (!components.city.long) components.city.long = components.borough;
			components.county.short = components.stateAbbr ? components.stateAbbr : components.city.short;
			components.county.long = components.stateAbbr ? components.stateAbbr : components.city.long;
			components.stateAbbr = "pr";
		}

		// Next, check if the location is a borough of NY and adjust if true.
		if (
			components.stateAbbr === "ny" &&
			components.city.long === ""
		) {
			let boroughs = [
				"the bronx",
				"brooklyn",
				"manhattan",
				"queens",
				"staten island"
			];

			if (boroughs.includes(components.borough.toLowerCase())) {
				components.city.long = "new york";
			}
		}

		// Next, correct for townships, which aren't in the database.
		if (components.city.long === "" && components.township !== "") {
			components.city.long = components.township;
			components.city.short = components.township;
		}

		// Next, handle independent cities, which are not in the territory of
		// any county, or counties with some exceptions (mostly in Virginia).
		if (components.county.long === "" && components.city.long !== "") {
			components.county.long = components.city.long;
			components.county.short = components.city.short;
		}

		// Next, handle St. Paul geocoding as St Paul.
		if (components.city.short === "st paul") {
			components.city.short = "st. paul";
		}

		// Next, handle Washington, DC addresses that don't match our database.
		if (components.city.short === "washington, d.c.") {
			components.city.short = "washington";
			components.county.short = "district of colombia";
			components.county.long = "district of colombia";
			components.stateAbbr = "DC";
		}

		return prompt;
	};

	validateLocation = async (city, county, state, nation, zip) => {
		function getField(xml, key) {
			let element = xml.getElementsByTagName(key)[0];
			if (element) return element.textContent.trim();
		}

		let controller = new AbortController();
		let signal = controller.signal;
		let timeout = setTimeout(() => controller.abort(), 5000);
		let url = "";

		if (DEVMODE) {
			url = new URL("https://dev.api.itreetools.org/v2/getGeneralLocationInformation/?");
		} else {
			url = new URL("https://api.itreetools.org/v2/getGeneralLocationInformation/?");
		}

		url.searchParams.append("key", process.env.REACT_APP_ITREE_ENGINE_API);
		url.searchParams.append("NorthHemisphere", "1");
		url.searchParams.append("CityName", city);
		url.searchParams.append("CountyName", county);
		url.searchParams.append("StateAbbr", state);
		url.searchParams.append("NationFullName", nation);
		url.searchParams.append("ZipCode", zip);

		signal.addEventListener("abort", () => {
			alert("The request to our server timed out. Please try again.")
		});

		let data = await (
			await fetch(
				url.href, {signal}
			)
		).text();

		if (data) clearTimeout(timeout);
		let xml = new DOMParser().parseFromString(data, "text/xml");
		let error = getField(xml, "Error");

		return error.length === 0;
	};

	validate = () => {
		let u = "undefined";
		let response = {
			proceed: true,
			errors: {
				uuid: false,
				address: false,
				city: false,
				county: false,
				latitude: false,
				longitude: false,
				nation: false,
				stateAbbr: false,
			},
		};

		for (let f in response.errors) {
			if (!this[f] || typeof this[f] === u || this[f] === "") {
				response.proceed = false;
				response.errors[f] = true;
			} else {
				delete response.errors[f];
			}
		}
		return response;
	}

	getAbbreviatedLocation = () => {
		if (this.city.length && this.stateAbbr.length && this.nation.length) {
			return `${this.city}, ${this.stateAbbr}, ${this.nation}`;
		}
		if (this.stateAbbr.length && this.nation.length) {
			return `${this.stateAbbr}, ${this.nation}`;
		}
		if (this.nation.length) {
			return this.nation;
		}
	}
}
