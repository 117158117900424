import React, {PureComponent} from 'react';

import {withRouter} from "react-router-dom";

import {
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";

import {ResetModal} from "../../Modals";

import {UnitToggle} from "../Shared";


class ProjectDropdown extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			navbarIsOpen: false,
			resetIsOpen: false,
		};
	}

	toggle = () => {
		this.setState((prevState) => ({
			navbarIsOpen: !prevState.navbarIsOpen
		}));
	};

	toggleResetModal = () => {
		this.setState((prevState) => ({
			resetIsOpen: !prevState.resetIsOpen
		}));
	};

	reset = (e) => {
		this.props.reset(e);
		this.toggleResetModal("reset");
		this.props.history.push("/");
	};

	render() {
		return (
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Project
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem
						onClick={this.toggleResetModal}
					>
						Start Over
					</DropdownItem>
					<ResetModal
						isOpen={this.state.resetIsOpen}
						toggleModal={this.toggleResetModal}
						reset={this.reset}
					/>
					<DropdownItem divider/>
					<UnitToggle
						checked={this.props.unitType}
						onChange={this.props.updateUnitType}
					/>
				</DropdownMenu>
			</UncontrolledDropdown>
		)
	}
}

export default withRouter(ProjectDropdown);
