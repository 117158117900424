import React, {PureComponent} from 'react';

import {
	NavLink as RRNavLink,
} from "react-router-dom";

import {
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";

import {URLS} from "../../../static_props/MyTree";


export class MenuDropdown extends PureComponent {
	render() {
		let eulaLink = "https://help.itreetools.org/eula?embed=true";
		let eulaTitle = "End User's License Agreement";
		let target = "_blank";
		let anchor = "a";

		return (
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Menu
				</DropdownToggle>
				<DropdownMenu end>
					<DropdownItem
						tag={anchor}
						href={URLS.home}
						target={target}
					>
						i-Tree Website
					</DropdownItem>
					<DropdownItem
						tag={anchor}
						href={eulaLink}
						target={target}
					>

						<abbr title={eulaTitle}>
							EULA
						</abbr>
					</DropdownItem>
					<DropdownItem
						tag={RRNavLink}
						to={"/about"}
					>
						About
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		)
	}
}
