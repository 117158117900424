export const createVersion = (code) => `${code[0]}.${code[1]}.${code[2]}`
export const createCode = (version) => version.split(".").map(x => parseInt(x))
export const createURL = (prototype, host) => `${prototype}://${host}/`

export const VERSION = process.env.NPM_PACKAGE_VERSION ?
	process.env.NPM_PACKAGE_VERSION : "0.0.0"
export const VERSION_CODE = createCode(VERSION)

let ENGINE_KEY = process.env.ENGINE_API_KEY
if (! ENGINE_KEY) {
	ENGINE_KEY = process.env.REACT_APP_ITREE_ENGINE_API
}
if (! ENGINE_KEY) {
	ENGINE_KEY = null
}

let ENGINE_PROTO = process.env.ENGINE_PROTO
if (! ENGINE_PROTO) {
	ENGINE_PROTO = process.env.REACT_APP_ITREE_ENGINE_API_PROTO
}
if (! ENGINE_PROTO) {
	ENGINE_PROTO = "https"
}

let ENGINE_HOST = process.env.ENGINE_HOST
if (! ENGINE_HOST) {
	ENGINE_HOST = process.env.REACT_APP_ITREE_ENGINE_API_HOST
}
if (! ENGINE_HOST) {
	ENGINE_HOST = "api.itreetools.org"
}

export { ENGINE_KEY }
export { ENGINE_PROTO }
export { ENGINE_HOST }
export let ENGINE_URL = createURL(ENGINE_PROTO, ENGINE_HOST)

// XXX: currently, only API version 3.0 is supported. There are no plans for v2.
//   Eventually, there should be a "supported" list or similar. Since APIv3 is
//   basically a release candidate there is only one version to support now.
let ENGINE_API_VERSION_ID = process.env.ENGINE_API_VERSION
if (! ENGINE_API_VERSION_ID) {
	ENGINE_API_VERSION_ID = process.env.REACT_APP_ITREE_ENGINE_API_VERSION
}
if (! ENGINE_API_VERSION_ID) {
	ENGINE_API_VERSION_ID = "v3"
}

export { ENGINE_API_VERSION_ID }
export let ENGINE_API_VERSION_CODE = [3, 0, 0]
export let ENGINE_API_VERSION = createVersion(ENGINE_API_VERSION_CODE)

