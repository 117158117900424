import React from "react";

function SwimmingPool() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
	  width="122.1"
      height="72"
      version="1"
      viewBox="0 0 403.86 238.14"
    >
      <style id="screen-color-temperature-style" type="text/css"></style>
      <g id="layer1" transform="translate(-88.964 -177.22)">
        <g id="g9011">
          <path
            id="path4128"
            fill="#dde6dc"
            fillRule="evenodd"
            d="M340.91 177.72l-251.44 105 82.84 132.16 320-186.44-151.4-50.72zm-.22 7.78l130.81 44.09-290.84 166.13-72.91-111.31 232.94-98.91z"
          ></path>
          <path
            id="path2186"
            fill="#51badc"
            fillRule="evenodd"
            d="M114.46 294.33l226.16-96.52 115.85 40.31-275.67 157.5-66.34-101.29z"
          ></path>
          <path
            id="path2170"
            fill="none"
            stroke="#000"
            strokeWidth="1"
            d="M89.464 282.72l82.856 132.14 320-186.43-151.43-50.71-251.43 105z"
          ></path>
          <path
            id="path8040"
            fill="#aac1a8"
            fillRule="evenodd"
            d="M107.86 284.33l232.76-98.75 130.81 43.93-15 8.66-115.72-40.36-225.84 97.41-7.01-10.89z"
          ></path>
          <path
            id="path2172"
            fill="none"
            stroke="#000"
            strokeWidth="0.911"
            d="M107.76 284.39l72.89 111.32L471.49 229.6l-130.81-44.09-232.92 98.88z"
          ></path>
          <path
            id="path2174"
            fill="none"
            stroke="#000"
            strokeWidth="1"
            d="M114.82 295.22l225.9-97.38 115.71 40.24"
          ></path>
          <path
            id="path2176"
            fill="none"
            stroke="#000"
            strokeWidth="1"
            d="M340.72 198.06v-12.2"
          ></path>
          <path
            id="path2184"
            fill="#b7b7b7"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="1"
            d="M342.81 310.33c.39 1.89 5.67 1.26 6.22-1.15 1.07-4.7 2.61-9.47 2.69-14.33.04-2.23-1.65-4.46-3.64-5.35-3.88-1.71-7.99-2.97-12.05-4.24-3.12-.98-6.45-1.62-9.65-1.72-4.76.17-6.47 5.87-7.3 10.06-1.58 7.88-3.23 19.88-4.75 27.78 1.34 1.32 5.06 1.32 5.31-.88.29-2.6.91-9.18 1.4-11.76 1.06-5.64 1.87-11.38 3.42-16.89 3.39-5.59 13.25-.86 17.99.98 1.71.66 3.18 2.39 2.58 4.71-1.08 4.14-2.97 9.13-2.22 12.79z"
          ></path>
          <path
            id="path2178"
            fill="#b7b7b7"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="1"
            d="M324.13 321.36c.39 1.89 5.67 1.26 6.22-1.15 1.07-4.7 2.62-9.47 2.69-14.33.04-2.23-1.65-4.47-3.64-5.35-3.88-1.71-7.99-2.97-12.05-4.24-3.12-.98-6.45-1.62-9.64-1.72-4.77.17-6.48 5.86-7.31 10.06-1.58 7.88-3.23 19.88-4.75 27.78 1.34 1.32 5.07 1.32 5.31-.88.29-2.6.91-9.18 1.4-11.76 1.06-5.64 1.87-11.38 3.42-16.89 3.39-5.59 13.25-.86 18 .98 1.7.65 3.17 2.39 2.57 4.71-1.08 4.14-2.97 9.13-2.22 12.79z"
          ></path>
          <path
            id="path5111"
            fill="#dde6dc"
            fillRule="evenodd"
            d="M295.05 341.11l29.07-16.36-1.76-9.97-27.63 16.03"
          ></path>
          <path
            id="path5131"
            fill="none"
            stroke="#000"
            strokeWidth="0.911"
            d="M294.09 330.92l28.68-16.38"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SwimmingPool;
