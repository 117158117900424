import React, {Component} from 'react';

import {
	Container,
	TabPane,
	TabContent,
} from "reactstrap";

import '../../stylesheets/nutrition.scss';

import * as Parts from "../parts/NutritionLabelParts";

import {Benefits} from "../../classes/benefits";
import {Project} from "../../classes/project";

import {TABS} from "../../utilities/resources";
import {Equivalents} from "../parts/Equivalents";
import {Bottom} from "../parts/MyTree/Bottom";


export class TotalBenefits extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: 1,
			activeSubTab: 0,
		};
	};

	toggle = (tab) => {
		this.setState({
			activeTab: tab,
		});
	};

	toggleSubTab = (tab) => {
		this.setState({
			activeSubTab: tab,
		});
	};

	componentDidMount() {
		if (this.props.projects.length === 0) {
			this.props.retrieveProjects()
		}
	};

	render() {
		let benefitsToDisplay;
		let totalBenefits = new Benefits();
		let projects = [...this.props.projects] || [];
		let proximity = false;
		let date = 0;
		let mostRecentProject = new Project();

		projects.forEach((project, index) => {
			// Determines if any trees have a proximate building.
			// If true, the energy benefits will be shown.
			if (!proximity && project.building.proximity === 0) proximity = 0;

			if (project.creationDate >= date) {
				mostRecentProject = project;
				date = project.creationDate;
			}

			let {benefits, futureBenefits} = {...project};

			if (this.state.activeTab === 0) {
				benefitsToDisplay = {...benefits};
			} else {
				benefitsToDisplay = {...futureBenefits};
			}

			for (let field in benefitsToDisplay.__fields()) {
				if (
					typeof benefitsToDisplay[field] === "object" &&
					benefitsToDisplay[field] instanceof Object
				) {
					if (benefitsToDisplay[field].__fields) {
						for (let item in benefitsToDisplay[field].__fields()) {
							if (
								field === "energy" ||
								(
									field !== "hydro" &&
									item.toLowerCase().includes("avoided")
								)
							) {
								if (project.building.proximity === 0) {
									totalBenefits[field][item] += parseFloat(benefitsToDisplay[field][item]);
								}
							} else {
								totalBenefits[field][item] += parseFloat(benefitsToDisplay[field][item]);
							}
						}
					}
				} else {
					if (typeof benefitsToDisplay[field] === "number") {
						totalBenefits[field] = totalBenefits[field] += benefitsToDisplay[field];
					}
				}
			}
		});

		let activeTabClasses = "active border border-primary";
		let subTabs = [
			"Benefits",
			"Equivalents",
		];

		return (
			<React.Fragment>
				<Parts.Controls
					toggleFunction={this.toggle}
					id={"benefitsYearToggle"}
					selected={this.state.activeTab}
					settings={TABS}
				/>
				<Parts.TabToggle
					function={this.toggleSubTab}
					selected={this.state.activeSubTab}
					tabs={subTabs}
					activeTabClasses={activeTabClasses}
				/>
				{benefitsToDisplay ?
					<TabContent
						activeTab={this.state.activeSubTab}
						className={"nutrition"}
					>
						<TabPane tabId={0}>
							<Container>
								<Parts.Header/>
								<Parts.TreeName
									common={"Tree Collection Totals"}
								/>
								<Parts.ServingSize
									total={true}
									count={projects.length}
								/>
								<Parts.Annual
									tab={this.state.activeTab}
									location={mostRecentProject.location}
									currency={this.props.currency}
									value={totalBenefits.totalDollarValue()}
								/>
								<Parts.BenefitsTable
									tab={this.state.activeTab}
									unitType={this.props.unitType}
									benefits={totalBenefits}
									proximity={proximity}
									currency={this.props.currency}
								/>
							</Container>
						</TabPane>
						<TabPane tabId={1}>
							<Equivalents
								common={"Tree Collection Totals"}
								activeTab={this.state.activeTab}
								benefits={totalBenefits}
								locationForDisplay={null}
								unitType={this.props.unitType}
							>
								{{
									intro: <p>With care and maintenance,<br/> after {this.state.activeTab === 0 ? "1 year" : "20 years"} these trees<br/>could potentially:</p>,
									label: <div>
										<p className={"h3 fw-bold"}>
											MyTrees
										</p>
										<p className={"mt-n2 h5 fw-bold"}>Work hard for me!</p>
									</div>
								}}
							</Equivalents>
						</TabPane>
						<Bottom
							activeTab={this.state.activeTab}
							activeSubTab={this.state.activeSubTab}
							dbVersion={benefitsToDisplay.meta.dbVersion}
							engineVersion={benefitsToDisplay.meta.engineVersion}
						/>
					</TabContent>
					:
					null
				}
			</React.Fragment>
		)
	}
}
