import React from "react";

function SmokeyCar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg548"
      width="128.3"
      height="48"
      version="1.1"
      viewBox="0 0 695 260"
    >
      <style id="screen-color-temperature-style" type="text/css"></style>
      <g id="g6029" transform="translate(-.004 31.373)">
        <path
          id="path606"
          fill="red"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M372.69 199.15c3.71-.42 69.9 7.15 74.65-9.46 11.86-33.23-8.71-60.92-37.19-71.21-28.48-5.54-24.52-2.37-61.71-7.91C314.42 66.27 329.46-3.35 247.97 2.19 156.2-.19 141.96 78.13 111.89 101.08 90.532 126.39 9.84 90 7.46 132.72c-3.162 15.03-5.535 51.43 18.99 67.25 11.867 0 31.646.79 39.557.79.791-56.17 67.243-47.47 68.043-1.58 18.19 1.58 156.64-1.58 166.13-2.37-.82-51.5 75.74-53.26 72.51 2.34z"
        ></path>
        <path
          id="path607"
          fill="#01ffff"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M119.8 108.99s39.56-70.41 70.42-88.61c19.77-8.7 35.6-13.45 49.05-12.66.79-5.53 4.74 89.4-1.59 102.06-12.65 3.17-112.34.79-117.88-.79z"
        ></path>
        <path
          id="path608"
          fill="#01ffff"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M247.18 7.716s-4.75 99.69 3.16 102.85c13.45.79 83.07 2.37 86.24-4.75-14.24-7.12-12.66-107.59-89.4-98.1z"
        ></path>
        <path
          id="path609"
          fill="#ffffb6"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M15.371 119.27c-5.538 7.12-6.329 15.04-4.747 24.53 13.45 6.33 26.899-33.23 4.747-24.53z"
        ></path>
        <path
          id="path610"
          fill="#ae0000"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M442.58 149.33c1.59-9.49-14.24-13.45-13.45 0-1.58 13.45 14.24 13.45 13.45 0z"
        ></path>
        <path
          id="path611"
          fill="#bfbfbf"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M1.922 176.23s3.164-11.07 20.57-1.58C39.104 188.1.338 197.6 1.921 176.23z"
        ></path>
        <path
          id="path613"
          fill="#bfbfbf"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M456.18 173.06s-3.164-11.07-20.569-1.58c-16.614 13.45 22.152 22.95 20.569 1.58z"
        ></path>
        <path
          id="path615"
          fill="none"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M119.01 108.99c22.15 47.47 18.2 83.86 19.78 83.86 3.17 0 91.77 2.37 99.69-1.58 3.16-18.99-1.59-82.28-1.59-82.28-8.7 3.16-100.47 2.37-117.88 0z"
        ></path>
        <path
          id="path616"
          fill="none"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M249.55 109.78c.79 6.33-2.37 79.91-2.37 82.28 13.45 0 37.18 1.58 47.47-2.37 7.91-30.86 29.13-34.02 38.76-38.77 3.17-5.54 3.17-45.1 3.17-45.1-3.17 6.33-58.55 5.54-87.03 3.96z"
        ></path>
        <path
          id="path617"
          fill="#bfbfbf"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M229.77 121.65c-.79-6.33-20.57-6.33-22.15 1.58.79 8.7 21.36 7.91 22.15-1.58z"
        ></path>
        <path
          id="path618"
          fill="#bfbfbf"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="bevel"
          strokeWidth="3.75"
          d="M332.62 116.9c-.79-6.33-20.57-6.33-22.15 1.58.79 8.7 21.36 7.91 22.15-1.58z"
        ></path>
        <path
          id="path623"
          fill="#fff"
          fillOpacity="0.711"
          fillRule="evenodd"
          d="M129.3 101.87c15.03-18.99 30.85-76.74 100.47-90.19-12.65 36.39-70.41 93.35-100.47 90.19z"
        ></path>
        <path
          id="path624"
          fill="#fff"
          fillOpacity="0.714"
          fillRule="evenodd"
          d="M252.72 13.256c-3.17 5.54-3.96 51.43 2.37 80.7 18.99-61.71 42.72-89.4-2.37-80.7z"
        ></path>
        <g id="g631" stroke="#000" transform="rotate(-2.39 -4092.098 484.333)">
          <path
            id="path612"
            fill="#7f8080"
            fillRule="evenodd"
            strokeLinejoin="bevel"
            strokeWidth="3.75"
            d="M106.802 341.099c-31.652-2.5-41.787 56.37 1.25 60.68 47.79-.79 32.87-61.47-1.25-60.68z"
          ></path>
          <path
            id="path625"
            fill="#bfbfbf"
            fillRule="evenodd"
            strokeWidth="3.75"
            d="M107.01 353.23c17.04-2.95 32.71 32.49 2.27 37.26-26.127-.45-22.037-34.08-2.27-37.26z"
          ></path>
          <path
            id="path626"
            fill="none"
            strokeWidth="3.75"
            d="M107.46 368.68c5.46-1.82 7.27 7.5 2.5 6.82-6.36 2.5-8.4-5.46-2.5-6.82z"
          ></path>
          <path
            id="path627"
            fill="none"
            strokeWidth="1.25"
            d="M107.69 357.09c-2.73 0-1.82 8.18.91 7.96 2.73-.23 1.59-8.18-.91-7.96z"
          ></path>
          <path
            id="path628"
            fill="none"
            strokeWidth="1.25"
            d="M109.28 379.128c-2.73 0-1.82 8.18.91 7.96 2.73-.23 1.59-8.18-.91-7.96z"
          ></path>
          <path
            id="path629"
            fill="none"
            strokeWidth="1.25"
            d="M123.22 371.1c0-2.73-8.18-1.82-7.96.91.23 2.73 8.18 1.59 7.96-.91z"
          ></path>
          <path
            id="path630"
            fill="none"
            strokeWidth="1.25"
            d="M102.09 373.6c0-2.73-8.18-1.82-7.96.91.23 2.73 8.18 1.59 7.96-.91z"
          ></path>
        </g>
        <g id="g639" stroke="#000" transform="rotate(-45.906 10.35 12.404)">
          <path
            id="path640"
            fill="#7f8080"
            fillRule="evenodd"
            strokeLinejoin="bevel"
            strokeWidth="3.75"
            d="M106.802 341.099c-31.652-2.5-41.787 56.37 1.25 60.68 47.79-.79 32.87-61.47-1.25-60.68z"
          ></path>
          <path
            id="path641"
            fill="#bfbfbf"
            fillRule="evenodd"
            strokeWidth="3.75"
            d="M107.01 353.23c17.04-2.95 32.71 32.49 2.27 37.26-26.127-.45-22.037-34.08-2.27-37.26z"
          ></path>
          <path
            id="path642"
            fill="none"
            strokeWidth="3.75"
            d="M107.46 368.68c5.46-1.82 7.27 7.5 2.5 6.82-6.36 2.5-8.4-5.46-2.5-6.82z"
          ></path>
          <path
            id="path643"
            fill="none"
            strokeWidth="1.25"
            d="M107.69 357.09c-2.73 0-1.82 8.18.91 7.96 2.73-.23 1.59-8.18-.91-7.96z"
          ></path>
          <path
            id="path644"
            fill="none"
            strokeWidth="1.25"
            d="M109.28 379.128c-2.73 0-1.82 8.18.91 7.96 2.73-.23 1.59-8.18-.91-7.96z"
          ></path>
          <path
            id="path645"
            fill="none"
            strokeWidth="1.25"
            d="M123.22 371.1c0-2.73-8.18-1.82-7.96.91.23 2.73 8.18 1.59 7.96-.91z"
          ></path>
          <path
            id="path646"
            fill="none"
            strokeWidth="1.25"
            d="M102.09 373.6c0-2.73-8.18-1.82-7.96.91.23 2.73 8.18 1.59 7.96-.91z"
          ></path>
        </g>
        <ellipse
          id="path5963"
          cx="465.851"
          cy="180.119"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5965"
          cx="482.145"
          cy="158.641"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5967"
          cx="499.179"
          cy="171.232"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5969"
          cx="482.886"
          cy="126.794"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5971"
          cx="511.029"
          cy="146.791"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5973"
          cx="531.767"
          cy="174.194"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5975"
          cx="507.326"
          cy="114.945"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5977"
          cx="547.32"
          cy="134.941"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5979"
          cx="493.995"
          cy="85.32"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5981"
          cx="537.692"
          cy="91.245"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5983"
          cx="576.945"
          cy="162.344"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5985"
          cx="590.276"
          cy="123.091"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5987"
          cx="562.132"
          cy="108.279"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5989"
          cx="554.726"
          cy="120.129"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5991"
          cx="516.213"
          cy="80.135"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5993"
          cx="511.77"
          cy="50.51"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5995"
          cx="556.948"
          cy="173.454"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5997"
          cx="597.682"
          cy="155.679"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse5999"
          cx="628.788"
          cy="151.235"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse6001"
          cx="555.466"
          cy="71.988"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse6003"
          cx="594.719"
          cy="105.316"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="28.884"
          ry="27.773"
        ></ellipse>
        <ellipse
          id="ellipse6005"
          cx="586.202"
          cy="76.802"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6007"
          cx="617.308"
          cy="127.905"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6009"
          cx="490.662"
          cy="59.768"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6011"
          cx="544.727"
          cy="151.605"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6013"
          cx="558.799"
          cy="20.515"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6015"
          cx="639.527"
          cy="44.956"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="19.626"
          ry="18.516"
        ></ellipse>
        <ellipse
          id="ellipse6017"
          cx="575.463"
          cy="26.44"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
        <ellipse
          id="ellipse6019"
          cx="631.751"
          cy="56.806"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
        <ellipse
          id="ellipse6021"
          cx="636.194"
          cy="91.615"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
        <ellipse
          id="ellipse6023"
          cx="532.507"
          cy="-19.478"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
        <ellipse
          id="ellipse6025"
          cx="518.435"
          cy="23.478"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
        <ellipse
          id="ellipse6027"
          cx="679.891"
          cy="95.318"
          fill="gray"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="1.661"
          opacity="0.67"
          rx="10.369"
          ry="10.369"
        ></ellipse>
      </g>
    </svg>
  );
}

export default SmokeyCar;
